import {action, makeObservable, observable} from 'mobx';

export function layoutStore() {
  return makeObservable(
    {
      sidebarToggle: false as boolean,
      setSidebarToggle(status: boolean) {
        this.sidebarToggle = status;
      },
    },
    {
      sidebarToggle: observable,
      setSidebarToggle: action.bound,
    }
  );
}

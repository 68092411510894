import {makeObservable, observable, action} from 'mobx';
import api from 'api';
import qs from 'query-string';

export function createPasswordStore() {
  return makeObservable(
    {
      isTokenValid: false as boolean,
      password: '' as string,
      passwordRepeat: '' as string,
      passwordMatch: false as boolean,
      isLoading: false as boolean,
      setPassword(password: string) {
        this.password = password;
        this.passwordMatch = password === this.passwordRepeat;
      },
      setPasswordRepeat(password: string) {
        this.passwordRepeat = password;
        this.passwordMatch = this.password === password;
      },
      validateToken() {
        const queryString = window.location.search;
        const values = qs.parse(queryString);
        this.isLoading = true;
        api.wizard
          .postCheckToken(values.token as string)
          .then(
            action((response) => {
              this.isTokenValid = !!response?.isTokenValid;
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      async postResetPassword(CSRFToken: string, captchaToken: string, token: string, isChina: boolean) {
        const params = {
          password: this.password,
          password_verify: this.passwordRepeat,
          token,
          'g-recaptcha-response': captchaToken,
          captcha_service: isChina ? 'friendly' : 'recaptcha',
          CSRFToken,
        };
        this.isLoading = true;
        return api.wizard
          .postCreatePassword(params)
          .then((response) => response.data.errors?.[0])
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
    },
    {
      isTokenValid: observable,
      password: observable,
      passwordRepeat: observable,
      passwordMatch: observable,
      isLoading: observable,
      setPassword: action.bound,
      setPasswordRepeat: action.bound,
      validateToken: action.bound,
      postResetPassword: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import {yesterday} from 'pages/Backoffice/components/Moderation/ModerationUtils';
import {ChatUser, ChatUserStatus} from 'store/pages/blendTalkStore';
import {DatePickerValue} from 'components/BaseComponents/BaseDatepicker';
import {
  getObjectFromListById,
  initialFormValue,
} from 'pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';
import api from 'api';

export interface ModerationField {
  from: DatePickerValue;
  until: DatePickerValue;
  status: string;
  source: string;
  'author.externalId': string;
}

export interface ModerationFieldForSearch extends Omit<ModerationField, 'author.externalId'> {
  'author.externalId': string | string[] | number | number[];
  'message.externalId'?: string;
  'message.externalId[]'?: string;
}

export type ModerationSourceType = 'blendTalk' | 'discussionBoard';

export interface ModerationMessage {
  externalId: string;
  text: string;
}

export type ModerationReasonType = 'stopWord' | 'email';
export interface ModerationReason {
  type: ModerationReasonType;
  value?: string;
  id?: string;
  match?: string;
}

export type ModerationHistoryStatus = 'approved' | 'declined' | 'pending';

export interface ModerationHistoryItem {
  author: Partial<ChatUser>;
  createdAt: string;
  status: ModerationHistoryStatus;
}

export interface ModerationUserEmail {
  email?: string;
}

export interface ModerationListItem {
  id: string;
  source: ModerationSourceType;
  createdAt: string;
  author: Partial<ChatUser & ModerationUserEmail>;
  message: ModerationMessage;
  reasons: ModerationReason[];
  history: ModerationHistoryItem[];
  type?: string;
}

export interface PmsClientsModerationResponse {
  items: ClientItem[];
}

export interface ClientItem {
  id: number;
}

export type ModerationName = 'from' | 'until' | 'status' | 'source' | 'author.externalId';

export function moderationStore() {
  return makeObservable(
    {
      field: {
        from: yesterday,
        until: new Date(),
        status: '',
        source: '',
        'author.externalId': '',
      } as ModerationField,
      loading: false as boolean,
      tableList: [] as ModerationListItem[] | null,
      chatModerationList: {} as {[key: string]: ModerationListItem[]},
      onFieldChange(value: Partial<ModerationField>) {
        this.field = {...this.field, ...value};
      },

      async getTableResult(token: string) {
        this.loading = true;

        const users = await api.blendTalkForAdmin.getUserList(initialFormValue).then((response) => response);

        if (!users.length) {
          this.loading = false;
          return;
        }
        const userByID = getObjectFromListById(users, 'ID');

        let extId = '';
        users.forEach(({ID}, index) => (extId += `${index ? '&' : '?'}externalId[]=${ID}`));

        const chatUserList = await api.blendTalkForAdmin.getCustomersList(extId, token);

        chatUserList.forEach(({id, externalId, type}) => {
          userByID[+externalId].externalId = id;
          userByID[+externalId].type = type;
        });

        const ids: number[] = this.field['author.externalId']
          ? await api.moderation
              .getPmCustomersList(this.field['author.externalId'], token)
              .then((response) => response?.results?.items?.map(({id}) => +id) || [])
          : [];

        const payload: ModerationFieldForSearch = {
          ...this.field,
          'author.externalId': this.field['author.externalId'] ? ids : '',
        };

        await api.moderation
          .getTableModerationList(payload, token)
          .then((response) => {
            this.tableList = !response.length
              ? null
              : response.map((data) => {
                  const id = data.author.externalId;

                  return {
                    ...data,
                    author: {
                      ...data.author,
                      name: id ? userByID[id]?.username : '',
                      status: id ? (userByID[id]?.user_status as ChatUserStatus) : ('offline' as ChatUserStatus),
                      email: id ? userByID[id]?.email : '',
                      id: data.author.externalId,
                      type: id ? userByID[id]?.type : '',
                    },
                  };
                });

            this.loading = false;
          })
          .catch(action(() => (this.tableList = [])))
          .finally(action(() => (this.loading = false)));
      },
      fetchChatModerationByMessageId(messageId: string, messageCreatedDate: string, token: string) {
        this.loading = true;

        const createDateWithoutTime = messageCreatedDate.slice(0, 10);
        const [day, month, year] = createDateWithoutTime.split('-');

        const dateObject = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

        const payload: ModerationFieldForSearch = {
          from: dateObject,
          until: new Date(),
          status: '',
          source: '',
          'author.externalId': '',
          'message.externalId': messageId,
        };

        api.moderation
          .getTableModerationList(payload, token)
          .then((response) => {
            this.chatModerationList[messageId] = response || [];
          })
          .finally(action(() => (this.loading = false)));
      },
      updateModerationStatusByMessageId(messageId: string, status: ModerationHistoryStatus, token: string) {
        if (this.chatModerationList[messageId]?.length) {
          this.chatModerationList[messageId].forEach(({id}) => {
            this.updateTableDataByStatus(status, id, token);
          });
        }
      },
      updateTableDataByStatus(status: ModerationHistoryStatus, moderationId: string, token: string) {
        this.loading = true;

        api.moderation
          .updateModerationStatus(status, moderationId, token)
          .then((response) => {
            const newTableList = [...(this.tableList || [])];

            const index = newTableList?.findIndex((td) => td.id === moderationId);

            if (index !== -1) {
              newTableList[index].history = response.history;

              this.tableList = newTableList;
            }
          })
          .finally(action(() => (this.loading = false)));
      },
    },
    {
      field: observable,
      loading: observable,
      tableList: observable,
      getTableResult: action.bound,
      onFieldChange: action.bound,
      updateTableDataByStatus: action.bound,
      chatModerationList: observable,
      fetchChatModerationByMessageId: action.bound,
      updateModerationStatusByMessageId: action.bound,
    }
  );
}

import {createGlobalStyle} from 'styled-components';
import colors from 'theme/colors';
import {attenFont, font_large, font_huge, font_medium} from 'theme/fonts';

const {
  grey000,
  grey010,
  grey030,
  grey050,
  grey060,
  grey070,
  grey100,
  green010,
  green015,
  green090,
  grey085,
  grey090,
  blue020,
  blue080,
  blue090,
  blue100,
  red100,
} = colors;

export const OverrideStyles = createGlobalStyle`
  *,
  .MuiInputBase-root {
    ${attenFont};
    text-transform: none;
  }
  
  .MuiFormLabel-root {
    font-size: 1rem!important;
    ${attenFont};
  }
  
  .MuiTypography-colorPrimary {
    color: rgb(0, 123, 255);
  }
  
  body {
    ${attenFont};
    background-color: ${grey000};
    color: ${grey100};
    font-weight: 700;
    font-size: 1.1rem;
  }
  
  .MuiButton-root {
    letter-spacing: inherit;
  }
  
  .MuiTypography-h1 {
    font-size: 60px;
    line-height: 72px;
    font-weight: 800;
    ${attenFont};
  }
  
  .MuiTypography-h2 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    ${attenFont};
  }
  
  .MuiTypography-h3 {
    font-size: 2.4rem;
    line-height: 52px;
    font-weight: 700;
    ${attenFont};
    
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }
  
  .MuiTypography-h4 {
    font-size: 2.4rem;
    line-height: 2.2rem;
    font-weight: 700;
    ${attenFont};
    letter-spacing: -0.34px;
  }
  
  .MuiTypography-h5 {
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: 700;
    ${attenFont};
  }
  
  .MuiTypography-h6 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    ${attenFont};
    letter-spacing: inherit;
  }
  
  .MuiTypography-body1 {
    font-size: 1rem;
    font-weight: normal;
    color: ${grey100};
    ${attenFont};
    letter-spacing: inherit;
    
    @media (max-width: 600px) {
      font-size: 13px;
      line-height: 1rem;
    }
  }
  
  #simple-menu {
    & > .MuiPaper-root {
      padding: 0;
      
      & > ul {
        padding: 0;
        
        & > li {
          padding: 12px 24px;
          
          &.Mui-focusVisible {
            background: transparent;
          }
        }
      }
    }
  }
  
  .MuiTypography-body2 {
    font-size: 1.1rem;
    font-weight: 700;
    ${attenFont};
    letter-spacing: inherit;
    
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  
  .MuiTypography-subtitle1 {
    font-size: 1rem;
    line-height: 1.2rem;
    ${attenFont};
    
    @media (max-width: 600px) {
     font-size: 13px;
    }
  }
  
  .MuiInputBase-input {
    ${attenFont};
  }
  
  .MuiPaper-root {
    padding: 20px;
  }
  
  .MuiOutlinedInput-input {
    padding: 0.9rem;
  }
  
  .MuiFormLabel-root {
    color: ${grey100};
    font-size: 0.9rem;
  }
  
  .MuiTypography-subtitle2 {
    ${attenFont};
  }
  
  .MuiInput-underline:before {
    border: none;
  }
  
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${blue100};
  }
  
  .MuiCheckbox-colorPrimary.Mui-disabled {
    opacity: .26;
    color: ${grey100};
  }
  
  .MuiTableCell-root {
    font-size: 1.1rem;
    font-weight: 700;
    ${attenFont};
  }
  
  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }
  
  .MuiAlert-root {
    font-size: 1.1rem;
    font-weight: 700;
    padding: 6px 16px;
  }
  
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  
  .MuiPaper-root ol {
    counter-reset: item;
  }
  
  .MuiPaper-root ol li {
    display: block;
    position: relative;
    padding-top: 10px;
  }
  
  .MuiPaper-root ol li:before {
    right: 10px;
    content: counters(item, ".");
    position: absolute;
    margin-right: 100%;
    counter-increment: item;
  }
  
  .MuiButton-outlinedSecondary {
    color: ${green090};
    border: 1px solid ${grey085};
  }
  
  .MuiButton-outlinedSecondary:hover {
    color: ${green090};
    border: 1px solid ${grey090};
    background-color: rgba(0, 0, 0, 0.08);
  }
  
  .MuiButton-containedPrimary {
    background-color: ${blue100};
  }
  
  .MuiTooltip-tooltip {
    background-color: ${blue100};
    font-size: 13px;
    margin: 5px 0;
    
    & > span {
      &:before {
        color: ${blue100};
      }
    }
    
    & a {
      color: ${grey000};
      text-decoration: underline;
    }

    & ul.password-tooltip {
      margin: 5px 0 5px -20px;
    }
  }

  .base-select-menu {
    padding: 0;
  }
  
  .Toastify {
    &__toast {
      padding: 0 0 5px 0 !important;
      
      &-body {
        display: flex;
        background: ${grey000} !important;
        padding: 15px 20px 10px 15px !important;
        margin: 0 !important;
        align-items: center;
      }
      
      &-container--top-right {
        top: 0.8em !important;
        right: 0.8em !important;
      }
    }
    
    &__close-button {
      color: ${grey100} !important;
      opacity: .3 !important;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  
  .MuiFormGroup-root[role="radiogroup"] {
    padding: 0 10px;
  
    & > label.MuiFormControlLabel-root {
      &.Mui-disabled {
        cursor: not-allowed;
      }

      & > span.MuiButtonBase-root {
        padding: 0;
        margin-right: 5px;
        
        &.Mui-checked {
          color: ${blue100};
        }
      }
    }
  }
  
  .select-price-type {
    &-disabled {
      .MuiTooltip {
        &-tooltip {
          background: ${grey100};
          max-width: 200px;
          border-radius: 8px;
          top: 190px;
          
          @media (min-width: 960px) {
            left: 50px;
            top: 5px;
          }
        }
      
        &-arrow {
          &:before {
            color: ${grey100};
          }
        }
      }
    }
    
    div.MuiTooltip-tooltip {
      min-height: 36px;
      display: flex;
      align-items: center;
      max-width: 200px;
      border-radius: 8px;
      top: 190px;
      
      @media (min-width: 960px) {
        left: 50px;
        top: 5px;
      }
    }
  }

  .voice-talent-labels .MuiTooltip-tooltip, .subscriptions-action-label .MuiTooltip-tooltip {
    max-width: 250px;
    background-color: ${grey100};
    color: ${grey000};
    font-weight: normal;
    
    & > span:before {
      color: ${grey100};
    }
  }

  .controlPanelTooltip .MuiTooltip-tooltip {
    ${font_large};
    background-color: ${grey100};
    border-radius: 30px;
    padding: 20px;
    max-width: 100%;

    & div {
     margin-bottom: 5px;
    }

    & > span:before {
      color: ${grey100};
    }
  }

  .voices-popper {
    top: -4px !important;
    left: 15px !important;

    & .MuiTooltip-tooltip {
      background-color: ${grey100};
      color: ${grey000};
      font-weight: normal;
      
      & > span {
        &:before {
          color: ${grey100};
        }

        @media (max-width: 600px) {
          left: 101px !important;
        }
      }
    }
  }

  .subscription-time-filter .MuiPaper-root {
    padding: 0;
  }
  
  .MuiMobileStepper-dot {
    background-color: ${grey000};
    opacity: .4;

    &Active {
      opacity: 1;
    }
  }
  
  .MuiRating-decimal {
    margin: 0 3px;
  }
  
  [data-qa-auto="hello-user"] {
    & .MuiSelect-select {
      min-width: unset;
      padding: 4px 12px;
    }
  }
  
  .switch-to-beneficiary {
    & .MuiMenu-paper {
      max-width: 200px;
      left: 78px !important;
      top: 114px !important;
      background-color: ${grey100};
      color: ${grey000};
      padding: 0;
      
      & ul {
        padding: 0;
        
        & li {
          display: block;
          text-overflow: ellipsis;
          margin: 5px;
          padding: 6px 10px;
          min-height: unset;
  
          &:hover {
            background-color: ${blue100};
          }
        }
      }
    }
  }
  
  .post-register-modal {
    .MuiDialogTitle-root {
      padding: 0;
    }
    
    .MuiDialogContent-root {
      padding: 10px;
    }
    
    .MuiDialogActions-root {
      & button {
        width: 100%;
        background-color: ${blue100};
        color: ${grey000};
        border-radius: 30px;
        padding: 12px;
        
        &:hover {
          background-color: ${blue090};
        }
      }
    }
  }

  .kyc-control-missed {
    background-color: ${grey010};

    &__error {
      & > div {
        background-color: rgb(255, 232, 234);
        border: 1px solid ${red100};
        border-radius: 4px;
      }
    }
  }

  .kyc-dropdown-option {
    font-size: 15px;
    
    & > span {
      margin-right: 10px;
      ${font_huge};
    }
  }
  
  .my-project-filter-modal {
    & .MuiDialogTitle-root {
      display: none;
    }

    & .MuiDialog-paper {
      width: 560px;
      
      @media (max-width: 425px) {
        margin: 10px;
      }
    }
    
    & .MuiDialogContent-root {
      padding: 10px;
      
      @media (max-width: 425px) {
        padding: 0;
      }
    }
    
    & .MuiDialogActions-root {
      justify-content: start;

      & button {
        width: 200px;
        height: 45px;
        border-radius: 30px;
        background-color: ${blue100}; 
        color: ${grey000};
      
        &:hover {
          background: ${blue090};
        }

        &:first-child {
          display: none;
        }
      }
    }
    
    & .MuiAutocomplete-tag {
      max-width: 132px;
    }
  }
  
  .my-project-tags-modal {
    & .MuiDialog-paper {
      width: 560px;
    }
    
    & .MuiDialogActions-root {
      justify-content: start;

      & button {
        width: 200px;
        height: 45px;
        border-radius: 30px;
        background-color: ${blue100};
        color: ${grey000};
      
        &:hover {
          background: ${blue090};
        }

        &:first-child {
          display: none;
        }
      }
    }
  }

  .my-project-bulk-message-modal {
    & .MuiDialog-paper {
      width: 560px;
    }

    & .MuiDialogTitle-root .MuiTypography-h6 {
      font-size: 22px;
    }
  }
  
  .my-project-rating-modal {
    & .MuiDialogActions-root {
      & button[data-qa-auto="modal-cancel-btn"] {
        color: ${grey100};
        background-color: ${grey030};
      }
    }
  }
  
  .convert-modal {
    & .MuiDialogTitle-root {
      padding-bottom: 0 !important;
    }

    
    & .MuiDialogContent-root {
      padding: 8px;
    }
    
    & .MuiDialogActions-root {
      padding: 8px 24px;

      & button:last-child {
        color: ${grey000};
        background-color: ${blue100};
      }
    }
  }
  
  .invoice-for-convert {
    & .MuiDialog-paper {
      max-width: 1000px;
    }
  }

  .MuiPickersToolbar-toolbar {
    height: 70px;
    background-color: ${blue100};
    
    & .MuiPickersToolbarText-toolbarTxt {
      font-size: 18px;
    }
  }

  .MuiPickersDay-daySelected {
    background-color: ${blue100};
    
    &:hover {
      background-color: ${blue090};
    }
  }
  
  .MuiAutocomplete-paper {
    padding: 0;
    margin: 0;
    
    & > ul {
      max-height: 340px;
      
      &::-webkit-scrollbar {
        width: 10px;
      }
      
      &::-webkit-scrollbar-track {
        background-color: ${green015};
      
        &:hover {
          background-color: ${green010};
        }
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 16px;
        border: 2px solid rgb(241, 246, 247);
        background-color: ${grey060};
        height: 100px;
        
        &:hover {
          background-color: ${grey070};
          border: 2px solid ${green010};
        }
      }
    }
  }
  
  .MuiAutocomplete-root {
    & > div {
      background-color: ${grey010};
      border-radius: 4px;
      width: 100%;

      & > div {
        max-width: 100%;
      }
    }
    
    & .MuiAutocomplete-tag {
      background-color: ${blue020};
      color: ${blue100};
      border: none;
      
      & svg {
        color: unset;
        width: 18px;
        
        &:hover {
          color: ${blue090};
        }
      }
    }
    
    .MuiAutocomplete-popupIndicator {
      width: 24px;
      height: 24px;
      margin-right: 3px;

      & svg {
        border: solid rgba(0, 0, 0, 0.54);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        -webkit-transform: rotate(45deg);
        width: 5px;
        height: 5px;
        margin-top: -2px;
      }
    }
  }

  .MuiAutocomplete-listbox {
    .MuiCheckbox-root {      
      &.Mui-checked {
        color: ${blue100};
      }
    }

    .MuiAutocomplete-option {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 6px 10px;
    }
  }
  
  .MuiOutlinedInput-root {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${grey030};
    }
    
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${blue100};
      }
    }

    &.Mui-disabled {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${grey030};
      }

      & .MuiAutocomplete-endAdornment {
        & svg {
          border-color: ${grey050};
        }
      }

      &:hover {
        & .MuiOutlinedInput-notchedOutline {
          border-color: ${grey030};
        }
      }
    }

    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${blue100};
      }
    }
  }
  
  .filter-date {
    height: 44px;
    background-color: ${grey010};
    border: 1px solid ${grey030};
    border-radius: 4px;
    padding: 0;
    box-shadow: none;
    z-index: 10;
  
    &::before {
      content: unset;
    }
  
    &.Mui-expanded {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin: 0;
      
      .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0;
      }
    }
    
    .MuiExpansionPanelSummary-root {
      ${font_medium};
      background-color: ${grey010};
      min-height: unset !important;
      padding: 0 15px;
      color: ${grey070};
      height: 42px;
    
      & .MuiIconButton-edgeEnd {
        width: 24px;
        height: 24px;
        padding: 0;
        margin-right: -4px;
        
        & span {
          justify-content: end;
        }
      
        svg {
          border: solid rgba(0, 0, 0, 0.54);
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          -webkit-transform: rotate(45deg);
          width: 5px;
          height: 5px;
          margin-top: -2px;
          margin-right: 8px;
        }
      }
    }
    
    .MuiCollapse-root {
      background-color: ${grey000};
      border: 1px solid ${grey030};
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      margin: -1px;

      & .MuiExpansionPanelDetails-root {
        padding: 10px;
      }
    }
  }

  .MuiPickersYearSelection-container {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${green015};

      &:hover {
        background-color: ${green010};
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 16px;
      border: 2px solid rgb(241, 246, 247);
      background-color: ${grey060};
      height: 100px;

      &:hover {
        background-color: ${grey070};
        border: 2px solid ${green010};
      }
    }
  }

  .credit-actions-dropdown {
    & .MuiList-padding, .MuiPaper-root {
      ${font_medium};
      padding: 0;
    }

    & .MuiMenuItem-root {
      padding: 0 3px;
    }
  }

  .profile-helper-text {
    position: absolute;
    bottom: -20px;
  }

  .copy-key-tooltip {
    & .MuiTooltip-tooltip {
      border: 1px solid ${blue080};
      background-color: ${grey000};
      color: ${grey100};
    }
  }
  
  .coa-popover {
    .MuiPaper-root {
      padding: 0;
    }
  }

  #menu-expertise {
    & .MuiListItem-root[icon='0'] {
      padding-left: 50px;
    }
  }
  
  .editor-text-area {
    width: 100%;
    border: 0;
    padding: 10px;
    vertical-align: top;
    resize: vertical;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #bbb;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #999;
    }
    &::placeholder {
      font-style: italic;
    }
  }

  // ------------- Animations ------------- //
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

import {makeObservable, observable, action} from 'mobx';
import dayjs from 'dayjs';
import api from 'api';
import {getItem, setItem} from 'utils/localStorage';
import {SourceLanguage} from 'pages/Wizard/types';

interface ILowQaProject {
  dateStr: string;
  total_count: string;
  exclusive_count: string;
  editing_count: string;
}

interface ILowQaProjectsArray {
  [key: string]: ILowQaProject;
}

export function LmDashboardMainStore() {
  return makeObservable(
    {
      client: '' as string,
      sourceLanguage: [] as number[],
      targetLanguage: [] as number[],
      languagesList: [] as SourceLanguage[],
      currentDate: dayjs().format('YYYY-MM-DD'),
      previousDate: dayjs().subtract(6, 'd').format('YYYY-MM-DD'),
      delayedChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
        exclusive: 0 as number,
        editing: 0 as number,
      },
      waitingChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
        exclusive: 0 as number,
        editing: 0 as number,
      },
      waitingPilotChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
      },
      lowQualityChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        lowQualityClass: '' as string,
        total: 0 as number,
        totalPrevious: 0 as number,
      },
      inProgerssChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
        exclusive: 0 as number,
        editing: 0 as number,
      },
      lowQAChart: {
        loading: true,
        total: 0 as number,
        totalPrevious: 0 as number,
      },
      translatorsChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
        totalPrevious: 0 as number,
      },
      lowLLChart: {
        loading: true,
        lowCQRClass: '' as string,
        total: 0 as number,
        totalPrevious: 0 as number,
      },
      expiredChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
      },
      waitingLLChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
      },
      statisticLLChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
      },
      ticketsChart: {
        series: [
          {
            name: 'Projects',
            data: [] as any[],
          },
        ],
        loading: true,
        total: 0 as number,
      },
      setClient(client: string) {
        this.client = client;
        this.saveFilters('Client', client);
      },
      setSourceLanguage(languages: []) {
        this.sourceLanguage = languages;
        this.saveFilters('Source', languages);
      },
      setTargetLanguage(languages: []) {
        this.targetLanguage = languages;
        this.saveFilters('Target', languages);
      },
      getLanguagesList() {
        api.wizard.getSourceLanguages().then(
          action((data) => {
            this.languagesList = data;
          })
        );
      },
      saveFilters(type: any, id: any) {
        let languageFilters: any = {};
        const filters = getItem('dashboardFilters');

        if (filters) {
          languageFilters = filters;
        }

        languageFilters[type] = id;

        setItem(languageFilters, 'dashboardFilters');
      },
      loadFilters() {
        const filters = getItem('dashboardFilters');

        if (filters?.Source && filters.Source.length) {
          this.sourceLanguage = filters.Source;
        }

        if (filters?.Target && filters.Target.length) {
          this.targetLanguage = filters.Target;
        }

        if (filters?.Client && filters.Client.length) {
          this.client = filters.Client;
        }
      },
      clearFilters() {
        this.setSourceLanguage([]);
        this.setTargetLanguage([]);
        this.setClient('');
      },
      getDelayedProjects() {
        let url =
          '/api/2/dashboard/projects/waiting?' +
          '&delay_more_then=24' +
          '&date_from=' +
          this.previousDate +
          '&date_to=' +
          this.currentDate;
        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              const projectsData: {x: string; y: any}[] = [];
              let total = 0,
                exclusive = 0,
                editing = 0;

              for (const [key, value] of Object.entries(data.results)) {
                const item: any = value;
                total += parseInt(item.total_count);
                exclusive += parseInt(item.exclusive_count);
                editing += parseInt(item.editing_count);

                projectsData.push({
                  x: dayjs(key).format('MMM DD'),
                  y: item.total_count,
                });
              }

              this.delayedChart = {
                ...this.delayedChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                exclusive: exclusive,
                editing: editing,
                loading: false,
              };
            })
          );
      },
      getWaitingProjects() {
        let url =
          '/api/2/dashboard/projects/waiting?' +
          '&delay_more_then=8' +
          '&delay_less_then=24' +
          '&date_from=' +
          this.previousDate +
          '&date_to=' +
          this.currentDate;
        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              const projectsData: {x: string; y: any}[] = [];
              let total = 0,
                exclusive = 0,
                editing = 0;

              for (const [key, value] of Object.entries(data.results)) {
                const item: any = value;
                total += parseInt(item.total_count);
                exclusive += parseInt(item.exclusive_count);
                editing += parseInt(item.editing_count);

                projectsData.push({
                  x: dayjs(key).format('MMM DD'),
                  y: item.total_count,
                });
              }

              this.waitingChart = {
                ...this.waitingChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                exclusive: exclusive,
                editing: editing,
                loading: false,
              };

              this.waitingPilotChart = {
                ...this.waitingPilotChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: Object.entries(data.results).length,
                loading: false,
              };
            })
          );
      },
      getLowQualityProjects() {
        let url =
          '/api/2/dashboard/projects/low-quality?' + '&date_from=' + this.previousDate + '&date_to=' + this.currentDate;

        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              const projectsData: {x: string; y: any}[] = [];

              const lowQaProjects: ILowQaProjectsArray = data.results;
              const resultsKeys: Array<keyof ILowQaProjectsArray> = Object.keys(lowQaProjects);
              const total: number = resultsKeys.reduce((acc: number, cur: keyof ILowQaProjectsArray) => {
                const curObj: ILowQaProject = lowQaProjects[cur];
                const value: number = +curObj.total_count;
                return acc + value;
              }, 0);

              this.lowQualityChart = {
                ...this.lowQualityChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                loading: false,
              };

              if (this.lowQualityChart.total > this.lowQualityChart.totalPrevious) {
                this.lowQualityChart.lowQualityClass = 'up';
              } else if (this.lowQualityChart.total < this.lowQualityChart.totalPrevious) {
                this.lowQualityChart.lowQualityClass = 'down';
              }
            })
          );
      },
      getInProgerssProjects() {
        let url =
          '/api/2/dashboard/projects/in-progress?' + '&date_from=' + this.previousDate + '&date_to=' + this.currentDate;

        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              const projectsData: {x: string; y: any}[] = [];
              let total = 0,
                exclusive = 0,
                editing = 0;

              for (const [key, value] of Object.entries(data.results)) {
                const item: any = value;
                total += parseInt(item.total_count);
                exclusive += parseInt(item.exclusive_count);
                editing += parseInt(item.editing_count);

                projectsData.push({
                  x: dayjs(key).format('MMM DD'),
                  y: item.total_count,
                });
              }

              this.inProgerssChart = {
                ...this.inProgerssChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                exclusive: exclusive,
                editing: editing,
                loading: false,
              };
            })
          );
      },
      getLowQAProjects() {
        let url = '/api/2/dashboard/projects/low-qa?';

        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              this.lowQAChart = {
                ...this.lowQAChart,
                total: data.results,
                loading: false,
              };
            })
          );
      },
      getTranslatorsProjects() {
        let url = '/api/2/dashboard/users/available-translators?';
        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              const projectsData: {x: string; y: any}[] = [],
                total = data.results.length;

              this.translatorsChart = {
                ...this.translatorsChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                loading: false,
              };
            })
          );
      },
      getLowLLProjects() {
        let url = '/api/2/dashboard/projects/low-ll?alerts=166d4472-870a-11eb-acb3-0242ac130011';

        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              this.lowLLChart = {
                ...this.lowLLChart,
                total: data.results,
                loading: false,
              };

              if (this.lowLLChart.total > this.lowLLChart.totalPrevious) {
                this.lowLLChart.lowCQRClass = 'up';
              } else if (this.lowLLChart.total < this.lowLLChart.totalPrevious) {
                this.lowLLChart.lowCQRClass = 'down';
              }
            })
          );
      },
      getExpiredProjects() {
        let url =
          '/api/2/dashboard/projects/expired?' + '&date_from=' + this.previousDate + '&date_to=' + this.currentDate;

        if (this.sourceLanguage && this.sourceLanguage.length) {
          url += '&source_language_id=' + this.sourceLanguage;
        }
        if (this.targetLanguage && this.targetLanguage.length) {
          url += '&target_language_id=' + this.targetLanguage;
        }
        if (this.client) {
          url += '&client=' + this.client.replace(/[+]/g, '%2B');
        }

        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then(
            action((data) => {
              const projectsData: {x: string; y: any}[] = [];
              const total = Object.entries(data.results).length;

              this.expiredChart = {
                ...this.expiredChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                loading: false,
              };

              this.waitingLLChart = {
                ...this.waitingLLChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                loading: false,
              };

              this.statisticLLChart = {
                ...this.statisticLLChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                loading: false,
              };

              this.ticketsChart = {
                ...this.ticketsChart,
                series: [
                  {
                    name: 'Projects',
                    data: projectsData,
                  },
                ],
                total: total,
                loading: false,
              };
            })
          );
      },
    },
    {
      client: observable,
      sourceLanguage: observable,
      targetLanguage: observable,
      languagesList: observable,
      setClient: observable,
      currentDate: observable,
      previousDate: observable,
      delayedChart: observable,
      waitingChart: observable,
      waitingPilotChart: observable,
      lowQualityChart: observable,
      inProgerssChart: observable,
      lowQAChart: observable,
      translatorsChart: observable,
      lowLLChart: observable,
      expiredChart: observable,
      waitingLLChart: observable,
      statisticLLChart: observable,
      ticketsChart: observable,
      setSourceLanguage: action.bound,
      setTargetLanguage: action.bound,
      getLanguagesList: action.bound,
      saveFilters: action.bound,
      loadFilters: action.bound,
      clearFilters: action.bound,
      getDelayedProjects: action.bound,
      getWaitingProjects: action.bound,
      getLowQualityProjects: action.bound,
      getInProgerssProjects: action.bound,
      getLowQAProjects: action.bound,
      getTranslatorsProjects: action.bound,
      getLowLLProjects: action.bound,
      getExpiredProjects: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';
import Consts from 'pages/Backoffice/components/Vocabulary/VocabularyConsts';
import {
  VocabularyLanguage,
  VocabularyLanguageRequest,
  VocabularyLoading,
  VocabularyWord,
  VocabularyWordSearchResult,
} from 'pages/Backoffice/components/Vocabulary/VocabularyUtils';

const {WORD_PAGINATION_PER_PAGE} = Consts;

export interface WordPagination {
  paginationItemsPerPage?: number;
  paginationTotalItems?: number;
  page: number | null;
}

export function vocabularyStore() {
  return makeObservable(
    {
      wordList: [] as VocabularyWord[] | null,
      languageList: [] as VocabularyLanguage[] | null,
      loading: {wordList: false, languageList: false} as VocabularyLoading,
      selectedId: null as string | null,
      search: '' as string,
      searchResult: [] as VocabularyWordSearchResult[] | null,
      wordPagination: {
        paginationItemsPerPage: 30,
        paginationTotalItems: 0,
        page: 1,
      } as WordPagination,

      setSearch(value: string) {
        this.search = value;
      },
      fetchWordList(id: string, token: string, total: number) {
        this.loading.wordList = true;
        this.selectedId = id;

        this.search && this.resetSearch();

        const page = Math.floor(total / WORD_PAGINATION_PER_PAGE) + 1;

        api.moderation
          .getWordList(id, token, page)
          .then(
            action(({data, paginationItemsPerPage, paginationTotalItems}) => {
              if (page === 1) {
                this.wordList = data.length ? data : null;
              } else {
                this.wordList = [...(this.wordList || []), ...data];
              }

              this.setWordPagination(paginationTotalItems, paginationItemsPerPage, page);
            })
          )
          .finally(action(() => (this.loading.wordList = false)));
      },

      addWord(title: string, language: VocabularyLanguageRequest, token: string) {
        this.loading.wordList = true;

        api.moderation
          .createWord(title, language, token)
          .then(
            action((response) => {
              this.wordList = [...(this.wordList || []), response];
            })
          )
          .finally(action(() => (this.loading.wordList = false)));
      },

      deleteWord(id: string, token: string) {
        this.loading.wordList = true;

        api.moderation
          .removeWord(id, token)
          .then(() => {
            const wordList = (this.wordList || []).filter((word) => word.id !== id);
            this.wordList = !wordList?.length ? null : wordList;

            this.searchResult = (this.searchResult || []).filter(({word}) => word.id !== id);
          })
          .finally(action(() => (this.loading.wordList = false)));
      },

      fetchLanguageList(token: string) {
        this.loading.languageList = true;

        api.moderation
          .getLanguageList(token)
          .then(
            action((response) => {
              this.languageList = response?.length ? response : null;

              if (response?.length && !this.selectedId) {
                this.selectedId = response[0].id;
                this.fetchWordList(response[0].id, token, 0);
              }
            })
          )
          .finally(action(() => (this.loading.languageList = false)));
      },

      addLanguage(title: string, token: string) {
        this.loading.languageList = true;

        api.moderation
          .createLanguage(title, token)
          .then(
            action((response) => {
              this.languageList = [...(this.languageList || []), response];
              this.selectedId = response.id;
              this.wordList = [];

              this.search && this.resetSearch();
            })
          )
          .finally(action(() => (this.loading.languageList = false)));
      },

      updateLanguage(id: string, title: string, token: string) {
        this.loading.languageList = true;

        api.moderation
          .editLanguage(id, title, token)
          .then(
            action((response) => {
              const newLanguageList = [...(this.languageList || [])];
              const index = newLanguageList.findIndex((language) => language.id === id);

              if (index >= 0) {
                newLanguageList[index] = {...newLanguageList[index], ...response};
                this.languageList = newLanguageList;
              }
            })
          )
          .finally(action(() => (this.loading.languageList = false)));
      },

      searchWords(token: string) {
        this.loading.wordList = true;
        api.moderation
          .searchWord(this.search, token)
          .then(
            action((result) => {
              this.searchResult = result?.words.length ? result.words : null;
              this.wordList = [];
            })
          )
          .finally(action(() => (this.loading.wordList = false)));
      },
      resetSearch() {
        this.searchResult = [];
        this.search = '';
      },
      setWordPagination(total = 0, perPage = 30, page: number | null) {
        this.wordPagination = {
          paginationTotalItems: total,
          paginationItemsPerPage: perPage,
          page,
        };
      },
    },
    {
      wordList: observable,
      languageList: observable,
      loading: observable,
      fetchWordList: action.bound,
      addWord: action.bound,
      deleteWord: action.bound,
      fetchLanguageList: action.bound,
      addLanguage: action.bound,
      updateLanguage: action.bound,
      selectedId: observable,
      search: observable,
      setSearch: action.bound,
      searchWords: action.bound,
      searchResult: observable,
      resetSearch: action.bound,
      setWordPagination: action.bound,
      wordPagination: observable,
    }
  );
}

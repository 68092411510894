import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function APIKeysStore() {
  return makeObservable(
    {
      formData: {
        apiFullName: '' as string,
        apiEmail: '' as string,
        apiPhone: '' as string,
        apiWebsite: '' as string,
        apiIntendendUse: '' as string,
      },
      token: '' as string,
      redirectUri: '' as string,
      clientId: '' as string,
      clientSecret: '' as string,
      errorMessages: {} as any,
      isLoading: false as boolean,
      updateFormData(newFormData: any) {
        this.formData = {...this.formData, ...newFormData};
        this.errorMessages = {};
      },
      setToken(token: string) {
        this.token = token;
        this.errorMessages = {};
      },
      setRedirectUri(uri: string) {
        this.redirectUri = uri;
        this.errorMessages = {};
      },
      setClientId(id: string) {
        this.clientId = id;
      },
      setClientSecret(secret: string) {
        this.clientSecret = secret;
      },
      postToken() {
        this.isLoading = true;
        api.profile
          .generateApiKeys(this.token)
          .then((response) => {
            if (response?.data?.status === 'ok') {
              this.errorMessages = {};
            } else {
              this.errorMessages = response?.data;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      resetToken() {
        this.isLoading = true;
        api.profile
          .resetApiToken()
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
      generateClientKeys(CSRFToken: string) {
        if (this.redirectUri === '') {
          this.errorMessages = {redirectUri: 'Redirect uri should be provided!'};
          return;
        }
        this.isLoading = true;
        api.profile
          .generateClientKeys(this.redirectUri, CSRFToken)
          .then((response) => {
            if (response?.data.errors?.length) {
              this.errorMessages = {redirectUri: response?.data?.errors.join(', ')};
            } else {
              this.errorMessages = {};
            }

            if (response?.data?.results?.clientId && response?.data?.results?.clientSecret) {
              this.clientId = response?.data?.results?.clientId;
              this.clientSecret = response?.data?.results?.clientSecret;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      refreshRedirectUri(CSRFToken: string) {
        if (this.redirectUri === '') {
          this.errorMessages = {redirectUri: 'Redirect uri should be provided!'};
          return;
        }
        this.isLoading = true;
        api.profile
          .updateClientDetails(this.redirectUri, CSRFToken)
          .then((response) => {
            if (response?.data?.errors?.length) {
              this.errorMessages = {redirectUri: response?.data?.errors.join(', ')};
            } else {
              this.errorMessages = {};
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      postAPIKeys(CSRFToken: string) {
        this.isLoading = true;
        api.profile
          .postApiKeysData({
            ...this.formData,
            CSRFToken,
            api_deatils: '1',
          })
          .then((response) => {
            if (response?.data?.status === 'ok') {
              this.errorMessages = {};
            } else {
              this.errorMessages = response?.data;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      formData: observable,
      token: observable,
      redirectUri: observable,
      clientId: observable,
      clientSecret: observable,
      errorMessages: observable,
      isLoading: observable,
      updateFormData: action.bound,
      setToken: action.bound,
      setRedirectUri: action.bound,
      setClientId: action.bound,
      setClientSecret: action.bound,
      postToken: action.bound,
      resetToken: action.bound,
      generateClientKeys: action.bound,
      refreshRedirectUri: action.bound,
      postAPIKeys: action.bound,
    }
  );
}

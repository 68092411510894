import {makeObservable, observable, action} from 'mobx';
import api from 'api';

type ListItem = {
  list_id: number;
  list_value: string;
  selected: boolean;
};

export function softwareServicesStore() {
  return makeObservable(
    {
      services: [] as ListItem[],
      software: [] as ListItem[],
      isLoading: false as boolean,
      setServices(list: ListItem[]) {
        this.services = list;
      },
      setSoftware(list: ListItem[]) {
        this.software = list;
      },
      changeServiceCheckbox(name: string, checked: boolean) {
        this.services.map((service) => {
          if (service.list_id.toString() === name) {
            service.selected = checked;
          }
          return service;
        });
      },
      changeSoftwareCheckbox(name: string, checked: boolean) {
        this.software.map((soft) => {
          if (soft.list_id.toString() === name) {
            soft.selected = checked;
          }
          return soft;
        });
      },
      postSoftwareServices() {
        const selectedSoftware: string[] = [];
        const selectedServices: string[] = [];

        this.services.forEach((service) => {
          service.selected && selectedServices.push(service.list_id.toString());
        });

        this.software.forEach((soft) => {
          soft.selected && selectedSoftware.push(soft.list_id.toString());
        });

        const data = new FormData();
        data.append('software', JSON.stringify(selectedSoftware));
        data.append('services', JSON.stringify(selectedServices));

        this.isLoading = true;
        api.profile
          .saveSoftwareService({fd: data})
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      services: observable,
      software: observable,
      isLoading: observable,
      setServices: action.bound,
      setSoftware: action.bound,
      changeServiceCheckbox: action.bound,
      changeSoftwareCheckbox: action.bound,
      postSoftwareServices: action.bound,
    }
  );
}

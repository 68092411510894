export const STEP_ITEMS_WIZARD = [
  {name: 'Languages', slug: 'languages'},
  {name: 'Project brief', slug: 'brief'},
  {name: 'Content', slug: 'materials'},
  {name: 'Project quote', slug: 'solutions'},
  {name: 'Pay & submit order', slug: 'payment'}, //logic for sidebar payment is saved in MOR-91-stepper-step-payment-cant-skip-plan
];

export const STEP_ITEMS_WIZARD_ENTRY = [
  {name: 'Entry', slug: 'entry'},
  {name: 'Choose languages', slug: 'languages'},
  {name: 'Upload content', slug: 'content'},
  {name: 'Select translation option', slug: 'solutions'},
  {name: 'Review order', slug: 'payment'},
  {name: 'Add more info', slug: 'brief_submit'},
];

//TODO rename file
export const QUERY_KEYS = {
  USER_DATA: 'userData',
  PROFILE_DATA: 'profileData',
  USER_DETAILS_FOR_ADMIN: 'userDetails',
  CHANGE_CURRENCY_FOR_ADMIN: 'changeCurrencyForAdmin',
  CHANGE_COUNTRY_FOR_ADMIN: 'changeCountryForAdmin',
  TARGET_LANGUAGES: 'targetLanguages',
  SOURCE_LANGUAGES: 'sourceLanguages',
  LANGUAGES: 'languages',
  CONFIG: 'config',
  SESSION: 'session',
  PO: 'PO',
  EXPERTISES: 'expertises',
  TIMEZONES: 'timezones',
  SOFTWARE: 'software',
  SERVICES: 'services',
  XTMLANGUAGES: 'xtmlanguages',
  TMXFILES: 'tmxfiles',
  TERMINOLOGYFILES: 'terminologyfiles',
  BENEFICIARY: 'beneficiary',
  PROJECTNOTES: 'projectnotes',
  GLOSSARYFILES: 'glossaryfiles',
  TFACODE: 'tfacode',
  ACTION_TYPES: 'actionTypes',
  TAGS: 'tags',
  STATEMENT_DATA: 'statement_data',
  MESSAGES: 'messages',
  EMAIL_REPORT: 'email_report',
  BASIC_PACKAGE_INFO: 'basicPackageInfo',
  EXPERT_PACKAGE_INFO: 'expertPackageInfo',
  EDITORAL_PERFECT_PACKAGE_INFO: 'editoralPerfectPackageInfo',
  MPTE_PACKAGE_INFO: 'mptePackageInfo',
  COUNTRIES: 'countries',
  INDUSTRY: 'industry',
  EXPERTISE: 'expertise',
  PARTNER: 'partner',
  INVOICES_DATA: 'invoicesData',
  INVOICE_DATA: 'invoiceData',
  ALERTS: 'alerts',
  BILLING_INFORMATION: 'billingInformation',
  UI_FEATURE: 'UiFeature',
  PROJECTS: 'Projects',
  PRICING: 'Price',
};

export const USER_ROLES = {
  admin: 'Admin',
  unlogged: 'unlogged',
  customer: 'Customer',
  translator: 'Translator',
};

export const MAX_IMAGE_SCALE = 3.0;
export const MIN_IMAGE_SCALE = 1.0;
export const SCALE_STEP = 0.5;

export const PROJECT_TYPES = {
  SeoMaster: 'SEO translation',
  translation: 'Translation',
  urgentTranslation: 'Urgent Translation',
  proofreading: 'Proofreading',
  combo_translation_proofreading: 'Translation + Editing',
  TranscriptionProject: 'Transcription',
  WeST2TranslationProject: 'Website Localization',
  ExpertWeST2TranslationProject: 'Website Localization',
  EnterpriseQuality: 'Enterprise Quality',
  LQAProject: 'Local insights report',
  Hybrid: 'MTPE Full',
  MtpeLight: 'Translation',
  MachineTranslation: 'Machine Translation',
  ExcelTranslation: 'Excel Translation',
  ExcelCombo: 'Excel Combo',
  MTPEaT: 'Translation',
  MTPELight: 'Standart translation',
  TranslationEvaluation: 'FB Translation Evaluation',
  DTP: 'DTP',
  FbStatus: 'FB Translation',
  FbPostEdit: 'FB Machine translation',
  FbRating: 'FB Rating',
  FbRatingComparison: 'FB Rating + Comparison',
  DynamicTranslation: 'FB Translation (Glossary)',
  RomanizationTranslation: 'FB Translation (Romanization)',
  FbPostEditTranslation: 'FB Machine translation without LE',
};

export const DATE_FORMAT = {
  MM_DD_YYYY: 'MM/DD/YYYY',
};

export const GLOBAL_CONSTS = {
  SECONDS_IN_DAY: 86400,
  SECONDS_IN_HOUR: 3600,
  SECONDS_IN_MINUTE: 60,
  HOURS_IN_DAY: 24,
  FORMAT_DATE: 'YYYY-MM-DD',
  FORMAT_DATE_SHOW: 'MMM DD, YYYY',
  DATA_QA_DATE: 'user-since-date',
  DATA_QA_DATE_FROM: 'user-since-date-from',
  DATA_QA_DATE_TO: 'user-since-date-to',
};

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import useTracking from 'context/Tracking/useTracking';
import {GTMEvents} from 'utils/globalEvents';

const ScrollToTop = ({children}) => {
  const location = useLocation();
  const {gtmTrack} = useTracking();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    try {
      gtmTrack({
        event: GTMEvents.page_view,
        page_location: window.location.href,
        page_title: document.title,
      });
    } catch (error) {
      console.error('gtm track error: ', error);
    }
  }, [location.pathname]);

  return children || null;
};

export default ScrollToTop;

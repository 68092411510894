import {makeObservable, observable, action} from 'mobx';
import api from 'api';

type translatorType = {
  name?: string;
  source_language?: string;
  target_language?: string;
  native_language?: string;
  avg_project_rate_customer?: string;
};

export function preferredTranslatorsStore() {
  return makeObservable(
    {
      preferredTranslators: [] as translatorType[],
      getPreferredTranslators(CSRFToken: string) {
        api.profile.getPreferredTranslators(CSRFToken).then((response) => {
          this.preferredTranslators = response?.results || [];
        });
      },
    },
    {
      preferredTranslators: observable,
      getPreferredTranslators: action.bound,
    }
  );
}

import {action, makeObservable, observable, runInAction} from 'mobx';
import dayjs from 'dayjs';
import api from 'api';
import {ChatList} from 'api/blendTalk';
import {
  ChatReaction,
  getDateWithTime,
  getMessageTime,
  sortChatsByDate,
  transformToChatNotifications,
} from 'pages/BlendTalk/BlendTalkUtils';
import {showNotification} from 'components/BaseComponents/BaseNotification';
import Consts from 'pages/BlendTalk/BlendTalkConsts';

const {
  CHAT_BODY,
  USER_TYPE: {TRANSLATOR, ADMIN},
  RESPONSE_ERROR_TEXT,
  RESPONSE_ERROR_TEXT_ATTACHMENTS,
} = Consts;

export enum ChatUserStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export type ChatBodyContent = string;

export interface LanguagePair {
  sourceLanguage: string | null;
  targetLanguage: string | null;
}

export interface ChatUser {
  id: string;
  name: string;
  externalId: string;
  status: ChatUserStatus;
  pictureUrl?: string;
  languagePairs: LanguagePair[];
  type?: string;
}

export interface ChatContact {
  user: ChatUser;
}

export interface AddedUser {
  id: string;
}

export type AddedUsers = AddedUser[];
export type MessageType = 'direct' | 'reply' | 'system';

export interface UnreadNotificationChat {
  id: string;
  owner?: ChatOwner;
}

export interface UnreadNotificationMessage {
  id: string;
}

export interface UnreadNotification {
  id: string;
  type: string;
  createdAt: string;
  chat: UnreadNotificationChat;
  seen: boolean;
  message?: UnreadNotificationMessage;
}

export type ChatType = 'default' | 'custom' | 'my-team';

export interface ChatOwner {
  id: string;
  name: string;
  externalId: string;
}

export interface Chat {
  chatId: string;
  title: string;
  lastMessage: ChatMessage | null;
  deletedAt?: string;
  type?: ChatType;
  createdAt: string;
  pinnedAt?: string;
  isPinned?: boolean;
  chat?: ChatMessageForSearch;
  owner: ChatOwner;
}

export interface Attachment {
  fileId: string;
  filename: string;
  mimeType: string;
  size: number;
  id?: string | null;
  deletedAt?: string;
}

export interface ChatAttachment {
  attachment: Attachment;
  message: ChatMessage;
  chat?: Chat;
}

export interface ChatMessageForSearch {
  chatId: string;
  title: string;
  owner?: ChatUser;
  chat?: Chat;
  message?: ChatMessage;
}

export interface ChatMessage {
  id: string;
  author: ChatUser;
  text: string;
  createdAt: string;
  deletedAt?: string | null;
  attachments?: Attachment[];
  reactions?: ChatReaction[];
  type?: MessageType;
  message?: ChatMessage;
  chat?: Chat;
  action?: ChatMessageAction;
  user?: ChatUser;
  pageNumber?: number;
  pinnedAt: string | null;
  status: ChatModerationStatus;
}

export enum ChatModerationStatus {
  CREATED = 'created',
  ON_MODERATION = 'onModeration',
  DECLINED = 'moderationFailed',
  APPROVED = 'sent',
}

export type ChatMessageAction = 'add' | 'remove' | 'messagePin';

export interface ChatListSearchMessage {
  message: ChatMessage;
  chat: Chat;
}

export interface ChatSearch {
  messages: ChatListSearchMessage[];
  chats: Chat[];
  attachments: ChatAttachment[];
}

export interface LoadingHub {
  contacts: boolean;
  members: boolean;
  chatList: boolean;
  messages: boolean;
  files: boolean;
  globalSearch: boolean;
  localSearch: boolean;
  notifications: boolean;
  pinnedMessages: boolean;
  sendMessage: boolean;
}

export type UserType = 'translator' | 'admin' | 'customer';

export interface CurrentUser {
  name: string;
  status: string;
  type: UserType;
  id: string;
  externalId: string;
}

export interface MessageWSPayload {
  chat: {id: string};
  message: ChatMessage;
  attachment: Attachment;
  event: WEBSOCKET_EVENT;
  user?: ChatUser;
}

export interface SharedWithUser {
  userId: string;
}

export interface UploadedFile {
  id: string;
  originalName: string;
  mimeType: string;
  size: number;
  createdAt: string;
  sharedWith: SharedWithUser[];
}

export interface HasMore {
  top: boolean;
  bottom: boolean;
}

export interface TextareaAttachment {
  [key: string]: Attachment[];
}

export interface BlendTalkErrorItem {
  [key: string]: string | null;
}

export interface ErrorHub {
  chatList: string;
  messageList: string;
  contacts: string;
  message: BlendTalkErrorItem;
  globalSearch: string;
  localSearch: string;
  attachments: string;
  chat: BlendTalkErrorItem;
  pinnedMessages: string;
}

export interface ChatNotifications {
  [key: string]: UnreadNotification[];
}

export interface MessagePaginationPages {
  top: number;
  bottom: number;
}

interface BlendTalkState {
  contacts: ChatContact[];
  loading: LoadingHub;
  chatId: null | string;
  chatList: ChatList;
  chatMembers: ChatContact[];
  chatContent: ChatBodyContent;
  messages: ChatMessage[];
  messagePage: number;
  hasMore: HasMore;
  currentUser: null | CurrentUser;
  token: null | string;
  overflowChatPosition: null | number;
  attachments: ChatAttachment[];
  globalSearch: null | ChatSearch;
  selectedChatAction: string;
  textareaAttachment: TextareaAttachment;
  searchInChat: string;
  searchInChatList: ChatSearch['messages'] | null;
  adminUserId: null | string;
  notifications: {[key: string]: UnreadNotification[]};
  pinnedMessages: ChatMessage[];
  error: ErrorHub;
  messagePaginationPages: MessagePaginationPages;
  currentMessage: ChatMessage | null;
  selectedMessageId: string | null;
  paginationMessageId: string | null;
  isChatBottomDistanceExceeded: boolean;
  highlightedMessage: string | null;
  startPaginationPage: number;
}

const defaultState: BlendTalkState = {
  contacts: [],
  loading: {
    contacts: false,
    members: false,
    chatList: false,
    messages: false,
    globalSearch: false,
    localSearch: false,
    files: false,
    notifications: false,
    pinnedMessages: false,
    sendMessage: false,
  },
  chatId: null,
  chatList: [],
  chatMembers: [],
  chatContent: CHAT_BODY.NEW_CHAT,
  messages: [],
  messagePage: 1,
  hasMore: {top: false, bottom: false},
  currentUser: null,
  token: null,
  overflowChatPosition: null,
  attachments: [],
  globalSearch: null,
  selectedChatAction: '',
  textareaAttachment: {},
  adminUserId: null,
  searchInChat: '',
  searchInChatList: [],
  notifications: {},
  pinnedMessages: [],
  error: {
    chatList: '',
    messageList: '',
    contacts: '',
    message: {},
    globalSearch: '',
    localSearch: '',
    attachments: '',
    chat: {},
    pinnedMessages: '',
  },
  currentMessage: null,
  selectedMessageId: null,
  messagePaginationPages: {
    top: 1,
    bottom: 1,
  },
  paginationMessageId: null,
  isChatBottomDistanceExceeded: false,
  highlightedMessage: null,
  startPaginationPage: 1,
};

export enum WEBSOCKET_EVENT {
  NEW_MESSAGE = 'newMessage',
  MESSAGE_REACTION = 'messageReaction',
  DELETE_ATTACHMENT = 'messageAttachmentDeleted',
  CHAT_SEEN = 'chatSeen',
  MESSAGE_UPDATED = 'messageUpdated',
  MESSAGE_PINNED = 'messagePinned',
  MESSAGE_UNPINNED = 'messageUnpinned',
  PARAMS_CHANGED = 'projectParamsChanged',
  MESSAGE_DELETED = 'messageDeleted',
  MESSAGE_RESTORED = 'messageRestored',
  NEW_MEMBER_ADDED = 'chatMemberAdded',
  CHAT_REMOVED = 'chatRemoved',
  CHAT_CREATED = 'chatCreated',
}

const {
  NEW_MESSAGE,
  MESSAGE_REACTION,
  DELETE_ATTACHMENT,
  CHAT_SEEN,
  MESSAGE_UPDATED,
  MESSAGE_PINNED,
  MESSAGE_UNPINNED,
  MESSAGE_DELETED,
  MESSAGE_RESTORED,
  NEW_MEMBER_ADDED,
  CHAT_REMOVED,
  CHAT_CREATED,
} = WEBSOCKET_EVENT;

export function blendTalkStore() {
  return makeObservable(
    {
      ...defaultState,
      getContactList(token: string, customer?: string | null) {
        this.loading.contacts = true;
        this.error.contacts = '';

        const customerId = customer ? customer : this.adminUserId;

        api.blendTalk
          .getAllMyContacts(token, customerId)
          .then(
            action((response) => {
              this.contacts = response;
            })
          )
          .catch(
            action(() => {
              this.contacts = [];
              this.error.contacts = RESPONSE_ERROR_TEXT;
            })
          )
          .finally(() => (this.loading.contacts = false));
      },
      addNewMemberToChat(userId: string, token: string) {
        this.loading.members = true;

        this.chatId &&
          api.blendTalk
            .addNewMember(this.chatId, userId, token)
            .then((response) => {
              this.chatMembers = [...this.chatMembers, response];
            })
            .catch(() => {
              this.showChatNotifications(RESPONSE_ERROR_TEXT);
            })
            .finally(
              action(() => {
                this.loading.members = false;
              })
            );
      },
      getChatList(token: string, customerId?: string) {
        this.error = defaultState.error;
        if (!this.chatList.length && !this.loading.chatList) {
          this.loading.chatList = true;
          this.error.chatList = '';

          api.blendTalk
            .getAllChats(token, customerId)
            .then(
              action((response) => {
                this.chatList = sortChatsByDate(response);

                if (response.length && !Object.keys(this.notifications).length && !this.loading.notifications) {
                  this.fetchNotifications();
                }
              })
            )
            .catch(
              action(() => {
                this.error.chatList = RESPONSE_ERROR_TEXT;
                this.showChatNotifications(RESPONSE_ERROR_TEXT, 'chatList');
              })
            )
            .finally(action(() => (this.loading.chatList = false)));
        }
      },
      createNewChat(title: string, members: ChatContact[], token: string, custId?: string) {
        this.loading.chatList = true;
        const selectedIds: AddedUsers = members.map(({user: {id}}) => ({id}));

        api.blendTalk
          .createChat(title, selectedIds, token, custId)
          .then((response) => {
            this.chatList = sortChatsByDate([response, ...this.chatList]);
            this.chatId = response.chatId;
            this.chatMembers = members;
            this.messages = [];
            this.attachments = [];
            this.messagePage = 1;
            this.pinnedMessages = [];
          })
          .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
          .finally(action(() => (this.loading.chatList = false)));
      },
      updateChatById(chatId: string, chat: Partial<Chat>, token: string) {
        this.loading.chatList = true;
        this.error.chat[chatId] = null;
        api.blendTalk
          .editChat(chatId, chat, token)
          .then((response) => {
            runInAction(() => {
              const chatIndex = this.chatList.findIndex((ch) => ch.chatId === chatId);

              if (chatIndex >= 0) {
                this.chatList[chatIndex] = {...this.chatList[chatIndex], ...response};
                this.chatList = sortChatsByDate(this.chatList);
              }
            });
          })
          .catch(() => {
            action(() => (this.error.chat[chatId] = RESPONSE_ERROR_TEXT));
            this.showChatNotifications(RESPONSE_ERROR_TEXT, 'chat');
          })
          .finally(action(() => (this.loading.chatList = false)));
      },
      removeUserFromChat(userId: string, token: string) {
        this.chatId &&
          api.blendTalk
            .deleteMemberFromChat(this.chatId, userId, token)
            .then(() => {
              this.chatMembers = this.chatMembers.filter(({user: {id}}) => id !== userId);
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT));
      },
      getChatMembers(id: string, token: string) {
        if (!this.token) {
          this.token = token;
        }

        this.setChatId(id);

        this.currentUser &&
          this.currentUser?.type !== TRANSLATOR &&
          api.blendTalk
            .fetchChatMembers(id, token)
            .then((response) => {
              this.chatMembers = response;
            })
            .catch(() => {
              action(() => (this.chatMembers = []));
              this.showChatNotifications(RESPONSE_ERROR_TEXT);
            });
      },
      getChatMessages(page: number, token: string, parameterId?: string, selectedMessageId?: string) {
        if (!this.token) {
          this.token = token;
        }

        this.loading.messages = true;
        this.messagePage = page;
        const id = parameterId ? parameterId : this.chatId;
        if (page === 1 && parameterId) {
          this.messages = [];
          this.searchInChat = '';
          this.searchInChatList = [];
          this.messagePaginationPages = {top: 1, bottom: 1};
          this.setChatBottomDistanceExceed(false);
          this.startPaginationPage = page;
        }
        this.error.messageList = '';

        id &&
          api.blendTalk
            .fetchMessages(id, page, token)
            .then((response) => {
              this.messages = this.messagePage === 1 ? response.reverse() : [...response, ...this.messages];

              this.messagePaginationPages = {
                top: page > this.messagePaginationPages.top ? page : this.messagePaginationPages.top,
                bottom:
                  (page < this.messagePaginationPages.bottom && !parameterId) || parameterId
                    ? page
                    : this.messagePaginationPages.bottom,
              };

              this.hasMore.top = response.length === 30;
              this.hasMore.bottom = page !== 1;

              selectedMessageId && this.setSelectedMessageId(selectedMessageId);

              if (this.selectedMessageId || selectedMessageId) {
                const message = document.getElementById(this.selectedMessageId! || selectedMessageId!);

                if (message) {
                  message.scrollIntoView({behavior: 'auto'});
                }
              }
            })
            .catch(
              action(() => {
                this.messages = [];
                this.error.messageList = RESPONSE_ERROR_TEXT;
                this.showChatNotifications(RESPONSE_ERROR_TEXT, 'messageList');
              })
            )
            .finally(action(() => setTimeout(() => (this.loading.messages = false), 1000)));
      },
      sendMessage(text: string, token: string, attachments: Attachment[]) {
        if (this.chatId) {
          this.loading.sendMessage = true;

          api.blendTalk
            .createMessage(this.chatId, text, token, attachments)
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT_ATTACHMENTS))
            .finally(
              action(() => {
                this.loading.sendMessage = false;
              })
            );
        }
      },
      getCurrentUser(token: string) {
        !this.currentUser &&
          api.blendTalk
            .getUserData(token)
            .then((response) => {
              this.currentUser = response;
              this.chatId && !this.chatMembers.length && this.getChatMembers(this.chatId, token);
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT));
      },
      readChatById(id: string, token: string) {
        if (!this.token) {
          this.token = token;
        }

        api.blendTalk.readChat(id, token).then(
          action(() => {
            this.notifications[id] = [];
          })
        );
      },
      sendReplyMessage(messageId: string, text: string, token: string) {
        this.chatId &&
          api.blendTalk
            .createReplyMessage(this.chatId, messageId, text, token)
            .then((response) => {
              this.messages = [...this.messages, response];

              const index = this.chatList.findIndex(({chatId}) => this.chatId === chatId);

              if (index >= 0) {
                this.chatList[index].lastMessage = response;
              }
              this.chatList = sortChatsByDate(this.chatList);
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT_ATTACHMENTS));
      },
      wsConstructor(payload: MessageWSPayload) {
        const {event} = payload;
        if ([NEW_MESSAGE, MESSAGE_REACTION, MESSAGE_UPDATED].includes(event) && this.token) {
          const type = [MESSAGE_UPDATED, NEW_MESSAGE].includes(event) ? 'message' : 'reaction';
          payload?.chat?.id && this.addNewMessageProcessing(payload.chat.id, payload.message, this.token, type);
        } else if (event === DELETE_ATTACHMENT) {
          payload.attachment.id && this.removeAttachmentProcessing(payload.message.id, payload.attachment.id);
        } else if (event === CHAT_SEEN) {
          this.seenChatByIdProcessing(payload.chat.id);
        } else if ([MESSAGE_PINNED, MESSAGE_UNPINNED].includes(event)) {
          this.togglePinMessage(payload);
        } else if (event === NEW_MEMBER_ADDED) {
          this.addNewMemberProcessing(payload?.chat);
        } else if ([CHAT_CREATED, CHAT_REMOVED].includes(event)) {
          this.toggleRemoveUserFromChatProcessing(payload, event === CHAT_REMOVED);
        } else if ([MESSAGE_DELETED, MESSAGE_RESTORED].includes(event)) {
          this.toggleDeleteMessageProcessing(payload, event === MESSAGE_RESTORED);
        }
      },
      sendMessageReaction(id: string, type: string, token: string) {
        this.chatId &&
          api.blendTalk
            .createMessageReaction(id, type, token)
            .then((response) => {
              this.error.message[id] = '';
              const index = this.messages.findIndex((message) => message.id === response.id);

              if (index >= 0) {
                const newMessages = [...this.messages];
                newMessages[index] = response;

                this.messages = newMessages;
              }
            })
            .catch(() => {
              action(() => (this.error.message[id] = RESPONSE_ERROR_TEXT));
              this.showChatNotifications('message', RESPONSE_ERROR_TEXT);
            });
      },
      setChatId(id: string | null) {
        this.chatId = id;
      },
      setChatAction(content: string) {
        this.selectedChatAction = content;
      },
      selectChatContent(content: ChatBodyContent) {
        this.chatContent = content;
      },
      setToken(token: string | null) {
        this.token = token;
      },
      setChatPosition(position: number | null) {
        this.overflowChatPosition = position;
      },
      setGlobalSearch(payload: ChatSearch | null) {
        this.globalSearch = payload;
      },
      addNewMessageProcessing(id: string, message: ChatMessage, token: string, type: string) {
        const unreadMessage = {
          createdAt: message.createdAt,
          id: message.id,
          type,
          chat: {id},
          seen: false,
          message: {id: message.id, text: message.text},
        };

        if (this.chatId === id) {
          const messageIndex = this.messages.findIndex((m) => m.id === message.id);
          if (messageIndex >= 0 && type !== 'reaction') {
            const newMessages = [...this.messages];
            newMessages[messageIndex] = {...newMessages[messageIndex], ...message};
            this.messages = [...newMessages];

            if (this.currentMessage?.id === message?.id) {
              this.currentMessage = {...message};
            }
          } else {
            this.messages = type !== 'reaction' ? [...this.messages, message] : [...this.messages];
          }

          ((message.status === ChatModerationStatus.APPROVED && !this.isChatBottomDistanceExceeded) ||
            message?.author?.id === this.currentUser?.id) &&
            this.readChatById(id, token);

          if (message.attachments?.length && message.status === ChatModerationStatus.APPROVED) {
            const newAttachments = message.attachments.map((attachment) => ({message, attachment}));
            this.attachments = [...newAttachments, ...this.attachments];
          }

          if (
            this.isChatBottomDistanceExceeded &&
            message.status === ChatModerationStatus.APPROVED &&
            message.author.id !== this.currentUser?.id
          ) {
            const notifications = {...this.notifications};

            notifications[id] = [...(notifications[id] || []), unreadMessage];

            this.notifications = notifications;
          }
        }

        const index = this.chatList.findIndex(({chatId}) => id === chatId);

        const chatList = [...this.chatList];

        if (index >= 0) {
          if (
            type !== 'reaction' &&
            (message.status === ChatModerationStatus.APPROVED || this.currentUser?.id === message?.author?.id)
          ) {
            const prevLastMessage = chatList![index]!.lastMessage;
            const prevLastMessageCreateDate = !!prevLastMessage?.createdAt && new Date(prevLastMessage.createdAt);
            const nextMessageCreateDate = !!message?.createdAt && new Date(message.createdAt);

            if (!prevLastMessageCreateDate || nextMessageCreateDate > prevLastMessageCreateDate) {
              chatList[index].lastMessage = message;
            }
          }

          if (this.chatId !== id) {
            const notifications = {...this.notifications};
            const currentNotifications = notifications[id] || [];
            const isIncludes = currentNotifications.some((notif) => notif.id === unreadMessage.id);

            const getFilteredNotifications =
              message.status !== ChatModerationStatus.APPROVED && this.currentUser?.type !== ADMIN
                ? currentNotifications.filter((notification) => notification?.message?.id !== message.id)
                : currentNotifications;

            const getNewNotifications =
              message.status === ChatModerationStatus.APPROVED || this.currentUser?.type === ADMIN
                ? [...currentNotifications, unreadMessage]
                : currentNotifications;

            notifications[id] = isIncludes ? getFilteredNotifications : getNewNotifications;

            this.notifications = notifications;
          }

          this.chatList = sortChatsByDate(chatList);
        }
      },
      searchChatList(search: string) {
        this.loading.globalSearch = true;
        this.token &&
          api.blendTalk
            .getChatListSearch(search, this.token, this.adminUserId && this.currentUser?.id)
            .then((response) => {
              const isAllEmpty = Object.values(response).every((arr) => !arr.length);
              this.globalSearch = isAllEmpty ? null : response;
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
            .finally(action(() => (this.loading.globalSearch = false)));
      },
      deleteMessage(id: string) {
        this.loading.messages = true;
        this.token &&
          api.blendTalk
            .removeMessage(id, this.token)
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
            .finally(action(() => (this.loading.messages = false)));
      },
      restoreMessage(id: string) {
        this.loading.messages = true;
        this.token &&
          api.blendTalk
            .restoreMessage(id, this.token)
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
            .finally(action(() => (this.loading.messages = false)));
      },
      fetchChatFiles(chatId: string, token: string) {
        this.loading.files = true;
        api.blendTalk
          .getChatAttachments(chatId, token)
          .then((response) => {
            this.attachments = response;
          })
          .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
          .finally(() => {
            this.loading.files = false;
          });
      },
      setSearchInChat(value: string) {
        this.searchInChat = value;
      },
      setSearchListInChat(value: ChatSearch['messages'] | null) {
        this.searchInChatList = value;
      },
      searchInsideChat(chatId: string, search: string) {
        if (this.token) {
          this.loading.localSearch = true;
          api.blendTalk
            .getMessageListBySearch(search, chatId, this.token)
            .then((response) => {
              const isAllEmpty = Object.values(response).every((arr) => !arr.length);

              this.setSearchListInChat(isAllEmpty ? null : response.messages);
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
            .finally(
              action(() => {
                this.loading.localSearch = false;
              })
            );
        }
      },
      downloadFile(fileId: string, filename: string) {
        this.token && api.blendTalk.downloadFile(fileId, filename, this.token);
      },
      setTextareaAttachment(chatId: string, attachments: Attachment[]) {
        this.textareaAttachment = {...this.textareaAttachment, [chatId]: attachments};
      },
      setHasMore(value: Partial<HasMore>) {
        this.hasMore = {...this.hasMore, ...value};
      },
      getMessagePage(page: number) {
        this.searchInChat = '';
        this.searchInChatList = [];
        if (this.token) {
          this.messagePage = page;
          this.hasMore = {top: true, bottom: page > 1};
          this.getChatMessages(page, this.token);
        }
      },
      removeAttachmentProcessing(messageId: string, attachmentId: string) {
        const deletedAt = getMessageTime(dayjs().toString());
        const newMessages = [...this.messages];
        const index = newMessages.findIndex(({id}) => id === messageId);

        this.attachments = this.attachments.filter(({attachment: {id}}) => id !== attachmentId);

        if (index >= 0 && newMessages[index]?.attachments?.length) {
          const attachmentIndex = newMessages[index].attachments!.findIndex(({id}) => id === attachmentId);

          if (attachmentIndex >= 0 && newMessages[index].attachments?.[attachmentIndex]) {
            newMessages[index]!.attachments![attachmentIndex].deletedAt = deletedAt;
            this.messages = newMessages;
          }
        }
      },
      removeAttachment(messageId: string, attachmentId: string) {
        if (this.token) {
          this.loading.files = true;

          api.blendTalk
            .removeAttachment(messageId, attachmentId, this.token)
            .then(() => {
              this.removeAttachmentProcessing(messageId, attachmentId);
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
            .finally(action(() => (this.loading.files = false)));
        }
      },
      setAdminUserId(id: string | null) {
        this.adminUserId = id;
      },
      fetchNotifications(otherToken?: string) {
        if (this.token || otherToken) {
          this.loading.notifications = true;

          api.blendTalk
            .getChatNotifications((this.token || otherToken) as string)
            .then((response) => {
              this.notifications = transformToChatNotifications(response);

              if (this.chatId && this.notifications[this.chatId] && this.token) {
                this.readChatById(this.chatId, this.token);
              }
            })
            .catch(action(() => (this.notifications = {})))
            .finally(
              action(() => {
                this.loading.notifications = false;
              })
            );
        }
      },
      seenChatByIdProcessing(chatId: string) {
        if (this.notifications[chatId]) {
          const notifications = {...this.notifications};
          notifications[chatId] = [];
          this.notifications = notifications;
        }
      },
      updateMessage(data: MessageWSPayload) {
        const {chat, message} = data;

        if (chat?.id === this.chatId) {
          const newMessages = [...this.messages];
          const index = newMessages.findIndex(({id}) => id === message.id);

          if (index >= 0) {
            newMessages[index] = message;
            this.messages = newMessages;
          }
        } else {
          if (chat?.id) {
            const unreadMessage = {
              createdAt: message.createdAt,
              id: message.id,
              type: message.type as MessageType,
              chat,
              seen: false,
            };

            const notifications = {...this.notifications};
            const currentNotifications = notifications[chat.id] || [];
            const isIncludes = currentNotifications.some((notif) => notif.id === unreadMessage.id);

            notifications[chat.id] = isIncludes ? currentNotifications : [...currentNotifications, unreadMessage];

            this.notifications = notifications;
          }
        }
      },
      hasAlreadyError(excludeKey?: string) {
        return Object.keys(this.error).some((key) => {
          return !['chat', 'message'].includes(key) && excludeKey !== key && !!this.error[key as keyof ErrorHub];
        });
      },
      showChatNotifications(message: string, excludeKey?: string) {
        (!excludeKey || this.hasAlreadyError(excludeKey)) && showNotification(message, {type: 'error'});
      },
      fetchPinnedMessages() {
        this.pinnedMessages = [];
        if (this.token && this.chatId) {
          this.loading.pinnedMessages = true;

          api.blendTalk
            .getPinnedMessages(this.chatId, this.token)
            .then((response) => {
              this.pinnedMessages = response;
            })
            .catch(() => this.showChatNotifications(RESPONSE_ERROR_TEXT))
            .finally(action(() => (this.loading.pinnedMessages = false)));
        }
      },
      modifyPinnedMessage(messageId: string, isPinned: boolean) {
        if (this.token) {
          this.loading.pinnedMessages = true;

          api.blendTalk
            .updateMessage(messageId, isPinned, this.token)
            .catch(
              action(() => {
                this.showChatNotifications(RESPONSE_ERROR_TEXT);
              })
            )
            .finally(action(() => (this.loading.pinnedMessages = false)));
        }
      },
      updateCurrentMessage(message: null | ChatMessage) {
        this.currentMessage = message;
      },
      togglePinMessage(data: MessageWSPayload) {
        const {
          message: {pinnedAt, id},
          message,
        } = data;

        let newPinnedMessages = [...this.pinnedMessages];

        newPinnedMessages = !pinnedAt
          ? this.pinnedMessages.filter((pm) => pm.id !== id)
          : [...this.pinnedMessages, message];

        this.pinnedMessages = newPinnedMessages;
        const index = this.messages.findIndex((m) => m.id === id);

        if (index >= 0) {
          const newMessages = [...this.messages];
          newMessages[index].pinnedAt = !pinnedAt ? null : message.pinnedAt;
          this.messages = newMessages;
        }
      },
      toggleDeleteMessageProcessing({message, chat}: MessageWSPayload, isRestore = false) {
        if (chat?.id !== this.chatId) {
          return;
        }

        const index = this.messages.findIndex((msg) => msg.id === message.id);
        if (index >= 0) {
          const newMessages = [...this.messages];

          newMessages[index] = {...newMessages[index], ...message, deletedAt: isRestore ? null : getDateWithTime()};

          this.messages = newMessages;
        }
      },
      startWithNewPaginationPage(page: number) {
        this.messagePage = page;
        this.messagePaginationPages = {top: page, bottom: page};
      },
      setSelectedMessageId(id: string | null) {
        this.selectedMessageId = id;
      },
      updatePaginationMessageId(id: string | null) {
        this.paginationMessageId = id;
      },
      addNewMemberProcessing(chat?: MessageWSPayload['chat']) {
        if (this.chatId === chat?.id && this.token) {
          this.getChatMembers(this.chatId, this.token);
        }
      },
      toggleRemoveUserFromChatProcessing(data: MessageWSPayload, remove?: boolean) {
        const {
          chat: {id},
        } = data;

        const index = this.chatList.findIndex(({chatId}) => chatId === id);

        if (index >= 0) {
          const newChatList = [...this.chatList];

          newChatList[index].deletedAt = remove ? getDateWithTime() : undefined;

          this.chatList = newChatList;
        }

        if (index === -1 && this.token) {
          this.getChatList(this.token);
        }
      },
      setChatBottomDistanceExceed(isExeed: boolean) {
        this.isChatBottomDistanceExceeded = isExeed;
      },
      setHighlightedMessage(id: string | null) {
        action(() => (this.highlightedMessage = id));
      },
      setStartPaginationPage(page: number) {
        action(() => (this.startPaginationPage = page));
      },
      resetStore() {
        this.contacts = defaultState.contacts;
        this.loading = defaultState.loading;
        this.chatId = defaultState.chatId;
        this.chatList = defaultState.chatList;
        this.chatMembers = defaultState.chatMembers;
        this.chatContent = defaultState.chatContent;
        this.messages = defaultState.messages;
        this.messagePage = defaultState.messagePage;
        this.hasMore = defaultState.hasMore;
        this.currentUser = defaultState.currentUser;
        this.overflowChatPosition = defaultState.overflowChatPosition;
        this.attachments = defaultState.attachments;
        this.globalSearch = defaultState.globalSearch;
        this.selectedChatAction = defaultState.selectedChatAction;
        this.textareaAttachment = defaultState.textareaAttachment;
        this.adminUserId = defaultState.adminUserId;
        this.notifications = defaultState.notifications;
        this.error = defaultState.error;
        this.messagePaginationPages = defaultState.messagePaginationPages;
        this.pinnedMessages = defaultState.pinnedMessages;
        this.currentMessage = defaultState.currentMessage;
        this.selectedMessageId = defaultState.selectedMessageId;
        this.paginationMessageId = defaultState.paginationMessageId;
        this.isChatBottomDistanceExceeded = defaultState.isChatBottomDistanceExceeded;
        this.highlightedMessage = defaultState.highlightedMessage;
      },
    },
    {
      contacts: observable,
      loading: observable,
      getContactList: action.bound,
      addNewMemberToChat: action.bound,
      chatId: observable,
      setChatId: action.bound,
      chatList: observable.deep,
      getChatList: action.bound,
      createNewChat: action.bound,
      updateChatById: action.bound,
      removeUserFromChat: action.bound,
      chatMembers: observable,
      getChatMembers: action.bound,
      chatContent: observable,
      selectChatContent: action.bound,
      messages: observable,
      getChatMessages: action.bound,
      sendMessage: action.bound,
      messagePage: observable,
      hasMore: observable,
      currentUser: observable,
      getCurrentUser: action.bound,
      readChatById: action.bound,
      sendReplyMessage: action.bound,
      wsConstructor: action.bound,
      addNewMessageProcessing: action.bound,
      sendMessageReaction: action.bound,
      token: observable,
      setToken: action.bound,
      overflowChatPosition: observable,
      setChatPosition: action.bound,
      searchChatList: action.bound,
      setGlobalSearch: action.bound,
      globalSearch: observable,
      deleteMessage: action.bound,
      attachments: observable,
      setChatAction: action.bound,
      selectedChatAction: observable,
      fetchChatFiles: action.bound,
      downloadFile: action.bound,
      textareaAttachment: observable,
      setTextareaAttachment: action.bound,
      searchInChat: observable,
      setSearchInChat: action.bound,
      searchInChatList: observable,
      setSearchListInChat: action.bound,
      searchInsideChat: action.bound,
      getMessagePage: action.bound,
      setHasMore: action.bound,
      adminUserId: observable,
      setAdminUserId: action.bound,
      resetStore: action.bound,
      removeAttachmentProcessing: action.bound,
      removeAttachment: action.bound,
      notifications: observable,
      fetchNotifications: action.bound,
      seenChatByIdProcessing: action.bound,
      error: observable,
      hasAlreadyError: action.bound,
      showChatNotifications: action.bound,
      updateMessage: action.bound,
      pinnedMessages: observable,
      fetchPinnedMessages: action.bound,
      modifyPinnedMessage: action.bound,
      currentMessage: observable,
      updateCurrentMessage: action.bound,
      togglePinMessage: action.bound,
      setSelectedMessageId: action.bound,
      selectedMessageId: observable,
      messagePaginationPages: observable,
      paginationMessageId: observable,
      updatePaginationMessageId: action.bound,
      toggleDeleteMessageProcessing: action.bound,
      restoreMessage: action.bound,
      addNewMemberProcessing: action.bound,
      toggleRemoveUserFromChatProcessing: action.bound,
      isChatBottomDistanceExceeded: observable,
      setChatBottomDistanceExceed: action.bound,
      highlightedMessage: observable,
      setHighlightedMessage: action.bound,
      startPaginationPage: observable,
      setStartPaginationPage: action.bound,
      startWithNewPaginationPage: action.bound,
    }
  );
}

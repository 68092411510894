import dompurify from 'dompurify';
import Showdown from 'showdown';

export function SanitizeHtml(html: string): string {
  return dompurify.sanitize(html, {ADD_ATTR: ['target']});
}

export function ShowdownSanitizor(html: string): string {
  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
    smartIndentationFix: true,
  });
  return converter.makeHtml(html);
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function glossaryFilesStore() {
  return makeObservable(
    {
      sourceLanguage: '' as string,
      targetLanguage: '' as string,
      glossaryLanguages: [] as [],
      glossaryFiles: [] as [],
      isLoading: false as boolean,
      setSourceLanguage(language: string) {
        this.sourceLanguage = language;
      },
      setTargetLanguage(language: string) {
        this.targetLanguage = language;
      },
      getGlossaryLanguages(CSRFToken: string) {
        api.profile.getTerminologyLanguages(CSRFToken).then((response) => {
          this.glossaryLanguages = response?.results || [];
        });
      },
      getGlossaryFiles(uuid: string) {
        this.isLoading = true;
        api.profile
          .getGlossaryFilesList(uuid)
          .then((response) => {
            this.glossaryFiles = response?.results || [];
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      uploadGlossaryFile(file: any, CSRFToken: string, uuid: string) {
        const data = new FormData();
        data.append('file', file);
        data.append('upload_file', 'true');
        data.append('source_language', this.sourceLanguage);
        data.append('target_language', this.targetLanguage);
        data.append('CSRFToken', CSRFToken);

        this.isLoading = true;

        api.profile
          .uploadGlossaryFile({
            fd: data,
            uuid,
          })
          .then(() => {
            this.sourceLanguage = '';
            this.targetLanguage = '';
            this.getGlossaryFiles(uuid);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      deleteGlossaryFile(id: string, CSRFToken: string, uuid: string) {
        this.isLoading = true;

        api.profile
          .deleteGlossaryFile(uuid, id, CSRFToken)
          .then(() => {
            this.getGlossaryFiles(uuid);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      sourceLanguage: observable,
      targetLanguage: observable,
      glossaryLanguages: observable,
      glossaryFiles: observable,
      isLoading: observable,
      setSourceLanguage: action.bound,
      setTargetLanguage: action.bound,
      getGlossaryLanguages: action.bound,
      uploadGlossaryFile: action.bound,
      deleteGlossaryFile: action.bound,
    }
  );
}

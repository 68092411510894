import {
  getMyProjectsDefault,
  getMyProjectsLl,
  getMyProjectsReviewedByQaLl,
  getMyProjectsReviews,
} from 'api/translatorMyProjects';
import {makeObservable, observable, action} from 'mobx';
import {rowsPerPageOptions} from 'pages_refactor/MyProjects/MyProjectsUtils';
import {
  IDefaultMyProject,
  IMyProjectLl,
  IMyProjectReviewedByQaLL,
  IReviewMyProject,
} from 'pages_refactor/TranslatorMyProjects/TranslatorMyProjectsUtils';
import {
  DEFAULT_FILTERS_DATA,
  IMyProjectsFiltersData,
  getFilterParams,
} from 'pages_refactor/TranslatorMyProjects/components/FiltersAndPagination/components/FilterModalUtils';
import {MyProjectTypeEnum} from 'pages_refactor/TranslatorMyProjects/components/Tabs/TabsUtils';

export function translatorMyProjectsStore() {
  return makeObservable(
    {
      isLoading: false as boolean,
      selectedProjectType: MyProjectTypeEnum.DEFAULT as MyProjectTypeEnum,
      projectsCount: 0 as number,
      projectsData: [] as IDefaultMyProject[] | IMyProjectReviewedByQaLL[] | IMyProjectLl[] | IReviewMyProject[],
      page: 0 as number,
      rowsPerPage: rowsPerPageOptions[0] as number,
      filtersData: DEFAULT_FILTERS_DATA as IMyProjectsFiltersData,
      setSelectedProjectType(newType: MyProjectTypeEnum) {
        this.page = 0;
        this.rowsPerPage = rowsPerPageOptions[0];
        this.filtersData = DEFAULT_FILTERS_DATA;
        this.projectsCount = 0;
        this.projectsData = [];
        this.selectedProjectType = newType;
      },
      getProjectsList(CSRFToken: string) {
        const requestMap = {
          [MyProjectTypeEnum.DEFAULT]: getMyProjectsDefault,
          [MyProjectTypeEnum.PRIORITY]: getMyProjectsDefault,
          [MyProjectTypeEnum.REVIEWS]: getMyProjectsReviews,
          [MyProjectTypeEnum.LL]: getMyProjectsLl,
          [MyProjectTypeEnum.REVIEWEDBYQALL]: getMyProjectsReviewedByQaLl,
        };
        const requestFunction = requestMap[this.selectedProjectType];
        this.isLoading = true;
        const filtersObj = getFilterParams(this.filtersData);

        requestFunction({
          CSRFToken,
          limit: String(this.rowsPerPage),
          page: String(this.page),
          sortBy: 'id',
          sortDirection: 'desc',
          ...filtersObj,
        })
          .then(
            action((data) => {
              if (!data.errors.length) {
                this.projectsData = data.results.projects;
                this.projectsCount = data.results.projectsCount;
              }
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      setRowsPerPage(newValue: number) {
        this.rowsPerPage = newValue;
      },
      setPage(newValue: number) {
        this.page = newValue;
      },
      setFiltersData(newFilters: IMyProjectsFiltersData) {
        this.filtersData = newFilters;
      },
    },
    {
      isLoading: observable,
      selectedProjectType: observable,
      projectsCount: observable,
      rowsPerPage: observable,
      page: observable,
      projectsData: observable.deep,
      filtersData: observable.deep,
      setSelectedProjectType: action.bound,
      getProjectsList: action.bound,
      setRowsPerPage: action.bound,
      setPage: action.bound,
      setFiltersData: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function clientAlertsStore() {
  return makeObservable(
    {
      alerts: {} as any,
      userId: new URLSearchParams(window.location.search).get('user_id') || '',
      getAlerts() {
        api.alerts.getAlerts(this.userId).then((response) => {
          this.alerts = response?.results || {};
        });
      },
      checkOneAlert(key: any, am: any, sales: any, support: any, CSRFToken: string) {
        this.alerts[key].config.am = am;
        this.alerts[key].config.sales = sales;
        this.alerts[key].config.support = support;

        const data = new FormData();
        data.append('user_id', this.userId);
        data.append('CSRFToken', CSRFToken);
        data.append('settings[' + key + '][am]', am);
        data.append('settings[' + key + '][sales]', sales);
        data.append('settings[' + key + '][support]', support);

        api.alerts.postAlerts({fd: data}).then(() => {
          this.getAlerts();
        });
      },
      checkAllAlerts(isChecked: boolean, CSRFToken: string) {
        const data = new FormData();
        data.append('user_id', this.userId);
        data.append('CSRFToken', CSRFToken);

        Object.keys(this.alerts).forEach((key) => {
          this.alerts[key].config.am = Number(isChecked);
          data.append('settings[' + key + '][am]', Number(isChecked).toString());

          this.alerts[key].config.sales = Number(isChecked);
          data.append('settings[' + key + '][sales]', Number(isChecked).toString());

          this.alerts[key].config.support = Number(isChecked);
          data.append('settings[' + key + '][support]', Number(isChecked).toString());
        });

        api.alerts.postAlerts({fd: data}).then(() => {
          this.getAlerts();
        });
      },
    },
    {
      alerts: observable,
      userId: observable,
      getAlerts: action.bound,
      checkOneAlert: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function passwordUpdateStore() {
  return makeObservable(
    {
      currentPassword: '' as string,
      newPassword: '' as string,
      newPasswordRepeat: '' as string,
      errorMessages: {} as any,
      isLoading: false as boolean,
      setCurrentPassword(password: string) {
        this.currentPassword = password;
      },
      setNewPassword(password: string) {
        this.newPassword = password;
      },
      setNewPasswordRepeat(password: string) {
        this.newPasswordRepeat = password;
      },
      submitPassword(CSRFToken: string) {
        this.errorMessages = {};
        this.isLoading = true;
        api.profile
          .updatePassword({
            CSRFToken: CSRFToken,
            current_pass: this.currentPassword,
            new_pass: this.newPassword,
            new_pass_repeat: this.newPasswordRepeat,
          })
          .then((response) => {
            if (response.status !== 200) {
              return;
            }
            if (response?.data?.ok === 1) {
              this.errorMessages = {update_success: 'Password Updated!'};
            } else {
              this.errorMessages = response?.data?.errors;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      currentPassword: observable,
      newPassword: observable,
      newPasswordRepeat: observable,
      errorMessages: observable,
      isLoading: observable,
      setCurrentPassword: action.bound,
      setNewPassword: action.bound,
      setNewPasswordRepeat: action.bound,
    }
  );
}

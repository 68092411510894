import {makeObservable, observable, action, computed} from 'mobx';
import api from 'api';
import {InvoiceRowProps} from 'pages/Invoices/InvoicesUtils';

type InvoiceData = {
  invoiceID: number;
  customerDetails: {
    name: string;
    company: string;
    vatId: string;
    country: string;
    billingAddress: string;
    billingDetails: string;
  };
  fullAddress: string;
  invoiceDate: string;
  Amount: string;
  dueDate: string;
  creditsWorth: number;
  rate: string;
  calculatedAmount: string;
  subTotal: string;
  VAT: string;
  processingFee: string;
  discount: number | null;
  total: string;
  paymentMade: string;
  balanceDue: string;
  moreInfo: {
    min_payment_adjustment: string;
  };
  currency: {
    symbol: string;
  };
};

export function invoicesStore() {
  return makeObservable(
    {
      rows: [] as InvoiceRowProps[],
      page: 1 as number,
      rowsPerPage: 5 as number,
      invoiceId: undefined as number | undefined,
      invoiceData: undefined as InvoiceData | undefined,
      modalVisible: true as boolean,
      invoiceOpen: false as boolean,
      isLoading: false as boolean,
      changePage(page: number) {
        this.page = page;
      },
      setInvoiceId(id: number | undefined) {
        this.invoiceId = id;
        if (id) {
          this.loadInvoice();
        }
      },
      setModalVisible(visible: boolean) {
        this.modalVisible = visible;
        this.isLoading = !visible;
        if (!visible) {
          this.setInvoiceOpen(true);
        }
      },
      setInvoiceOpen(open: boolean) {
        this.invoiceOpen = open;
        if (!open) {
          this.invoiceId = undefined;
        }
      },
      get currentPage() {
        return this.rows.slice(
          (this.page - 1) * this.rowsPerPage,
          (this.page - 1) * this.rowsPerPage + this.rowsPerPage
        );
      },
      loadInvoices() {
        this.isLoading = true;
        api.invoices
          .getInvoices()
          .then(
            action(({data}) => {
              this.rows = data?.results;
            })
          )
          .catch((err) => console.log('Error while getting all invoices', err))
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      loadInvoice() {
        this.isLoading = true;
        api.invoices
          .getInvoice(this.invoiceId)
          .then(
            action(({data}) => {
              this.invoiceData = data?.results;
              this.invoiceOpen = true;
            })
          )
          .catch((err) => console.log('Error while getting invoice', err))
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      eraseInvoiceData() {
        this.invoiceData = undefined;
      },
    },
    {
      rows: observable,
      page: observable,
      rowsPerPage: observable,
      invoiceId: observable,
      invoiceData: observable,
      modalVisible: observable,
      invoiceOpen: observable,
      isLoading: observable,
      currentPage: computed,
      changePage: action,
      setInvoiceId: action,
      setModalVisible: action,
      setInvoiceOpen: action,
      loadInvoices: action,
      loadInvoice: action,
      eraseInvoiceData: action,
    }
  );
}

import http from '../utils/api';

export class ApiService {
  constructor() {
    throw new Error('Cannot construct singleton');
  }

  static async getResource(projectId: string | number, resource_uuid: string, token: string) {
    const path = `/registry/get?project_id=${projectId}&resource_uuid=${resource_uuid}&_token=${token}&data=PostResource`;
    const res = await http.get(path);
    return res?.PostResource;
  }

  static getKycFormTypes(task_uuid: string) {
    const path = `/api/3/tasks/form-types?task_uuid=${task_uuid}`;
    return http.get(path);
  }

  static async postResource(projectId: string | number, resource_uuid: string, body: any) {
    const path = `/api/2/projects/${projectId}/resource/${resource_uuid}`;
    return http.post(path, body);
  }
}

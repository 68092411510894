import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function resumeCertificatesStore() {
  return makeObservable(
    {
      resume: {
        name: '' as string,
        link: '' as string,
      },
      document: {
        name: '' as string,
        link: '' as string,
      },
      certificates: [] as Array<{name: string; link: string}>,
      isLoading: false as boolean,
      setResume(resume: {name: string; link: string}) {
        this.resume = resume;
      },
      setDocument(document: {name: string; link: string}) {
        this.document = document;
      },
      setCertificates(list: []) {
        this.certificates = list;
      },
      uploadResume(file: any) {
        const data = new FormData();
        data.append('cvUpload', file);
        data.append('is_react', '1');
        this.isLoading = true;
        api.profile
          .uploadCV({fd: data})
          .then(({response}) => {
            if (response?.name && response?.link) {
              this.resume = response;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      uploadDocument(file: any) {
        const data = new FormData();
        data.append('is_react', '1');
        data.append('govIDUpload', file);
        this.isLoading = true;
        api.profile
          .uploadGovID({fd: data})
          .then(({response}) => {
            if (response?.name && response?.link) {
              this.document = response;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      uploadCertificate(file: any) {
        const data = new FormData();
        data.append('cert', file);
        data.append('is_react', '1');
        this.isLoading = true;
        api.profile
          .uploadCertification({fd: data})
          .then(({response}) => {
            if (response?.name && response?.link) {
              this.certificates.push(response);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      resume: observable,
      document: observable,
      certificates: observable,
      isLoading: observable,
      setResume: action.bound,
      setDocument: action.bound,
      setCertificates: action.bound,
    }
  );
}

import {action, makeObservable, observable} from 'mobx';
import dayjs from 'dayjs';
import api from 'api';

interface IDateFilter {
  from: string;
  to: string;
  textValue: string;
}

export function LmDashboardProjectsStore() {
  return makeObservable(
    {
      info: {
        loadData: true as boolean,
        currentSource: '' as string,
        currentTarget: '' as string,
        type: '' as string,
        title: 'Projects' as string,
        projects: [] as any[],
        sort: '' as string,
        orderAsc: false as boolean,
        filteredProjects: [] as [],
        LowCQRTranslators: [
          {
            id: 2,
            name: 'Dina Hennessey',
            projects: 8,
          },
          {
            id: 3,
            name: 'Vova Shelby',
            projects: 5,
          },
          {
            id: 4,
            name: 'Sam Vozniak',
            projects: 20,
          },
        ],
        availableTranslators: [
          {
            id: 2,
            name: 'Dina Hennessey',
            email: 'dina@oht.cc',
            status: 'No available',
            top: 0,
          },
          {
            id: 3,
            name: 'Vova Shelby',
            email: 'vova@oht.cc',
            status: 'On vacation',
            top: 1,
          },
          {
            id: 4,
            name: 'Sam Vozniak',
            email: 'sam@oht.cc',
            status: 'Available',
            top: 0,
          },
        ],
        currentPage: 0,
        totalCountData: 100,
        pageNumbers: [1, 2],
      },
      perPage: 30 as number,
      type: '' as string,
      activeFilter: '' as string,
      dateFilter: {
        from: dayjs().clone().subtract(6, 'months').format('YYYY-MM-DD'),
        to: '',
        textValue: 'Six months',
      } as IDateFilter,
      expertisesList: [] as {key: number; title: string}[],
      updateInfo(newInfo: any) {
        this.info = {...this.info, ...newInfo};
      },
      setType(type: string) {
        this.type = type;
      },
      setActiveFilter(filter: string) {
        this.activeFilter = filter === 'editing' ? 'PostTranslationProofreadingProject' : filter;
        this.changePage(1);
      },
      changeSort(field: string) {
        this.updateInfo({
          sort: field,
          orderAsc: field === this.info.sort ? !this.info.orderAsc : true,
        });
        this.getProjects();
      },
      changePage(page: number) {
        this.updateInfo({
          currentPage: page - 1,
        });
        this.getProjects();
      },
      getExpertisesList() {
        api.wizard.getExpertises().then((response) => {
          this.expertisesList = response?.results?.Expertise;
          if (response?.results?.Expertise?.length) {
            this.getProjects();
          }
        });
      },
      getUrl() {
        let url = '';

        switch (this.type) {
          case 'waiting':
            url = '/api/2/project/list?qa_workflow_status=all&workflow_status=2&exclude_statuses[]=temp_draft';
            break;
          case 'inprogress':
            url = '/api/2/project/list?qa_workflow_status=all&workflow_status=3';
            break;
          case 'low_ll':
            url = '/api/2/project/list?qa_workflow_status=all&alerts=166d4472-870a-11eb-acb3-0242ac130011';
            break;
          case 'low_quality':
            url = '/api/2/project/list?qa_workflow_status=all&alerts=c7023fd6-f2f3-407f-a854-fc3e2a6bb32f';
            break;
          case 'low_qa':
            url =
              '/api/2/project/list?qa_workflow_status=all&alerts=c836d9d2-0f38-461e-bb50-e7174eb97c61,d3913227-4ff4-11eb-a29a-0224018ec8ca';
            break;
          case 'delayed_projects':
            url =
              '/api/2/project/list?qa_workflow_status=all&workflow_status=2&delayed=2&exclude_statuses[]=temp_draft';
            break;
          case 'expired':
            url = '/api/2/project/list?qa_workflow_status=all&workflow_status=9';
            break;
        }

        if (this.loadLanguage('Source')) {
          url += '&source_language=' + this.loadLanguage('Source');
        }
        if (this.loadLanguage('Target')) {
          url += '&target_language=' + this.loadLanguage('Target');
        }
        if (this.loadLanguage('Client')) {
          url += '&client=' + this.loadLanguage('Client').replace(/[+]/g, '%2B');
        }

        return url;
      },
      getTitle() {
        let title = '';

        switch (this.type) {
          case 'waiting':
            title = 'Waiting Projects';
            break;
          case 'inprogress':
            title = 'In progress Projects';
            break;
          case 'low_ll':
            title = 'Projects with low LL reviews';
            break;
          case 'low_quality':
            title = 'Projects with low quality complaint';
            break;
          case 'low_qa':
            title = 'Projects with Low QA';
            break;
          case 'delayed_projects':
            title = 'Delayed projects (>24 hours)';
            break;
          case 'expired':
            title = 'Expired Projects';
            break;
          case 'active_translators':
            title = 'Active Translators';
            break;
          default:
            title = 'Projects';
            break;
        }

        return title;
      },
      loadLanguage(type: string) {
        if (localStorage.getItem('dashboardFilters')) {
          const dashboardFilters = JSON.parse(localStorage.getItem('dashboardFilters') || '{}');
          return dashboardFilters[type];
        }
      },
      getExpertiseById(id: any) {
        if (typeof id === 'string') {
          return id;
        }
        const currentExpertise = this.expertisesList.filter((item: any) => item.key === id);
        return currentExpertise[0].title;
      },
      getProjects() {
        const projectData: any = [];
        const offset = this.info.currentPage * this.perPage;
        const sortDirection = this.info.orderAsc === true ? 'asc' : 'desc';
        const sortParameter = !this.info.sort
          ? ''
          : `&order[0][colName]=${this.info.sort}&order[0][sortDirection]=${sortDirection}`;
        const url = `${this.getUrl()}&iDisplayStart=${offset}&iDisplayLength=${this.perPage}&project_type=${
          this.activeFilter
        }&from_project_date=${this.dateFilter.from}&until_project_date=${this.dateFilter.to}${sortParameter}`;
        const title = this.getTitle();

        this.updateInfo({
          loadData: true,
        });
        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        })
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            const dataProjects = data.results.data;

            dataProjects.forEach((value: any) => {
              projectData.push({
                id: value.id,
                date_submition: dayjs(value.created_date).format('YYYY-MM-DD'),
                source_language: value?.source_language?.toLowerCase() || '',
                target_language: value?.target_language?.toLowerCase() || '',
                exclusive: Number.parseInt(value.exclusive),
                type: value.type,
                word_count: Number.parseInt(value.count) || 0,
                expertise: this.getExpertiseById(value.expertise || 0),
                allocation: value.allocation,
              });
            });

            const totalProjectsData = data.results.iTotalDisplayRecords;

            const pageNumbers = [];
            for (let i = 1; i <= Math.ceil(parseInt(totalProjectsData) / this.perPage); i++) {
              pageNumbers.push(i);
            }

            this.updateInfo({
              title: title,
              projects: projectData,
              filteredProjects: projectData,
              loadData: false,
              totalCountData: totalProjectsData,
              pageNumbers: pageNumbers,
            });
          })
          .catch(() => {
            this.updateInfo({
              loadData: false,
              title: title,
            });
          });
      },
      changeFilterDateRange(textValue: string, from: string, to: string) {
        this.dateFilter = {
          textValue,
          from: from,
          to: to,
        };
        this.getProjects();
      },
    },
    {
      info: observable,
      activeFilter: observable,
      type: observable,
      expertisesList: observable,
      updateInfo: action.bound,
      setType: action.bound,
      setActiveFilter: action.bound,
      changeSort: action.bound,
      changePage: action.bound,
      getExpertisesList: action.bound,
      getProjects: action.bound,
      changeFilterDateRange: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';
import {StatementRowProps} from 'pages/CustomerStatement/CustomerStatementUtils';
import {SourceLanguage} from 'pages/Wizard/types';
import {DatePickerValue} from '../../components/BaseComponents/BaseDatepicker';

export type Order = 'asc' | 'desc';
export type DocumentType = 'PDF' | 'CSV';

type FilterData = {
  dateFrom: DatePickerValue;
  dateTo: DatePickerValue;
  sourceLang: string;
  targetLang: string;
  actionType: string;
  tag: string;
  page: number;
  limit: number;
  orderBy: string;
  order: Order;
};

export const defaults = {
  page: 0,
  rowsPerPage: 25,
  order: 'desc' as Order,
  orderBy: 'date',
};

export interface CustomerLanguage {
  id: number;
  name: string;
  slug: string;
  code: string;
}

export interface ActionType {
  slug: string;
  name: string;
}

export function customerStatementStore() {
  return makeObservable(
    {
      rows: [] as StatementRowProps[],
      total: 0 as number,
      email: undefined as string | undefined,
      isModalOpen: false as boolean,
      isEmailInvalid: false as boolean,
      documentType: 'CSV' as DocumentType,
      fromDateOpen: false as boolean,
      toDateOpen: false as boolean,
      languagesList: [] as CustomerLanguage[],
      actionTypesList: [] as ActionType[],
      tagsList: [] as [],
      filterData: {
        dateFrom: null,
        dateTo: null,
        sourceLang: '',
        targetLang: '',
        actionType: '',
        tag: '',
        page: defaults.page,
        limit: defaults.rowsPerPage,
        orderBy: defaults.orderBy,
        order: defaults.order,
      } as FilterData,
      isLoading: false as boolean,
      showCreditsValue: false as boolean,
      resetRows() {
        this.rows = [];
      },
      setEmail(email: string) {
        this.email = email;
      },
      setIsEmailInvalid(invalid: boolean) {
        this.isEmailInvalid = invalid;
      },
      updateFilterData(newFilterData: Partial<FilterData>) {
        this.filterData = {...this.filterData, ...newFilterData};
      },
      changeModalOpen(isOpen: boolean) {
        this.isModalOpen = isOpen;
      },
      changeFromDateOpen(isOpen: boolean) {
        this.fromDateOpen = isOpen;
      },
      changeToDateOpen(isOpen: boolean) {
        this.toDateOpen = isOpen;
      },
      changeDocumentType(type: DocumentType) {
        this.documentType = type;
      },
      loadReport() {
        if (this.filterData.dateFrom && this.filterData.dateTo) {
          this.isLoading = true;
          api.customerStatement
            .getStatementData(
              (this.filterData.dateFrom as Date)?.toISOString()?.split('T')[0],
              (this.filterData.dateTo as Date)?.toISOString()?.split('T')[0],
              this.filterData.sourceLang,
              this.filterData.targetLang,
              this.filterData.actionType,
              this.filterData.tag,
              this.filterData.page,
              this.filterData.limit,
              this.filterData.orderBy,
              this.filterData.order
            )
            .then(
              action(({data}) => {
                this.showCreditsValue = data.results?.showCreditsValue;
                if (Array.isArray(data?.results?.actions)) {
                  this.rows = data.results.actions.map((row: any) => {
                    if (row.estimatedValue) {
                      row.estimatedValue = parseFloat(row.estimatedValue.toString().replace(/,/g, ''));
                    }
                    if (row.amount) {
                      row.amount = parseFloat(row.amount.toString().replace(/,/g, ''));
                    }
                    if (row.balance) {
                      row.balance = parseFloat(row.balance.toString().replace(/,/g, ''));
                    }
                    if (row.currencyBalance) {
                      row.currencyBalance = parseFloat(row.currencyBalance.toString().replace(/,/g, ''));
                    }
                    if (row.wordcount) {
                      row.wordcount = parseInt(row.wordcount.toString(), 10);
                    } else {
                      row.wordcount = 0;
                    }
                    return row;
                  });

                  this.total = data.results.statistics?.results_actions;
                }
              })
            )
            .finally(
              action(() => {
                this.isLoading = false;
              })
            );
        }
      },
      sendReportByEmail() {
        if (this.email) {
          const fd = new FormData();
          fd.append('email', this.email);
          fd.append('format', this.documentType);
          fd.append('date_from', (this.filterData.dateFrom as Date)?.toISOString()?.split('T')[0] || '');
          fd.append('date_to', (this.filterData.dateTo as Date)?.toISOString()?.split('T')[0] || '');
          fd.append('tag', this.filterData.tag);
          fd.append('source_lang', this.filterData.sourceLang);
          fd.append('target_lang', this.filterData.targetLang);
          fd.append('action_type', this.filterData.actionType);

          this.isLoading = true;
          api.customerStatement
            .sendStatementReport({fd})
            .then()
            .finally(
              action(() => {
                this.isLoading = false;
                this.isModalOpen = false;
              })
            );
        }
      },
      getLanguagesList() {
        api.wizard.getLanguages().then(
          action(({data}) => {
            const available = Object.keys(data.results?.Language?.grouped?.languages).map(
              (key) => data.results?.Language?.grouped?.languages[key].slug
            );
            this.languagesList = data.results?.Language?.list.filter(
              (value: SourceLanguage) => available.indexOf(value.slug) >= 0
            );
          })
        );
      },
      getActionTypesList() {
        api.customerStatement.getActionTypes().then(
          action(({data}) => {
            if (Array.isArray(data.results)) {
              this.actionTypesList = data.results;
            }
          })
        );
      },
      getTagsList() {
        api.customerStatement.getTags().then(
          action(({data}) => {
            if (Array.isArray(data.results)) {
              this.tagsList = data.results;
            }
          })
        );
      },
    },
    {
      rows: observable,
      total: observable,
      email: observable,
      languagesList: observable,
      actionTypesList: observable,
      tagsList: observable,
      isModalOpen: observable,
      isEmailInvalid: observable,
      documentType: observable,
      filterData: observable.deep,
      fromDateOpen: observable,
      toDateOpen: observable,
      isLoading: observable,
      showCreditsValue: observable,
      resetRows: action.bound,
      updateFilterData: action.bound,
      setEmail: action.bound,
      setIsEmailInvalid: action.bound,
      sendReportByEmail: action.bound,
      changeModalOpen: action.bound,
      changeFromDateOpen: action.bound,
      changeToDateOpen: action.bound,
      changeDocumentType: action.bound,
      loadReport: action.bound,
      getLanguagesList: action.bound,
      getActionTypesList: action.bound,
      getTagsList: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function languageSkillsStore() {
  return makeObservable(
    {
      nativeLanguage: '0' as string,
      secondNativeLanguage: '0' as string,
      translationSourceLanguage: '' as string,
      translationTargetLanguage: '' as string,
      transcriptionLanguage: '' as string,
      transcriptionSkills: [] as [],
      languagesList: [] as [],
      userSkills: [] as [],
      validationError: false as boolean,
      isLoading: false as boolean,
      setNativeLanguage(language: string) {
        this.nativeLanguage = language;
        this.validationError = false;
      },
      setSecondNativeLanguage(language: string) {
        this.secondNativeLanguage = language;
      },
      setTranslationSourceLanguage(language: string) {
        this.translationSourceLanguage = language;
      },
      setTranslationTargetLanguage(language: string) {
        this.translationTargetLanguage = language;
      },
      setTranscriptionLanguage(language: string) {
        this.transcriptionLanguage = language;
      },
      setTranscriptionSkills(skills: []) {
        this.transcriptionSkills = skills;
      },
      setLanguagesList(list: []) {
        this.languagesList = list;
      },
      setUserSkills(skills: []) {
        this.userSkills = skills;
      },
      addLanguageSkill(userId: string) {
        const requestParams = {
          uid: userId,
          source_id: this.translationSourceLanguage,
          target_id: this.translationTargetLanguage,
          native_id: this.nativeLanguage + ',' + this.secondNativeLanguage,
        };
        this.isLoading = true;
        api.profile
          .addLanguagePairToUserSkills(requestParams)
          .then(() => {
            this.translationSourceLanguage = '';
            this.translationTargetLanguage = '';
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      removeLanguageSkill(userId: string, source: string, target: string) {
        const requestParams = {
          uid: userId,
          source_id: source,
          target_id: target,
        };
        this.isLoading = true;
        api.profile
          .removeLanguagePairFromUserSkills(requestParams)
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
      addTranscriptionSkill(userId: string) {
        if (this.transcriptionLanguage !== '') {
          this.isLoading = true;
          api.profile
            .setTranscriptionSkill(userId, this.transcriptionLanguage)
            .then(() => {
              this.transcriptionLanguage = '';
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      removeTranscriptionSkill(userId: string, language: string) {
        this.isLoading = true;
        api.profile
          .removeTranscriptionSkill(userId, language)
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
      updateLanguageInfo() {
        if (this.nativeLanguage !== '0') {
          this.isLoading = true;
          api.profile
            .postLanguageInfo(this.nativeLanguage, this.secondNativeLanguage)
            .then()
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.validationError = true;
        }
      },
    },
    {
      nativeLanguage: observable,
      secondNativeLanguage: observable,
      translationSourceLanguage: observable,
      translationTargetLanguage: observable,
      transcriptionLanguage: observable,
      transcriptionSkills: observable,
      languagesList: observable,
      userSkills: observable,
      validationError: observable,
      isLoading: observable,
      setNativeLanguage: action.bound,
      setSecondNativeLanguage: action.bound,
      setTranslationSourceLanguage: action.bound,
      setTranslationTargetLanguage: action.bound,
      setTranscriptionLanguage: action.bound,
      setTranscriptionSkills: action.bound,
      setLanguagesList: action.bound,
      setUserSkills: action.bound,
      addLanguageSkill: action.bound,
      removeLanguageSkill: action.bound,
      addTranscriptionSkill: action.bound,
      removeTranscriptionSkill: action.bound,
      updateLanguageInfo: action.bound,
    }
  );
}

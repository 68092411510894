import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function privacyPolicyStore() {
  return makeObservable(
    {
      reasonsList: {} as any,
      confirmOpen: false as boolean,
      reasonOpen: false as boolean,
      passwordOpen: false as boolean,
      reason: '' as string,
      password: '' as string,
      otherReason: '' as string,
      error: undefined as string | undefined,
      success: false as boolean,
      isLoading: false as boolean,
      setConfirmOpen(isOpen: boolean) {
        this.confirmOpen = isOpen;
      },
      setReasonOpen(isOpen: boolean) {
        this.reasonOpen = isOpen;
      },
      setPasswordOpen(isOpen: boolean) {
        this.passwordOpen = isOpen;
      },
      setReason(reason: string) {
        this.reason = reason;
        if (reason !== '' && reason !== 'other') {
          this.reasonOpen = false;
          this.passwordOpen = true;
        }
      },
      setPassword(password: string) {
        this.password = password;
      },
      setOtherReason(otherReason: string) {
        this.otherReason = otherReason;
      },
      getDeleteReasons() {
        api.profile.getDeleteReasons().then((response) => {
          this.reasonsList = response;
          this.confirmOpen = false;
          this.reasonOpen = true;
        });
      },
      submitDelete(email: string) {
        this.error = undefined;
        this.isLoading = true;

        api.profile
          .checkCredentials(email, this.password)
          .then((response) => {
            if (!response?.data?.results?.id) {
              this.isLoading = false;
              this.error = 'The password you entered is incorrect.';
            } else {
              api.profile
                .deleteAccount(this.reason !== '' ? this.reason : this.otherReason)
                .then(() => {
                  this.passwordOpen = false;
                  this.success = true;
                })
                .finally(() => {
                  this.isLoading = false;
                });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      reasonsList: observable,
      confirmOpen: observable,
      reasonOpen: observable,
      passwordOpen: observable,
      reason: observable,
      password: observable,
      otherReason: observable,
      error: observable,
      success: observable,
      isLoading: observable,
      setConfirmOpen: action.bound,
      setReasonOpen: action.bound,
      setPasswordOpen: action.bound,
      setReason: action.bound,
      setPassword: action.bound,
      setOtherReason: action.bound,
      getDeleteReasons: action.bound,
      submitDelete: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';
export interface ProfileExpertise {
  expertOnly: boolean;
  userExpertiseList: [];
  expertiseList: [];
}

export type ProfileData = {
  sections: string[];
  isOnVacation: boolean;
  userRole: string;
  account?: any;
  languages?: any;
  expertise?: ProfileExpertise;
  billing?: any;
  uploads?: any;
  about?: any;
  time?: any;
  references?: any;
  verification?: any;
  software_services?: any;
  networks?: any;
  mailing?: any;
  apikeys?: any;
  preftrans?: any;
  translator_guidelines?: any;
  tmxFiles?: any;
  terminology?: any;
  customer_thanks?: any;
  translator_thanks?: any;
  password?: any;
  telegram?: any;
  beneficiary_account?: any;
  custom_notes?: any;
  glossary_files?: any;
  tfa_enable?: any;
  tfa_disable?: any;
  privacy_policy?: any;
};

export function profileStore() {
  return makeObservable(
    {
      currentHash: '#account' as string,
      flow: null as string | null,
      profileData: {} as ProfileData,
      setCurrentHash(hash: string) {
        this.currentHash = hash;
      },
      setFlow(param: string | null) {
        this.flow = param;
      },
      getProfileData() {
        api.profile.getProfileInfo(this.flow).then(
          action(({data}) => {
            this.profileData = data?.results;
          })
        );
      },
    },
    {
      currentHash: observable,
      flow: observable,
      profileData: observable.deep,
      setCurrentHash: action.bound,
      setFlow: action.bound,
      getProfileData: action.bound,
    }
  );
}

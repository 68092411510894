import {action, makeObservable, observable} from 'mobx';
import api from 'api';

export function registrationStore() {
  return makeObservable(
    {
      type: '' as 'customer' | 'translator' | '',
      countriesList: [] as [],
      industriesList: [] as [],
      formData: {
        username: '' as string,
        email: '' as string,
        password: '' as string,
        repassword: '' as string,
        userCountry: 'US' as string,
        userIndustry: '' as string,
        companyName: '' as string,
        phone: '' as string,
        'g-recaptcha-response': '' as string,
        recaptchaValue: undefined as string | null | undefined,
        agreeTerms: true as boolean,
      },
      setType(type: 'customer' | 'translator' | '') {
        this.type = type;
      },
      getCountriesList() {
        api.wizard.getCountriesInfo().then(
          action(({data}) => {
            if (Array.isArray(data.list)) {
              this.countriesList = data.list;
              this.formData.userCountry =
                data.list.filter(({Code}: {Code: string}) => Code === data.countryByIp).length > 0
                  ? data.countryByIp
                  : 'US';
            }
          })
        );
      },
      getIndustriesList() {
        api.profile.getIndustry().then(
          action(({data}) => {
            this.industriesList = data || [];
          })
        );
      },
      updateFormData(newFormData: any) {
        this.formData = {...this.formData, ...newFormData};
      },
    },
    {
      type: observable,
      countriesList: observable,
      industriesList: observable,
      formData: observable,
      setType: action.bound,
      getCountriesList: action.bound,
      getIndustriesList: action.bound,
      updateFormData: action.bound,
    }
  );
}

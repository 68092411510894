import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function translatorGuidelinesStore() {
  return makeObservable(
    {
      agree: false as boolean,
      isLoading: false as boolean,
      setAgree(isAgree: boolean) {
        this.agree = isAgree;
      },
      postTranslatorGuidelineAgreement(CSRFToken: string) {
        this.isLoading = true;
        api.profile
          .setTranslatorGuidelineAgreement(this.agree, CSRFToken)
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      agree: observable,
      isLoading: observable,
      setAgree: action.bound,
      postTranslatorGuidelineAgreement: action.bound,
    }
  );
}

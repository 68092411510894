import breakpoints from './breakpoints';
import colors from './colors';

type ColorThemeKey =
  | 'grey100'
  | 'grey090'
  | 'grey085'
  | 'grey080'
  | 'grey070'
  | 'grey065'
  | 'grey060'
  | 'grey050'
  | 'grey030'
  | 'grey020'
  | 'grey015'
  | 'grey010'
  | 'grey005'
  | 'grey000'
  | 'green100'
  | 'green090'
  | 'green085'
  | 'green084'
  | 'green080'
  | 'green075'
  | 'green070'
  | 'green060'
  | 'green055'
  | 'green054'
  | 'green050'
  | 'green040'
  | 'green020'
  | 'green015'
  | 'green010'
  | 'celery100'
  | 'celery050'
  | 'cyan110'
  | 'cyan100'
  | 'cyan050'
  | 'cyan020'
  | 'pink100'
  | 'pink050'
  | 'blue100'
  | 'blue095'
  | 'blue094'
  | 'blue093'
  | 'blue090'
  | 'blue080'
  | 'blue050'
  | 'blue020'
  | 'yellow100'
  | 'yellow090'
  | 'yellow080'
  | 'yellow050'
  | 'yellow020'
  | 'red100'
  | 'red050'
  | 'red020'
  | 'orange100'
  | 'orange090';

export type ThemeColors = {
  [key in ColorThemeKey]: string;
};

type BreakpointThemeKey =
  | 'maxXs'
  | 'maxSm'
  | 'maxMd'
  | 'maxLt'
  | 'maxLg'
  | 'maxXl'
  | 'minXs'
  | 'minSm'
  | 'minMd'
  | 'minLt'
  | 'minLg'
  | 'minXl';

export type ThemeBreakpoints = {
  [key in BreakpointThemeKey]: string;
};

export default {
  breakpoints,
  colors,
};

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {getEstimation} from './modelParser';
import {PROJECT_TYPES, DATE_FORMAT, GLOBAL_CONSTS} from './const';

const {MM_DD_YYYY} = DATE_FORMAT;
const {SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE} = GLOBAL_CONSTS;

dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const {
  SeoMaster,
  translation,
  urgentTranslation,
  proofreading,
  combo_translation_proofreading,
  TranscriptionProject,
  WeST2TranslationProject,
  ExpertWeST2TranslationProject,
  EnterpriseQuality,
  LQAProject,
  Hybrid,
  MtpeLight,
  MachineTranslation,
  ExcelTranslation,
  ExcelCombo,
  TranslationEvaluation,
  DTP,
  FbStatus,
  FbPostEdit,
  FbRating,
  FbRatingComparison,
  DynamicTranslation,
  RomanizationTranslation,
  FbPostEditTranslation,
} = PROJECT_TYPES;

interface IncludeDaysType {
  from: string | number;
  to: string | number;
}

export const getTimeEstimation = (time: number) => {
  const {months, days, hours} = getEstimation(time);

  const timeArr = [
    {value: months, flag: 'month'},
    {value: days, flag: 'day'},
    {value: hours, flag: 'hour'},
  ];

  const getActualValue = (value: number, flag: string) => {
    switch (value) {
      case 0:
        return '';
      case 1:
        return `${value} ${flag} `;
      default:
        return `${value} ${flag}s `;
    }
  };

  return timeArr.map(({value, flag}) => getActualValue(value, flag));
};

export const getShortEstimation = (time: number) => {
  const {months, days, hours} = getEstimation(time);

  const timeArr = [
    {value: months, flag: 'M'},
    {value: days, flag: 'D'},
    {value: hours, flag: 'H'},
  ];

  const getActualValue = (value: number, flag: string) => (value ? `${value}${flag} ` : '');

  return timeArr.map(({value, flag}) => getActualValue(value, flag));
};

export const getTextsByProductType = (type: string, needExpedite?: boolean) => {
  const target: {[key: string]: string} = {
    SeoMaster,
    translation: needExpedite ? urgentTranslation : translation,
    proofreading,
    combo_translation_proofreading,
    TranscriptionProject,
    WeST2TranslationProject,
    ExpertWeST2TranslationProject: ExpertWeST2TranslationProject,
    EnterpriseQuality,
    LQAProject,
    Hybrid,
    MachineTranslation,
    'Translation Project': translation,
    'f240a484-a7d2-4c4d-b677-9a3f1cb5ab28': translation,
    'c7f7857e-d482-428f-9615-ba223742e366': translation,
    'fff02996-3de0-4e82-b8bb-3a44457d10a0': MtpeLight,
    'd557d47a-a099-469a-99ed-b93956f667b7': combo_translation_proofreading,
    '07f8cb6e-5a03-4bc1-ad5d-e96d387deace': combo_translation_proofreading,
    ExcelTranslation,
    ExcelCombo,
    'fb-translation-comparison': TranslationEvaluation,
    'Certificate Translation': 'Certificate Translation',
    DTP,
    'fb-status': FbStatus,
    'fb-post-edit': FbPostEdit,
    'fb-translation-rating': FbRating,
    'fb-three-way-comparison': FbRatingComparison,
    'dynamic-translation': DynamicTranslation,
    'romanization-translation': RomanizationTranslation,
    'fb-post-edit-translation': FbPostEditTranslation,
  };

  return type in target ? target[type] : type;
};

interface IncludeDaysType {
  from: string | number;
  to: string | number;
}

export const isIncludeDays = ({from, to}: IncludeDaysType) => {
  const today = dayjs(new Date()).format(MM_DD_YYYY);
  const formattedFrom = typeof from === 'number' ? dayjs.unix(from) : dayjs(from);
  const formattedTo = typeof to === 'number' ? dayjs.unix(to) : dayjs(to);
  const fromDate = formattedFrom.format(MM_DD_YYYY);
  const toDate = formattedTo.format(MM_DD_YYYY);

  return dayjs(today).isBetween(fromDate, toDate) || [fromDate, toDate].includes(today);
};

export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const isValidPassword = (value: string) => {
  const upperCasePattern = /^(?=.*[A-Z]).+$/; // At least one UPPERCASE character:
  const lowerCasePattern = /^(?=.*[a-z]).+$/; // At least one LOWERCASE character:
  const numberPattern = /^(?=.*\d).+$/; // At least one NUMBER:
  const characterCountPattern = /^.{6,}/; // At least 6 characters in the screen:
  const specialCharacterPatter = /([-+=_!@#$%^&*.,;:'"<>/?`~[\](){}\\|\s])/; // At least one SPECIAL character:
  const spacePattern = /^(?=.*[ ]).+$/; // Check for Space:

  return (
    upperCasePattern.test(value) &&
    lowerCasePattern.test(value) &&
    numberPattern.test(value) &&
    characterCountPattern.test(value) &&
    specialCharacterPatter.test(value) &&
    !spacePattern.test(value)
  );
};

export const pluralCheck = (value: string | number) => {
  let condition = false;

  if ((typeof value === 'string' && value.length === 1) || (typeof value === 'number' && value === 1)) {
    condition = true;
  }

  return condition ? '' : 's';
};

export const convertSecondsToTime = (seconds: number) => {
  let days = 0;
  const showDays = seconds >= SECONDS_IN_DAY;

  const makeTwoDigits = (value: number | string) => ('0' + value).slice(-2);

  if (showDays) {
    days = Math.floor(seconds / SECONDS_IN_DAY);
    seconds -= days * SECONDS_IN_DAY;
  }

  const hours = Math.floor(seconds / SECONDS_IN_HOUR);
  seconds -= hours * SECONDS_IN_HOUR;

  const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  seconds -= minutes * SECONDS_IN_MINUTE;

  return (
    (showDays ? days + ' ' : '') + makeTwoDigits(hours) + ':' + makeTwoDigits(minutes) + ':' + makeTwoDigits(seconds)
  );
};

export const snakeCase = (value: string) => value.replace(/\s+/g, '_').toLowerCase();

export const isEqual = (value: any, other: any): boolean => {
  const type: string = Object.prototype.toString.call(value);
  const otherType: string = Object.prototype.toString.call(other);

  if (type !== otherType) return false;

  if (type === '[object Object]') {
    const keys: string[] = Object.keys(value);
    const otherKeys: string[] = Object.keys(other);

    if (keys.length !== otherKeys.length) return false;

    for (const key of keys) {
      if (!isEqual(value[key], other[key])) return false;
    }

    return true;
  }
  return false;
};

export const times = <T>(n: number, iteratee: (index: number) => T): T[] => {
  const result: T[] = [];
  for (let i = 0; i < n; i++) {
    result.push(iteratee(i));
  }
  return result;
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  options?: {leading?: boolean; trailing?: boolean}
): T => {
  let timeoutId: number | null | NodeJS.Timeout;

  return function debounced(val: any, ...args: T[]) {
    const context = val;

    const later = () => {
      timeoutId = null;
      if (!options || (options.trailing !== false && options.leading !== false)) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = !timeoutId && options && options.leading !== false;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(later, wait);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  } as T;
};

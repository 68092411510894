import {makeObservable, observable, action} from 'mobx';
import {deletePurchaseOrder, getAllCount, getAllPurchaseOrders} from 'pages/ShoppingCart/shoppingCartApi';

export function shoppingCartStore() {
  return makeObservable(
    {
      shoppingCartDataIsLoaded: false as boolean,
      shoppingCartCountIsLoaded: false as boolean,
      shoppingCartData: [] as [],
      shoppingCartCount: 0 as number,
      getShoppingCartCount() {
        getAllCount().then(
          action(({results}) => {
            this.shoppingCartCount = results?.count || 0;
            this.shoppingCartCountIsLoaded = true;
          })
        );
      },
      getShoppingCartData() {
        getAllPurchaseOrders().then(
          action(({results}) => {
            this.shoppingCartData = results.filter(
              (item: {purchaseOrderId: string}) => typeof item.purchaseOrderId === 'string'
            );
            this.shoppingCartDataIsLoaded = true;
          })
        );
      },
      deleteOrder(poid: string, CSRFToken: string) {
        this.shoppingCartDataIsLoaded = false;
        this.shoppingCartCountIsLoaded = false;
        deletePurchaseOrder(poid, CSRFToken).then(() => {
          this.getShoppingCartData();
          this.getShoppingCartCount();
        });
      },
    },
    {
      shoppingCartDataIsLoaded: observable,
      shoppingCartCountIsLoaded: observable,
      shoppingCartData: observable,
      shoppingCartCount: observable,
      getShoppingCartCount: action.bound,
      getShoppingCartData: action.bound,
      deleteOrder: action.bound,
    }
  );
}

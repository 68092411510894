import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function translationMemoryStore() {
  return makeObservable(
    {
      sourceLanguage: '' as string,
      targetLanguage: '' as string,
      TMXFilesList: {} as any,
      XTMLanguagesList: [] as [],
      error: '' as string,
      isLoading: false as boolean,
      setSourceLanguage(language: string) {
        this.sourceLanguage = language;
      },
      setTargetLanguage(language: string) {
        this.targetLanguage = language;
      },
      getTMXFiles() {
        api.profile.getTMXFiles().then((response) => {
          this.TMXFilesList = response?.results;
        });
      },
      getXTMLanguages() {
        api.profile.getXTMLanguages().then((response) => {
          this.XTMLanguagesList = response?.results;
        });
      },
      uploadTMXFiles(file: any) {
        const data = new FormData();
        data.append('file', file);
        data.append('srcLang', this.sourceLanguage);
        data.append('tarLang', this.targetLanguage);
        this.isLoading = true;
        this.error = '';
        api.profile
          .uploadTMXFiles({fd: data})
          .then((response) => {
            if (response?.results?.status === 'ok') {
              this.getTMXFiles();
              this.sourceLanguage = '';
              this.targetLanguage = '';
            } else {
              this.error = response?.results?.msg || '';
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      sourceLanguage: observable,
      targetLanguage: observable,
      TMXFilesList: observable,
      XTMLanguagesList: observable,
      error: observable,
      isLoading: observable,
      setSourceLanguage: action.bound,
      setTargetLanguage: action.bound,
      getTMXFiles: action.bound,
      getXTMLanguages: action.bound,
      uploadTMXFiles: action.bound,
    }
  );
}

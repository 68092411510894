import {makeObservable, observable, action} from 'mobx';
import {profileStore} from 'store/pages/profileStore';
import api from 'api';

const profile = profileStore();

type AccountInformation = {
  fullName: string;
  email: string;
  address?: string | null | undefined;
  userCountry?: string | null | undefined;
  userIndustry?: string | null | undefined;
  city?: string | null | undefined;
  phone?: string | null | undefined;
  company?: string | null | undefined;
  phone_verify_code?: string | null | undefined;
  showNBMessage?: string | null | undefined;
  is_phone_verified?: number;
  listIndustry?: Array<any>;
  listCountry?: Array<any>;
};

export function accountInformationStore() {
  return makeObservable(
    {
      accountInformation: {
        fullName: '' as string,
        email: '' as string,
        address: '' as string,
        userCountry: '' as string,
        userIndustry: '' as string,
        city: '' as string,
        phone: '' as string,
        company: '' as string,
        phone_verify_code: '' as string,
      } as Partial<AccountInformation>,
      verifyCodeSent: false as boolean,
      errorMessages: {} as any,
      isLoading: false as boolean,
      bio: '' as string,
      avatar: '' as string,
      uploadResult: {} as any,
      updateAccountInformation(newAccountInformation: Partial<AccountInformation>) {
        this.accountInformation = {...this.accountInformation, ...newAccountInformation};
      },
      postAccountInformation(CSRFToken: string) {
        const params = {
          full_name: this.accountInformation.fullName,
          user_email: this.accountInformation.email,
          user_industry: this.accountInformation.userIndustry,
          user_phone: this.accountInformation.phone,
          user_country: this.accountInformation.userCountry,
          user_address: this.accountInformation.address,
          user_city: this.accountInformation.city,
          company_name: this.accountInformation.company,
          CSRFToken: CSRFToken,
        };
        this.isLoading = true;
        this.verifyCodeSent = false;
        api.profile
          .postUpdateUser(params)
          .then((responseData: any) => {
            if (responseData.status !== 200) {
              return;
            }
            if (responseData?.data?.user_id) {
              this.setErrorMessages({});
              profile.getProfileData();
            } else {
              this.setErrorMessages(responseData?.data);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      startVerification() {
        this.isLoading = true;
        api.profile
          .postStartVerify(this.accountInformation.phone || '')
          .then((responseData: any) => {
            if (responseData.status !== 200) {
              return;
            }
            if (responseData?.data?.status?.code === 0) {
              this.verifyCodeSent = true;
            } else if (responseData?.data?.status?.msg) {
              this.setErrorMessages({
                phone_verify_check: responseData?.data?.status?.msg,
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      tryToVerify() {
        this.isLoading = true;
        api.profile
          .postTryVerify(this.accountInformation.phone || '', this.accountInformation.phone_verify_code || '')
          .then((responseData: any) => {
            if (responseData.status !== 200) {
              return;
            }
            if (responseData?.data?.status?.code === 0 && responseData?.data?.results?.status === true) {
              profile.getProfileData();
              this.verifyCodeSent = false;
              this.setErrorMessages({});
            } else if (responseData?.data?.status?.msg) {
              this.setErrorMessages({
                phone_verify_check: responseData?.data?.status?.msg,
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      setErrorMessages(messages: any) {
        this.errorMessages = {...messages};
      },
      setBio(text: string) {
        this.bio = text;
      },
      setAvatar(link: string) {
        this.avatar = link;
        console.warn(this.avatar);
      },
      resetUploadResult() {
        this.uploadResult = {};
      },
      uploadAvatar(userId: string, file: any) {
        const data = new FormData();
        data.append('image_translator', file);
        this.isLoading = true;
        api.profile
          .uploadAvatar({fd: data})
          .then(
            action((response) => {
              if (response.data.error) {
                this.uploadResult = {...this.uploadResult, error: response.data.error};
              } else {
                if (response.key !== '') {
                  this.setAvatar(`/download/?fi=${response.data.key}&userId=${userId}&size=medium`);
                  this.uploadResult = {success: true};
                }
              }
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      postAboutMe() {
        api.profile
          .postAboutMeInfo(this.bio)
          .then(
            action((response) => {
              if (response?.data?.about_status === 'error') {
                this.errorMessages = {
                  about_status: '*Your bio should be at least 5 words long.',
                };
              } else {
                this.isLoading = true;
                this.errorMessages = {};
                this.isLoading = false;
              }
            })
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      accountInformation: observable,
      verifyCodeSent: observable,
      errorMessages: observable,
      isLoading: observable,
      bio: observable,
      avatar: observable,
      uploadResult: observable.deep,
      updateAccountInformation: action.bound,
      postAccountInformation: action.bound,
      startVerification: action.bound,
      tryToVerify: action.bound,
      setErrorMessages: action.bound,
      setBio: action.bound,
      setAvatar: action.bound,
      resetUploadResult: action.bound,
      uploadAvatar: action.bound,
      postAboutMe: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';
import {showNotification} from 'components/BaseComponents/BaseNotification';
import Consts from 'pages/Profile/ProfileConsts';

const {ALLOCATE_SUCCESSFUL, UNABLE_TRANSFER} = Consts;

export function beneficiaryAccountsStore() {
  return makeObservable(
    {
      beneficiaryInfo: {} as any,
      emailInput: '' as string,
      transferInputs: {} as any,
      confirmDeleteOpen: false as boolean,
      confirmDelete: false as boolean,
      accountToDelete: undefined as string | undefined,
      errorMessages: {} as any,
      isLoading: false as boolean,
      setEmailInput(email: string) {
        this.emailInput = email;
      },
      setTransferInputs(newTransferInputs: any) {
        this.transferInputs = {...this.transferInputs, ...newTransferInputs};
      },
      setConfirmDeleteOpen(isDeleteOpen: boolean) {
        this.confirmDeleteOpen = isDeleteOpen;
      },
      setConfirmDelete(isDelete: boolean, CSRFToken?: string) {
        this.confirmDelete = isDelete;
        if (isDelete) {
          this.confirmDeleteOpen = false;
          if (this.accountToDelete && CSRFToken) {
            this.isLoading = true;
            api.profile
              .deleteBeneficiaryAccount(CSRFToken, this.accountToDelete)
              .then(() => {
                this.confirmDelete = false;
                this.accountToDelete = undefined;
                this.getBeneficiaryInfo(CSRFToken);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        }
      },
      setAccountToDelete(uuid: string) {
        this.accountToDelete = uuid;
        if (uuid) {
          this.confirmDeleteOpen = true;
        }
      },
      getBeneficiaryInfo(CSRFToken: string) {
        api.profile.getBeneficiaryAccounts(CSRFToken).then(
          action(({results}) => {
            this.beneficiaryInfo = results || {};
          })
        );
      },
      addBeneficiaryAccount(CSRFToken: string) {
        if (this.emailInput !== '') {
          this.isLoading = true;
          api.profile
            .addBeneficiaryAccount(CSRFToken, this.emailInput)
            .then(() => {
              this.getBeneficiaryInfo(CSRFToken);
              this.emailInput = '';
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      saveAutomaticFunds(CSRFToken: string, uuid: string, automatic: boolean) {
        this.isLoading = true;
        api.profile
          .saveAutomaticFunds(CSRFToken, uuid, automatic)
          .then(() => {
            this.getBeneficiaryInfo(CSRFToken);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      postTransfer(CSRFToken: string, uuid: string) {
        this.errorMessages = {};
        this.isLoading = true;
        api.profile
          .allocateCredits(CSRFToken, uuid, this.transferInputs[uuid] || '')
          .then(
            ({
              status,
              data: {
                results,
                errors,
                status: {code},
              },
            }) => {
              errors.length && showNotification(errors[0], {type: 'error'});
              if (status !== 200) {
                return;
              }
              if (code === 200) {
                this.errorMessages = {allocate_credits: UNABLE_TRANSFER};
              }
              if (results?.transferred_credits || results?.length) {
                showNotification(ALLOCATE_SUCCESSFUL, {type: 'success'});
                this.getBeneficiaryInfo(CSRFToken);
              }
            }
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      beneficiaryInfo: observable,
      emailInput: observable,
      transferInputs: observable,
      confirmDeleteOpen: observable,
      confirmDelete: observable,
      accountToDelete: observable,
      errorMessages: observable,
      isLoading: observable,
      setEmailInput: action.bound,
      setTransferInputs: action.bound,
      setConfirmDeleteOpen: action.bound,
      setConfirmDelete: action.bound,
      setAccountToDelete: action.bound,
      getBeneficiaryInfo: action.bound,
      addBeneficiaryAccount: action.bound,
      saveAutomaticFunds: action.bound,
      postTransfer: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import {
  getDashboardStatistics,
  getDashboardAdditional,
  getDashboardBalance,
  updateConvertedRequest,
  postDashboardTransActive,
  getDashboardStatement,
  getDashboardExportStatement,
  getProjectsAffected,
  getIsRecruitment,
  postRejectedProject,
} from 'api/dashboard';

export interface GeneralData {
  key: string;
  unit: string;
  value: string;
  button?: boolean;
  subValue?: number;
}

export interface MedalsData {
  css: string;
  flag: string;
  title: string;
  value: string;
}

export interface TranslatorData {
  inactive: boolean;
  member_since: string;
  last_updated: string;
  rating: number;
  status: string;
  medals: MedalsData[];
  isRecruitment: boolean;
}

export interface PendingData {
  credits: number;
  date: number;
  projectId: number;
}

export interface СonvertedData {
  credits: number;
  date: number;
  currency: {
    count: number;
    unit: string;
  };
  id: string;
  destination: {
    name: string;
    type: string;
  };
  status: string;
}

export type GeneralType = GeneralData;
export type QualityType = GeneralData;
export type ReviewerType = GeneralData;
export type PendingType = PendingData;
export type СonvertedType = СonvertedData;

export interface LevelConditions {
  QualityFrom: number;
  ResponseRate: number;
  Conduct: number;
  Wc: number;
  ProjectsDelivered: number;
  DeliveryTime: number;
}

interface CurrentLevelConditions {
  Id: number;
  Name: string;
  Value: number;
  Wc: number;
  ProjectsDelivered: number;
  QualityFrom: number;
  QualityTo: number;
  Conduct: number;
  WarningCount: number;
  DeliveryTime: number;
  ResponseRate: number;
  PriorityPoints: number;
  MyTeam: boolean;
  ProjectsVolume: number;
  PayRate: number;
  PpAmount: number;
  OperatorId: number;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface Leveling {
  current_level?: number | null;
  current_level_conditions: CurrentLevelConditions;
  next_level_conditions: LevelConditions;
  number_of_days: number;
  potential_level: number;
  show_my_team: boolean;
  trans_leveling_admin_score: number;
  trans_leveling_completed_word_count: number;
  trans_leveling_my_team_commitment: number;
  trans_leveling_on_time_delivery: number;
  trans_leveling_projects_delivered: number;
  trans_leveling_quality_average: number;
  trans_leveling_response_rate: number;
  trans_leveling_warning_count: number;
  trans_leveling_quality_locked: boolean;
}

export interface StatementItem {
  act_id: number;
  action: string;
  amount: string;
  balance: string;
  projectId: string;
  wordcount: number;
  expertise: number;
  source: number;
  target: number;
  date: number;
}

export interface Statement {
  count: number;
  history: StatementItem[];
  statistics: [];
}

export interface StatementProps {
  limit?: number;
  page?: number;
  action_type?: string;
  action_date_from?: number;
  action_date_to?: number;
}

export function dashboardStore() {
  return makeObservable(
    {
      isLoading: true as boolean,
      general: [] as GeneralType[],
      leveling: {} as Leveling,
      quality: [] as QualityType[],
      reviewer: [] as ReviewerType[],
      removedProjects: [] as string[],
      translator_id: 0 as number,
      translator_status: '' as string,
      translator_info: {
        isRecruitment: true,
      } as TranslatorData,
      balance: [] as GeneralType[],
      pending: [] as PendingType[],
      pendingTotal: 0 as number,
      pendingPages: 0 as number,
      converted: [] as СonvertedType[],
      parkedCredits: {} as GeneralData,
      statement: {} as Statement,
      statementSent: 0 as number,
      affectedType: '' as string,
      projectsRequired: [],
      transProjectsRequired: [],
      myProjects: [],
      vacation: false as boolean,
      vacationDateFrom: null as null | string,
      vacationDateTo: null as null | string,
      getDashboardStatisticsData() {
        getDashboardStatistics().then(
          action(({general, leveling, quality, reviewer, translator_id, translator_status}) => {
            this.general = general;
            this.leveling = leveling;
            this.quality = quality;
            this.reviewer = reviewer;
            this.translator_id = translator_id;
            this.translator_status = translator_status;
          })
        );
      },
      getDashboardStatementData({limit, page, action_type, action_date_from, action_date_to}: StatementProps) {
        getDashboardStatement(limit || 25, page || 1, action_type, action_date_from, action_date_to).then(
          action((data) => {
            this.statement = data;
          })
        );
      },
      getDashboardExportStatementData(type: string, email: string) {
        getDashboardExportStatement(type, email).then(
          action(({msg}) => {
            msg === 'ok' && this.statementSent++;
          })
        );
      },
      getDashboardAdditionalData() {
        getDashboardAdditional().then(
          action((data) => {
            this.translator_info.inactive = data.inactive;
            this.translator_info.member_since = data.member_since;
            this.translator_info.last_updated = data.last_updated;
            this.translator_info.medals = data.medals;
            this.translator_info.rating = data.rating;
            this.translator_info.status = data.status;
            this.myProjects = data.my_projects;
            this.vacation = data.vacation;
            this.vacationDateFrom = data?.vacation_date_from || null;
            this.vacationDateTo = data?.vacation_date_to || null;
            this.isLoading = false;
          })
        );
      },
      getProjectsAffectedData(uuid: string, type: string) {
        this.affectedType = type;
        this.transProjectsRequired = [];
        this.projectsRequired = [];
        getProjectsAffected(uuid, type).then(
          action((data) => {
            if (type === 'response_required') {
              this.transProjectsRequired = data;
            } else {
              this.projectsRequired = data;
            }
            this.isLoading = false;
          })
        );
      },
      postDashboardTransActiveData(value: string, CSRFToken: string) {
        postDashboardTransActive(value, CSRFToken).then(
          action((data) => {
            this.translator_info.inactive = data !== '0';
          })
        );
      },
      postRejectedProjectData(project_id: number, entity_id: number, CSRFToken: string) {
        postRejectedProject(project_id, entity_id, this.affectedType, CSRFToken).then(
          action(() => {
            this.removedProjects.push(`${project_id}_${entity_id}`);
          })
        );
      },
      getDashboardBalanceData(limit: number, page: number) {
        getDashboardBalance(limit, page).then(
          action(({balance, pending, pendingTotal, pendingPages, converted, parkedCredits}) => {
            this.balance = balance;
            this.pending = pending;
            this.pendingTotal = pendingTotal;
            this.pendingPages = pendingPages;
            this.converted = converted;
            this.parkedCredits = parkedCredits;
          })
        );
      },
      checkRecruitment() {
        getIsRecruitment().then(
          action((status) => {
            this.translator_info.isRecruitment = status;
          })
        );
      },
      postConvertedRequest(id: string, uuid: string, csrftoken: string) {
        updateConvertedRequest(id, uuid, csrftoken).then(
          action((data) => {
            this.converted = this.converted.filter(({id: itemId}) => itemId != data?.id);
          })
        );
      },
    },
    {
      isLoading: observable,
      affectedType: observable,
      general: observable.deep,
      leveling: observable.deep,
      quality: observable.deep,
      reviewer: observable.deep,
      projectsRequired: observable.deep,
      transProjectsRequired: observable.deep,
      myProjects: observable.deep,
      vacation: observable,
      removedProjects: observable.deep,
      translator_id: observable,
      translator_status: observable,
      translator_info: observable,
      getDashboardStatisticsData: action.bound,
      getDashboardAdditionalData: action.bound,
      getProjectsAffectedData: action.bound,
      getDashboardBalanceData: action.bound,
      postConvertedRequest: action.bound,
      balance: observable,
      pending: observable,
      pendingTotal: observable,
      pendingPages: observable,
      converted: observable,
      parkedCredits: observable,
      statement: observable.deep,
      statementSent: observable,
      postDashboardTransActiveData: action.bound,
      getDashboardStatementData: action.bound,
      getDashboardExportStatementData: action.bound,
      checkRecruitment: action.bound,
      postRejectedProjectData: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';

export function LQAEditorStore() {
  return makeObservable(
    {
      formData: {} as any,
      isFormSaved: true as boolean,
      isConfirmationWithoutModalOpen: false as boolean,
      updateFormData(newFormData: any) {
        this.formData = {...this.formData, ...newFormData};
      },
      setFormSaved(isSaved: boolean) {
        this.isFormSaved = isSaved;
      },
      setConfirmationModalState(state: boolean) {
        this.isConfirmationWithoutModalOpen = state;
      },
    },
    {
      formData: observable,
      isFormSaved: observable,
      isConfirmationWithoutModalOpen: observable,
      updateFormData: action.bound,
      setFormSaved: action.bound,
      setConfirmationModalState: action.bound,
    }
  );
}

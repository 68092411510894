import {ReactNode} from 'react';
import {makeObservable, observable, action} from 'mobx';
import {getFilters, getTalents} from 'api/voices';
import {IUrlData} from 'pages_refactor/VoicesPage/VoicesPageView';
import {
  DEFAULT_FILTERS,
  SortDirectionEnum,
  TalentSortEnum,
  checkDefaultFiltersPresent,
  filtersDto,
} from 'pages_refactor/VoicesPage/VoicesUtils';

export interface IFilterOption {
  value: string | number;
  label: string;
  icon?: string | ReactNode;
}

export interface ILanguageFilterDto extends IFilterOption {
  _id: string;
  type: string;
}

export interface IFilters {
  language: IFilterOption[] | null;
  projectTypes: IFilterOption[] | null;
  gender: IFilterOption[] | null;
  performance: IFilterOption[] | null;
  voiceAges: IFilterOption[] | null;
}

export interface ISelectedFilters {
  language?: string | null;
  projectTypes?: (string | number)[] | null;
  voiceAges?: string | null;
  gender?: string | null;
  performance?: string | null;
  sortBy?: TalentSortEnum;
  sortDirection?: SortDirectionEnum;
}

interface ISelectedFiltersDto extends ISelectedFilters {
  voiceType?: string | null;
}

export enum SelectedFiltersNameEnum {
  language = 'LANGUAGE',
  projectTypes = 'PROJECT_TYPE',
  gender = 'GENDER',
  performance = 'PERFORMANCE',
  voiceAges = 'AGE',
}

export interface ITalentSample {
  _id: string;
  name: string;
  url: string;
  projectType: string;
  length: number;
  talentId: string;
  dynamicsGUID: string;
}

export interface ITalentData {
  _id: string;
  name: string;
  gender: string;
  language: string;
  voiceAges: string[];
  projectTypes: string[];
  performance: string[];
  featured: boolean;
  created: string;
  dynamicsGUID: string;
  samples: ITalentSample[];
}

export type SelectedFiltersNameEnumKeysType = keyof typeof SelectedFiltersNameEnum;

export type ISelectedFiltersType = ISelectedFilters;

export type ITalentDataType = ITalentData;

export type IFiltersType = IFilters;

interface IPagination {
  page: number;
  total: number;
}

export function voicesStore() {
  return makeObservable(
    {
      isLoading: false as boolean,
      filters: null as IFilters | null,
      selectedFilters: {} as ISelectedFilters,
      talentsData: [] as ITalentData[],
      isLeadFormOpened: false as boolean,
      pagination: {page: 1, total: 0} as IPagination,
      playingAudio: '' as string,
      selectedTalent: '' as string,
      getFiltersData(urlData: IUrlData) {
        getFilters().then(action((filters) => (this.filters = filtersDto(filters))));
        this.selectedFilters = checkDefaultFiltersPresent(urlData);
      },
      getTalentsData() {
        const valuesToSkip: string[] = ['sortBy', 'sortDirection'];
        const filtersDtoObject: ISelectedFiltersDto = {...this.selectedFilters, voiceType: this.selectedFilters.gender};
        delete filtersDtoObject.gender;
        const fieldsArray: string[] = Object.keys(filtersDtoObject).filter(
          (field: string) => !valuesToSkip.includes(field)
        );
        const params: string = fieldsArray.reduce((paramsString: string, key: string) => {
          const value = filtersDtoObject?.[key as keyof ISelectedFiltersType];
          return !!value && value[0] !== 'all' ? (paramsString += `&${key}=${value.toString()}`) : paramsString;
        }, `?sort=${filtersDtoObject.sortBy}:${filtersDtoObject.sortDirection}&page=${this.pagination?.page || 1}&limit=10`);
        getTalents(params).then(
          action(({data, page, total}) => {
            this.talentsData = data;
            this.pagination = {
              page,
              total,
            };
          })
        );
      },
      setSelectedFilter(name: keyof ISelectedFiltersType, value: string | number | (string | number)[] | null) {
        this.selectedFilters = checkDefaultFiltersPresent({
          ...this.selectedFilters,
          [name]: value,
        });
      },
      clearAllFilters() {
        this.selectedFilters = DEFAULT_FILTERS;
      },
      setOverrideFilters(overrideFilters: ISelectedFilters) {
        this.selectedFilters = checkDefaultFiltersPresent(overrideFilters);
      },
      setLeadFormState(newState: boolean) {
        this.isLeadFormOpened = newState;
      },
      setPage(page: number) {
        this.pagination.page = page;
      },
      handleAudioPlay(link: string) {
        this.playingAudio = link;
      },
      handleTalentSelect(name: string) {
        this.selectedTalent = name;
      },
    },
    {
      isLoading: observable,
      isLeadFormOpened: observable,
      playingAudio: observable,
      selectedTalent: observable,
      filters: observable.deep,
      selectedFilters: observable.deep,
      talentsData: observable.deep,
      pagination: observable.deep,
      getFiltersData: action.bound,
      getTalentsData: action.bound,
      setSelectedFilter: action.bound,
      clearAllFilters: action.bound,
      setOverrideFilters: action.bound,
      setLeadFormState: action.bound,
      setPage: action.bound,
      handleAudioPlay: action.bound,
      handleTalentSelect: action.bound,
    }
  );
}

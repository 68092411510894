import {makeObservable, observable, action} from 'mobx';
import api from 'api';
import {DatePickerValue} from 'components/BaseComponents/BaseDatepicker';

const getFormattedDate = (date: Date) => {
  if (typeof date.getFullYear !== 'function') {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
};

export function availabilityStore() {
  return makeObservable(
    {
      timeZone: '' as string,
      activeDay: '' as string,
      daysAvailbility: [] as string[],
      hoursAvailbility: [] as any,
      selectedFromDate: null as DatePickerValue,
      selectedFromDateDefault: Date() as DatePickerValue,
      selectedToDate: null as DatePickerValue,
      fromDateOpen: false as boolean,
      toDateOpen: false as boolean,
      timeZonesList: {} as any,
      daysList: {} as any,
      availabilityList: {} as any,
      validationError: false as boolean,
      isLoading: false as boolean,
      setTimeZone(zone: string) {
        this.timeZone = zone;
        this.validationError = false;
      },
      setActiveDay(day: string) {
        this.activeDay = day;
      },
      setTimeZonesList(list: any) {
        this.timeZonesList = list;
      },
      setDaysList(list: any) {
        this.daysList = list;
      },
      setAvailabilityList(list: any) {
        this.availabilityList = list;
      },
      setDaysAvailbility(days: string[]) {
        this.daysAvailbility = days;
      },
      setHoursAvailbility(hours: any) {
        this.hoursAvailbility = hours;
      },
      setSelectedFromDate(date: DatePickerValue, isDefault = false) {
        this.selectedFromDate = date;
        if (isDefault) {
          this.selectedFromDateDefault = date || Date();
        }
        if (this.fromDateOpen) {
          this.fromDateOpen = false;
          this.toDateOpen = true;
        }
      },
      setSelectedToDate(date: DatePickerValue) {
        this.selectedToDate = date;
        this.toDateOpen = false;
      },
      setFromDateOpen(isOpen: boolean) {
        this.fromDateOpen = isOpen;
      },
      setToDateOpen(isOpen: boolean) {
        this.toDateOpen = isOpen;
      },
      postAvailabilityInfo() {
        if (this.timeZone === '') {
          this.validationError = true;
          return;
        }
        const data = new FormData();
        data.append('timezone', this.timeZone);

        if (this.selectedFromDate && this.selectedToDate) {
          data.append('vacation', 'on');
          data.append('from_project_date', getFormattedDate(this.selectedFromDate as Date));
          data.append('until_project_date', getFormattedDate(this.selectedToDate as Date));
        }

        this.daysAvailbility.forEach((day) => data.append('daysAvailbility[]', day.toString()));
        Object.keys(this.hoursAvailbility).forEach((key, i) =>
          this.hoursAvailbility[key].forEach((hour: number) =>
            data.append(`hoursAvailbility[${i + 1}][]`, hour.toString())
          )
        );
        data.append('is_react', '1');
        this.isLoading = true;
        api.profile
          .addAvailabilityInfo({fd: data})
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      timeZone: observable,
      activeDay: observable,
      daysAvailbility: observable,
      hoursAvailbility: observable,
      selectedFromDate: observable,
      selectedFromDateDefault: observable,
      selectedToDate: observable,
      fromDateOpen: observable,
      toDateOpen: observable,
      timeZonesList: observable,
      daysList: observable,
      availabilityList: observable,
      validationError: observable,
      isLoading: observable,
      setTimeZone: action.bound,
      setActiveDay: action.bound,
      setTimeZonesList: action.bound,
      setDaysList: action.bound,
      setAvailabilityList: action.bound,
      setDaysAvailbility: action.bound,
      setHoursAvailbility: action.bound,
      setSelectedFromDate: action.bound,
      setSelectedToDate: action.bound,
      setFromDateOpen: action.bound,
      setToDateOpen: action.bound,
    }
  );
}

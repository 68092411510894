import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export interface IProjectNote {
  id: number;
  subject: string;
  content?: string;
  updated?: string;
  isDefault?: boolean;
}

export function projectNotesStore() {
  return makeObservable(
    {
      projectNotes: [] as IProjectNote[],
      isLoading: false as boolean,
      getProjectNotes(CSRFToken: string) {
        api.profile.getProjectNotesList(CSRFToken).then(
          action((response) => {
            this.projectNotes = response;
          })
        );
      },
      async saveNoteNew(CSRFToken: string, subject: string, content: string, id: string): Promise<boolean> {
        if (content) {
          this.isLoading = true;
          return api.profile
            .saveProjectNote({
              CSRFToken,
              subject,
              content,
              id,
            })
            .then(() => {
              this.getProjectNotes(CSRFToken);
              return true;
            })
            .catch(() => false)
            .finally(() => {
              this.isLoading = false;
            });
        }
        return false;
      },
      deleteNote(CSRFToken: string, id: string) {
        this.isLoading = true;
        api.profile
          .deleteProjectNote(CSRFToken, id)
          .then(() => {
            this.getProjectNotes(CSRFToken);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      projectNotes: observable,
      isLoading: observable,
      getProjectNotes: action.bound,
      saveNoteNew: action.bound,
      deleteNote: action.bound,
    }
  );
}

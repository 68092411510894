import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function resetPasswordStore() {
  return makeObservable(
    {
      email: '' as string,
      emailSent: false as boolean,
      error: '' as string,
      isLoading: false as boolean,
      setEmail(email: string) {
        this.error = '';
        this.email = email;
      },
      submitResetPassword(CSRFToken: string) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(this.email).toLowerCase())) {
          this.isLoading = true;
          api.wizard
            .postResetPassword(this.email, CSRFToken)
            .then((response) => {
              if (response?.data?.errors?.length) {
                this.error = 'This email address does not match our records';
              } else {
                this.emailSent = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.error = 'Input does not match email format';
        }
      },
    },
    {
      email: observable,
      emailSent: observable,
      error: observable,
      isLoading: observable,
      setEmail: action.bound,
      submitResetPassword: action.bound,
    }
  );
}

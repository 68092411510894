import {makeObservable, observable, action} from 'mobx';
import api from 'api';

interface IResetCodeData {
  oldCode: string;
  newCode: string;
}

interface IResetModalData {
  isOpened: boolean;
  success: boolean;
}

export function enableTFAStore() {
  return makeObservable(
    {
      isTFAEnabled: false as boolean,
      TFAData: {} as any,
      code: '' as string,
      deactivateCode: '' as string,
      resetCodeData: {
        oldCode: '',
        newCode: '',
      } as IResetCodeData,
      resetModalData: {
        isOpened: false,
        success: true,
      } as IResetModalData,
      errorMessages: {} as any,
      isLoading: false as boolean,
      updateProfile: false as boolean,
      setIsTFAEnabled(enabled: boolean) {
        this.isTFAEnabled = enabled;
      },
      setCode(code: string) {
        this.code = code;
      },
      setDeactivateCode(deactivateCode: string) {
        this.deactivateCode = deactivateCode;
      },
      setResetCodeData(field: keyof IResetCodeData, value: string) {
        this.resetCodeData = {...this.resetCodeData, [field]: value};
      },
      handleResetModalClose() {
        this.resetModalData.isOpened = false;
      },
      resetTFAOldCodeWithNewCode(CSRFToken: string) {
        this.isLoading = true;
        this.updateProfile = false;

        api.profile
          .sendTFACode({
            CSRFToken,
            secret: this.TFAData?.secret || '',
            secret_hash: this.TFAData?.secret_hash || '',
            code: this.resetCodeData.newCode,
            verify_code: this.resetCodeData.oldCode,
          })
          .then((response) => {
            this.resetModalData.isOpened = true;
            if (response.status !== 200 || response.data?.status?.code !== 0) {
              this.resetModalData.success = false;
              return;
            }
            this.resetModalData.success = true;
            this.TFAData = {};
            this.getTFACode(CSRFToken);
          })
          .catch(() => {
            this.resetModalData = {
              isOpened: true,
              success: false,
            };
          })
          .finally(() => {
            this.resetCodeData.oldCode = '';
            this.resetCodeData.newCode = '';
            this.isLoading = false;
            this.updateProfile = true;
          });
      },
      getTFACode(CSRFToken: string) {
        api.profile.getTFACode(CSRFToken).then(
          action(({results}) => {
            this.TFAData = results || {};
          })
        );
      },
      postTFACode(CSRFToken: string) {
        this.isLoading = true;
        this.updateProfile = false;

        api.profile
          .sendTFACode({
            CSRFToken,
            secret: this.TFAData?.secret || '',
            secret_hash: this.TFAData?.secret_hash || '',
            code: this.code,
          })
          .then((response) => {
            this.isLoading = false;

            if (response.status !== 200) {
              return;
            }
            if (response.data?.status?.code !== 0) {
              this.errorMessages = {tfa_status: response.data?.status?.msg};
            } else {
              this.updateProfile = true;
              window.location.replace('/profile#tfa_disable');
              this.errorMessages = {};
            }
          });
      },
      deactivateTFACode(CSRFToken: string) {
        this.isLoading = true;
        this.updateProfile = false;

        api.profile
          .resetTFACode(CSRFToken, this.deactivateCode)
          .then((response) => {
            if (response.status !== 200) {
              return;
            }
            if (response.data?.status?.code !== 0) {
              this.errorMessages = {reset_tfa_status: response.data?.status?.msg};
            } else {
              this.updateProfile = true;
              window.location.replace('/profile#tfa_enable');
              this.errorMessages = {};
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      isTFAEnabled: observable,
      TFAData: observable,
      code: observable,
      deactivateCode: observable,
      resetCodeData: observable,
      errorMessages: observable,
      isLoading: observable,
      updateProfile: observable,
      resetModalData: observable,
      setIsTFAEnabled: action.bound,
      setCode: action.bound,
      setDeactivateCode: action.bound,
      getTFACode: action.bound,
      postTFACode: action.bound,
      deactivateTFACode: action.bound,
      setResetCodeData: action.bound,
      handleResetModalClose: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function expertiseStore() {
  return makeObservable(
    {
      expertise: '' as string,
      isExpertOnly: false as boolean,
      userExpertise: [] as [],
      availableExpertise: [] as [],
      isLoading: false as boolean,
      setExpertise(expertise: string) {
        this.expertise = expertise;
      },
      setExpertOnly(expertOnly: boolean) {
        this.isExpertOnly = expertOnly;
      },
      setAvailableExpertises(list: []) {
        this.availableExpertise = list;
      },
      setUserExpertise(list: []) {
        this.userExpertise = list;
      },
      addUserExpertise(userId: string) {
        if (this.expertise !== '') {
          this.isLoading = true;
          api.profile
            .addExpertiseToUser(userId, this.expertise)
            .then()
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      removeUserExpertise(userId: string, expertise: string) {
        this.isLoading = true;
        api.profile
          .removeExpertiseForUser(userId, expertise)
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
      sendExpertOnly(flag: boolean) {
        this.isExpertOnly = flag;
        this.isLoading = true;
        api.profile
          .checkExpertOnlyFlag(flag)
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      expertise: observable,
      isExpertOnly: observable,
      userExpertise: observable,
      availableExpertise: observable,
      isLoading: observable,
      setExpertise: action.bound,
      setExpertOnly: action.bound,
      setAvailableExpertises: action.bound,
      setUserExpertise: action.bound,
      sendExpertOnly: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function mailingPreferencesStore() {
  return makeObservable(
    {
      preferences: {
        email_promotion: undefined as string | undefined,
        email_yourproject: undefined as string | undefined,
        email_project_files_attach: undefined as string | undefined,
        email_translatorproject: undefined as string | undefined,
        email_newproject: undefined as string | undefined,
        translation_started: undefined as string | undefined,
        new_comment: undefined as string | undefined,
        approve_time: undefined as string | undefined,
        word_count_diff: undefined as string | undefined,
        project_signed: undefined as string | undefined,
        failed_callback: undefined as string | undefined,
        project_submitted: undefined as string | undefined,
        project_delayed: undefined as string | undefined,
      },
      additionalEmail: '' as string,
      isLoading: false as boolean,
      updatePreferences(newPreferences: {[key: string]: string | undefined}) {
        this.preferences = {...this.preferences, ...newPreferences};
      },
      setAdditionalEmail(email: string) {
        this.additionalEmail = email;
      },
      postMailingPreferences(CSRFToken: string) {
        const formData = new FormData();
        Object.keys(this.preferences).forEach((key) => {
          if ((this.preferences as {[key: string]: string | undefined})[key]) {
            formData.append(key, (this.preferences as {[key: string]: string})[key]);
          }
        });
        formData.append('CSRFToken', CSRFToken);
        api.profile
          .updateMailingPreferences({fd: formData})
          .then(
            action(() => {
              this.isLoading = true;
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      postAdditionalEmail(userId: string) {
        if (this.additionalEmail !== '') {
          this.isLoading = true;
          api.profile
            .addSecEmailToUser(userId, this.additionalEmail)
            .then(() => {
              this.additionalEmail = '';
            })
            .finally(
              action(() => {
                this.isLoading = false;
              })
            );
        }
      },
      deleteAdditionalEmail(userId: string, email: string) {
        this.isLoading = true;
        api.profile
          .removeSecEmailFromUser(userId, email)
          .then()
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
    },
    {
      preferences: observable,
      additionalEmail: observable,
      isLoading: observable,
      updatePreferences: action.bound,
      setAdditionalEmail: action.bound,
      postAdditionalEmail: action.bound,
      deleteAdditionalEmail: action.bound,
    }
  );
}

type Primitive = string | number | boolean | null | undefined;
type JsonObject = {[key: string]: Primitive | JsonObject | JsonArray};
type JsonArray = (Primitive | JsonObject | JsonArray)[];
type AllowedTypes = Primitive | JsonObject | JsonArray;

export const setItem = (key: string, value: AllowedTypes) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key: string) => {
  try {
    const value: string | null = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    return null;
  }
};

import axios from 'axios';

const apiURL = window.location.origin;

export default class API {
  constructor() {
    throw new Error('Cannot construct singleton');
  }

  static get(path, params) {
    return this._request(path, 'GET', null, params);
  }

  static post(path, body, params) {
    return this._request(path, 'POST', body, params);
  }

  static put(path, body, params) {
    return this._request(path, 'PUT', body, params);
  }

  static delete(path, body, params) {
    return this._request(path, 'DELETE', body, params);
  }

  static async _request(path, method, body) {
    const options = {
      method: method || 'GET',
      url: apiURL + path,
    };

    if (body) {
      options.data = body;
    }

    const response = await axios(options);

    if (response.data?.errors?.length > 0) {
      throw new Error('API error: ' + response.data?.status?.msg);
    }
    return response.data.results;
  }
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function SMSVerificationStore() {
  return makeObservable(
    {
      countryNumber: undefined as number | undefined,
      prefixNumber: undefined as number | undefined,
      mobileNumber: undefined as number | undefined,
      isLoading: false as boolean,
      setCountryNumber(number: number) {
        this.countryNumber = number;
      },
      setPrefixNumber(number: number) {
        this.prefixNumber = number;
      },
      setMobileNumber(number: number) {
        this.mobileNumber = number;
      },
      sendNumber() {
        if (this.countryNumber && this.prefixNumber && this.mobileNumber) {
          api.profile
            .sendSMSVerification({
              country_number: this.countryNumber,
              prefix_number: this.prefixNumber,
              mobile_number: this.mobileNumber,
            })
            .then(() => {
              this.countryNumber = undefined;
              this.prefixNumber = undefined;
              this.mobileNumber = undefined;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
    {
      countryNumber: observable,
      prefixNumber: observable,
      mobileNumber: observable,
      isLoading: observable,
      setCountryNumber: action.bound,
      setPrefixNumber: action.bound,
      setMobileNumber: action.bound,
      sendNumber: action.bound,
    }
  );
}

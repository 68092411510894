import {makeObservable, observable, action} from 'mobx';
import {
  getBonusAvailableProjects,
  getClassicAvailableProjects,
  getLLAvailableProjects,
  getLLAvailableProjectsCount,
  getQaAvailableProjects,
  getQaAvailableProjectsCount,
} from 'api/translatorAvailableProjects';
import {
  IAvailableProjectsFiltersData,
  IBonusProject,
  IBundleProject,
  IClassicProject,
  ILLProject,
  IQAProject,
} from 'pages_refactor/AvailableProjects/AvailableProjectsUtils';
import {AvailableProjectTypeEnum} from 'pages_refactor/AvailableProjects/components/Tabs/TabsUtils';
import {showNotification} from 'components/BaseComponents/BaseNotification';
import {
  checkIsNewProjectAvailable,
  sortClassicProjects,
} from 'pages_refactor/AvailableProjects/AvailableProjectsHelpers';
import {getFilterParams} from 'pages_refactor/AvailableProjects/components/Filters/FiltersUtils';

export interface IProjectsCountResponse {
  count: number;
}

export interface IProjectCountWithJobsResponse extends IProjectsCountResponse {
  jobs: IClassicProject[];
}

export function availableProjectsStore() {
  return makeObservable(
    {
      isLoading: false as boolean,
      selectedProjectType: AvailableProjectTypeEnum.CLASSIC as AvailableProjectTypeEnum,
      projectsData: {
        [AvailableProjectTypeEnum.CLASSIC]: [],
        [AvailableProjectTypeEnum.BUNDLE]: [],
        [AvailableProjectTypeEnum.BONUS]: [],
        [AvailableProjectTypeEnum.QA]: [],
        [AvailableProjectTypeEnum.LL]: [],
      } as {[key: string]: IClassicProject[] | ILLProject[] | IBonusProject[] | IQAProject[] | IBundleProject[]},
      projectsCount: {
        [AvailableProjectTypeEnum.CLASSIC]: 0,
        [AvailableProjectTypeEnum.BUNDLE]: 0,
        [AvailableProjectTypeEnum.BONUS]: 0,
        [AvailableProjectTypeEnum.QA]: 0,
        [AvailableProjectTypeEnum.LL]: 0,
      } as {[key: string]: number},
      isBundleAvailable: false as boolean,
      shouldPlayMusic: false as boolean,
      filtersData: {} as IAvailableProjectsFiltersData,
      getProjectsData(CSRFToken: string, isInit?: boolean) {
        const filters = getFilterParams(this.filtersData);
        const defaultParamsObj = {
          t: String(new Date().getTime()),
          CSRFToken,
          ...filters,
        };
        this.isLoading = true;
        Promise.allSettled([
          getClassicAvailableProjects(defaultParamsObj),
          getBonusAvailableProjects(defaultParamsObj),
          getQaAvailableProjects(defaultParamsObj),
          getQaAvailableProjectsCount(defaultParamsObj),
          getLLAvailableProjects(defaultParamsObj),
          getLLAvailableProjectsCount(defaultParamsObj),
        ])
          .then(
            action((res) => {
              this.isBundleAvailable = res[0].status === 'fulfilled' ? !!res[0].value.results.bundle_available : false;

              this.projectsCount = {
                [AvailableProjectTypeEnum.CLASSIC]: res[0].status === 'fulfilled' ? res[0].value.results.count : 0,
                [AvailableProjectTypeEnum.BUNDLE]:
                  res[0].status === 'fulfilled' ? res[0]?.value?.results?.bundles?.length : 0,
                [AvailableProjectTypeEnum.BONUS]: res[1].status === 'fulfilled' ? res[1]?.value?.results?.length : 0,
                [AvailableProjectTypeEnum.QA]: res[3].status === 'fulfilled' ? res[3].value.results.count : 0,
                [AvailableProjectTypeEnum.LL]: res[5].status === 'fulfilled' ? res[5].value.results.count : 0,
              };

              const oldProjectsData = this.projectsData;
              this.projectsData = {
                ...this.projectsData,
                [AvailableProjectTypeEnum.CLASSIC]:
                  res[0].status === 'fulfilled' ? sortClassicProjects(res[0].value.results.jobs) : [],
                [AvailableProjectTypeEnum.BUNDLE]: res[0].status === 'fulfilled' ? res[0].value.results.bundles : [],
                [AvailableProjectTypeEnum.BONUS]: res[1].status === 'fulfilled' ? res[1].value.results : [],
                [AvailableProjectTypeEnum.QA]: res[2].status === 'fulfilled' ? res[2].value.results : [],
                [AvailableProjectTypeEnum.LL]: res[4].status === 'fulfilled' ? res[4].value.results : [],
              };

              if (isInit) {
                return;
              }

              const isAnyProjectNew: boolean = checkIsNewProjectAvailable(
                Object.values(oldProjectsData).flat(),
                Object.values(this.projectsData).flat()
              );
              this.shouldPlayMusic = isAnyProjectNew;
            })
          )
          .finally(
            action(() => {
              if (!document.hidden) {
                showNotification('Project list updated', {type: 'success', autoClose: 5000});
              }
              this.isLoading = false;
            })
          );
      },
      setSelectedProjectType(newType: AvailableProjectTypeEnum) {
        this.selectedProjectType = newType;
      },
      handleSoundPlayEnd() {
        this.shouldPlayMusic = false;
      },
      setFiltersData(newFilters: IAvailableProjectsFiltersData) {
        this.filtersData = newFilters;
      },
    },
    {
      isLoading: observable,
      isBundleAvailable: observable,
      selectedProjectType: observable,
      shouldPlayMusic: observable,
      projectsData: observable.deep,
      projectsCount: observable.deep,
      filtersData: observable.deep,
      getProjectsData: action.bound,
      setSelectedProjectType: action.bound,
      handleSoundPlayEnd: action.bound,
      setFiltersData: action.bound,
    }
  );
}

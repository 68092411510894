import {makeObservable, observable, action} from 'mobx';
import {getAclList} from 'api/backoffice';

export function backofficeStore() {
  return makeObservable(
    {
      aclList: [] as string[],
      isLoading: false,

      getAclListData() {
        this.isLoading = true;
        getAclList()
          .then((responseData: string[]) => {
            this.aclList = responseData;
          })
          .finally(action(() => (this.isLoading = false)));
      },
    },
    {
      aclList: observable.deep,
      isLoading: observable,
      getAclListData: action.bound,
    }
  );
}

import {GLOBAL_CONSTS} from './const';

const {SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE} = GLOBAL_CONSTS;

export const parseExpertise = (data) => {
  // in this case data is good enough to not require additional parsing.
  return data;
};

export const getCurrencySymbol = (value) => {
  let result = null;

  switch (value) {
    case 'EUR':
    case 'EURO':
      result = '\u20AC';
      break;
    case 'USD':
      result = '$';
      break;
    case 'ILS':
    case 'NIS':
      result = '\u20AA';
      break;
    case 'GBP':
      result = '\u00A3';
      break;
    case 'CAD':
      result = 'CA$';
      break;
    case 'AUD':
      result = 'A$';
      break;
    case 'CHF':
      result = '\u20a3';
      break;
    default:
      break;
  }

  return result;
};

export const parseCoupon = (data) => ({
  id: data.id,
  key: data.key,
  creditValue: data.credits,
  currencyValue: data.localized,
  name: data.name,
  description: data.description,
  key_id: data.key_id,
});

export const parseProduct = (data) => {
  const parsedData = {
    unit: data.unit,
    name: data.name,
    sourceLanguage: data.srcLang,
    targetLanguage: data.tarLang,
    type: data.type,
    typeName: data.typeName,
    sendCOA: false,
    price: {
      localized: data.price.localized,
      credits: data.price.credits,
      translation: {
        credits: 0,
        localized: 0,
      },
      proofreading: {
        credits: 0,
        localized: 0,
      },
    },
    expertise: parseExpertise(data.expertise),
    wordCount: data.wordCount,
    needProofReading: data.needProofreading,
    exclusiveLanguages: data.exclusiveLanguages,
    editingTypeName: data.editingTypeName,
  };

  if (parsedData.typeName === 'Certificate of Accuracy') {
    parsedData.sendCOA = true;
  }

  if (parsedData.typeName === 'Expedite') {
    parsedData.typeName = 'Urgent delivery';
  }

  if (data.needExpertise) {
    parsedData.needExpertise = data.needExpertise;
  }

  parsedData.price.translation.credits = parsedData.price.credits;
  parsedData.price.translation.localized = parsedData.price.localized;

  if (parsedData.typeName === 'Translation' && data.separatePrices?.translation && data.separatePrices?.editing) {
    parsedData.price.translation.credits = data.separatePrices.translation?.credits ?? 0;
    parsedData.price.translation.localized = data.separatePrices.translation?.localized ?? 0;
    parsedData.price.proofreading.credits = data.separatePrices.editing?.credits ?? 0;
    parsedData.price.proofreading.localized = data.separatePrices.editing?.localized ?? 0;
  }

  if (data.needProofreading) {
    parsedData.editingTypeName = data.editingTypeName;
  }

  return parsedData;
};

export const parseOrder = (data) => {
  let products = [];
  let coupons = [];
  let voucherPromotion = {};
  let i;

  const {
    translationItems,
    paymentItems,
    invoiceAddress,
    orderID,
    timeEstimation: {TimeEstimation},
    isSalePackage,
    userCredits: {localized, credits},
    needProofReading,
    expediteData,
    deliveryData,
    billingInfo,
    words,
    COA,
    poid,
    userId,
  } = data;

  const {
    currency,
    subtotalNoDiscount,
    debt,
    totalNoVat,
    VAT,
    cost,
    totalCoupon,
    discount,
    tmDiscount,
    transactionFee,
    totalIncludingVat,
    currencyExchange,
    upcomingCoupons,
    minPurchase,
    restMinPayment,
    certificateCost,
    useBalance,
  } = paymentItems;

  for (i = 0; i < translationItems.length; i++) {
    products.push(parseProduct(translationItems[i]));
  }

  if (paymentItems.coupons) {
    for (i = 0; i < paymentItems.coupons.length; i++) {
      coupons.push(parseCoupon(paymentItems.coupons[i]));
    }
  }

  if (paymentItems.voucherPromotion) {
    voucherPromotion = {
      key: paymentItems.voucherPromotion.key,
      isPercent: paymentItems.voucherPromotion.isPercent,
      percent: paymentItems.voucherPromotion.percent,
      credits: paymentItems.voucherPromotion.credits,
      localized: paymentItems.voucherPromotion.localized,
    };
  }

  return {
    address: invoiceAddress,
    id: orderID,
    products: products,
    isSalePackage: isSalePackage,
    coupons,
    voucherPromotion: voucherPromotion,
    estimation: TimeEstimation,
    currency: currency === 'NIS' ? 'ILS' : currency,
    cost,
    totalCoupon: totalCoupon || 0,
    discount,
    tmDiscount,
    subtotal: subtotalNoDiscount,
    debt,
    total: totalNoVat.localized,
    vat: VAT.localized,
    fees: transactionFee.localized,
    totalIncludingVat: totalIncludingVat.localized,
    totalNoVat: totalNoVat.localized,
    usedFromBalance: useBalance,
    userCredits: {localized, credits},
    needProofReading: needProofReading,
    allowExpedite: expediteData.allowExpedite || false,
    needExpedite: expediteData.needExpedite || false,
    expediteData,
    billingAddress: billingInfo,
    words,
    COA,
    currencyExchange,
    deliveryData,
    poid,
    currencySymbol: getCurrencySymbol(currency),
    userId,
    upcomingCoupons: upcomingCoupons || [],
    minPurchase,
    orderAdjustment: restMinPayment && restMinPayment.localized,
    certificateCost: certificateCost && certificateCost.localized,
  };
};

export const getTimeStringFromTime = (time, format) => {
  if (typeof time !== 'number' && typeof time !== 'object') {
    console.log('wzz:Incorrect time passed. Expected number or object, instead got: ' + typeof time);
    return '';
  }

  if (
    time < 0 ||
    (typeof time === 'object' && (time.hours === undefined || time.days === undefined)) ||
    time.hours < 0 ||
    time.days < 0
  ) {
    console.log('wzz:Incorrect time passed. Expected positive number or object, instead got: ' + time);
    return '';
  }

  let hours,
    days,
    months = 0;

  if (typeof time === 'number') {
    days = Math.floor(time / (60 * 24)); // days in the time

    if (format && format === 'DHM') {
      hours = Math.floor(time / 60) % 24;
    } else {
      hours = time < 90 ? 1 : Math.round(time / 60) % 24; // hours in the time (without 'days')
    }
  } else {
    const {hours: timeHours, days: timeDays} = time;
    hours = timeHours;
    days = timeDays;
  }

  let estimationString;

  if (format && format === 'DHM') {
    // D:HH:MM format
    let minutes = time < 60 ? time : time - Math.floor(time / 60) * 60;

    estimationString = [days, hours >= 10 ? hours : '0' + hours, minutes >= 10 ? minutes : '0' + minutes].join(':');
  } else {
    // string format
    if (days >= 25) {
      months = Math.floor(days / 25);
      days %= 25;
    }
    if (months === 0) {
      if (hours === 0) {
        estimationString = days + ' day' + (days === 1 ? '' : 's');
      } else {
        if (days === 0) {
          estimationString = hours + ' hour' + (hours > '1' ? 's' : '');
        } else {
          estimationString =
            days +
            ' ' +
            'day' +
            (days === 1 ? '' : 's') +
            ' ' +
            'and' +
            ' ' +
            hours +
            ' ' +
            'hour' +
            (hours > '1' ? 's' : '');
        }
      }
    } else {
      estimationString = months + ' month' + (months === 1 ? '' : 's');
      if (days > 0) {
        estimationString += ' and ' + days + ' day' + (days === 1 ? '' : 's');
      }
    }
  }

  return estimationString;
};

export const getEstimation = (minutes) => {
  let days = Math.floor(minutes / (60 * 24));
  let hours = minutes < 60 ? 0 : Math.round(minutes / 60) % 24;
  let months = 0;
  if (days >= 25) {
    months = Math.floor(days / 25);
    days %= 25;
  }

  return {
    minutes,
    hours,
    days,
    months,
  };
};

export const convertWordsToSeconds = (wordCount, showDaysPassed) => {
  let days;
  let showDays =
    typeof showDaysPassed === 'object' && showDaysPassed.name === 'convertWordsToSeconds' ? undefined : showDaysPassed;
  if (typeof wordCount !== 'number') {
    console.log('source file: Incorrect type passed. Expected number, instead got: ' + typeof wordCount, 'wzz');
    return '';
  }

  if (wordCount < 0) {
    console.log('source file: Incorrect word count passed. Expected positive number, instead got: ' + wordCount, 'wzz');
    return '';
  }

  // this returns a string representation of the number as double digit if it's a positive single digit
  const makeTwoDigits = (value) => {
    let result = value;
    if (value <= 9 && value >= 0) {
      result = '0' + value;
    }

    return result;
  };

  if (showDays) {
    // reduce days
    days = Math.floor(wordCount / SECONDS_IN_DAY);
    wordCount -= days * SECONDS_IN_DAY;
  }

  // reduce hours
  let hours = Math.floor(wordCount / SECONDS_IN_HOUR);
  wordCount -= hours * SECONDS_IN_HOUR;
  // reduce minutes
  let minutes = Math.floor(wordCount / SECONDS_IN_MINUTE);
  wordCount -= minutes * SECONDS_IN_MINUTE;

  return `${showDays ? days` ` : ``}${makeTwoDigits(hours)}:${makeTwoDigits(minutes)}:${makeTwoDigits(wordCount)}`;
};

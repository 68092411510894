import {action, makeObservable, observable} from 'mobx';
import {
  ConvertCountry,
  ConvertFormField,
  ConvertMeta,
  ConvertPaymentMethod,
  ConvertRole,
  ConvertStatus,
  ConvertTableItem,
  Direction,
  formatNumber,
  today,
  threeMonthsAgo,
} from 'pages/Backoffice/components/Convert/ConvertUtils';
import api from 'api';

const defaultData: ConvertFormField = {
  userIdOrEmail: '',
  status: ConvertStatus.ALL,
  method: ConvertPaymentMethod.ALL,
  country: ConvertCountry.ALL,
  userRole: ConvertRole.ALL,
  perPage: 50,
  targetDateBefore: today,
  targetDateAfter: threeMonthsAgo,
};

const defaultMeta: ConvertMeta = {
  sort: 'id',
  direction: 'ASC',
  page: 1,
  total: 0,
};

export function convertStore() {
  return makeObservable(
    {
      field: defaultData,
      meta: defaultMeta,
      tableData: [] as ConvertTableItem[] | null,
      loading: false,
      loadingCSV: false,
      setField<T extends keyof ConvertFormField>(key: T, value: ConvertFormField[T]) {
        this.field[key] = value;
      },
      resetField(token: string) {
        this.field = defaultData;

        this.searchConvertData(1, 'id', 'DESC', token);
      },
      setMeta<T extends keyof ConvertMeta>(key: T, value: ConvertMeta[T]) {
        this.meta[key] = value;
      },
      searchConvertData(page: number, sort: string, order: Direction, token: string) {
        this.loading = true;
        this.meta.direction = order;
        this.meta.page = page;

        const params = {...this.field, perPage: this.field.perPage, page, sort, order};

        api.convertStoreAPI
          .getConvertData(params, token)
          .then(
            action((response) => {
              if (response) {
                this.tableData =
                  response?.items?.map((tableItem) => {
                    const total = Number(
                      (
                        parseFloat(tableItem.fee_amount.replace(/,/g, '')) +
                        parseFloat(tableItem.convert_amount.replace(/,/g, ''))
                      )?.toFixed(2)
                    );

                    return {
                      ...tableItem,
                      total: isNaN(total) ? '' : formatNumber(total),
                    };
                  }) || null;

                this.meta.total = response?.meta?.total || 1;
              }
            })
          )
          .finally(action(() => (this.loading = false)));
      },

      batchUpdateStatus(ids: number[], status: string, token: string) {
        this.loading = true;
        api.convertStoreAPI
          .updateStatuses(ids, status, token)
          .then(() => {
            this.tableData =
              this.tableData?.map((td) => ({
                ...td,
                status: ids.includes(td.id) ? (status as ConvertStatus) : td.status,
              })) || null;
          })
          .finally(action(() => (this.loading = false)));
      },

      getCSV(token: string) {
        this.loadingCSV = true;
        api.convertStoreAPI.fetchConvertCSV(this.field, token).finally(action(() => (this.loadingCSV = false)));
      },
    },
    {
      field: observable,
      loading: observable,
      loadingCSV: observable,
      tableData: observable,
      meta: observable.deep,
      setField: action.bound,
      setMeta: action.bound,
      resetField: action.bound,
      getCSV: action.bound,
      searchConvertData: action.bound,
      batchUpdateStatus: action.bound,
    }
  );
}

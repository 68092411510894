import {ThemeColors} from './index';

const colors: ThemeColors = {
  grey100: `rgb(0, 0, 0)`, // #000000
  grey090: `rgb(69, 77, 85)`, // #454d55
  grey085: `rgb(86, 105, 124)`, // #56697C
  grey080: `rgb(118, 128, 146)`, // #768092
  grey070: `rgb(146, 146, 146)`, // #929292
  grey065: `rgb(166, 166, 166)`, // #A6A6A6
  grey060: `rgb(190, 193, 196)`, // #BEC1C4
  grey050: `rgb(210, 214, 217)`, // #D2D6D9
  grey030: `rgb(233, 233, 242)`, // #E9E9F2
  grey020: `rgb(240, 244, 249)`, // #F0F4F9
  grey015: `rgb(242, 242, 242)`, // #F2F2F2
  grey010: `rgb(247, 247, 249)`, // #F7F7F9
  grey005: `rgb(250, 250, 250)`, // #FAFAFA
  grey000: `rgb(255, 255, 255)`, // #FFFFFF

  green100: `rgb(22, 30, 31)`, // #161E1F
  green090: `rgb(0, 52, 56)`, // #003438
  green085: `rgb(64, 103, 106)`, // #40676A
  green084: `rgb(127, 153, 155)`, // #7F999B
  green080: `rgb(142, 170, 168)`, // #8EAAA8
  green075: `rgb(204, 214, 215)`, // #CCD6D7
  green070: `rgb(4, 126, 111)`, // #047E6F
  green060: `rgb(111, 187, 160)`, // #6FBBA0
  green055: `rgb(12, 200, 117)`, // #0CC875
  green054: `rgb(27, 201, 67)`, // #1BC943
  green050: `rgb(213, 226, 203)`, // #D5E2CB
  green040: `rgb(220, 241, 232)`, // #DCF1E8
  green020: `rgb(232, 236, 232)`, // #E8ECE8
  green015: `rgb(242, 248, 248)`, // #F2F8F8
  green010: `rgb(244, 244, 244)`, // #F4F4F4

  celery100: `rgb(148, 224, 149)`, // #94E095
  celery050: `rgb(228, 250, 228)`, // #E4FAE4

  cyan110: `rgb(25, 158, 168)`, // #199EA8
  cyan100: `rgb(148, 212, 217)`, // #94D4D9
  cyan050: `rgb(219, 240, 242)`, // #DBF0F2
  cyan020: `rgb(233, 243, 247)`, // #E9F3F7

  pink100: `rgb(224, 50, 186)`, // #E032BA
  pink050: `rgb(253, 224, 255)`, // #FDE0FF

  blue100: `rgb(88, 87, 255)`, // #5857FF
  blue095: `rgb(10, 102, 194)`, // #0A66C2
  blue094: `rgb(24, 119, 242)`, // #1877F2
  blue093: `rgb(68, 140, 203)`, // #448CCB
  blue090: `rgb(106, 112, 255)`, // #6A70FF
  blue080: `rgb(137, 136, 255)`, // #8988FF
  blue050: `rgb(217, 218, 255)`, // #D9DAFF
  blue020: `rgb(236, 236, 255)`, // #ECECFF

  yellow100: `rgb(238, 163, 57)`, // #EEA339
  yellow090: `rgb(255, 175, 26)`, // #FFAF1A
  yellow080: `rgb(242, 182, 90)`, // #F2B65A
  yellow050: `rgb(255, 229, 193)`, // #FFE5C1
  yellow020: `rgb(255, 242, 224)`, // #FFF2E0

  red100: `rgb(234, 110, 104)`, // #EA6E68
  red050: `rgb(246, 223, 223)`, // #F6DFDF
  red020: `rgb(255, 238, 238)`, // #FFEEEE

  orange100: `rgb(254, 89, 40)`, // #FE5928
  orange090: `rgb(255, 122, 83)`, // #FF7A53
};

export default colors;

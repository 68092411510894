import {action, makeObservable, observable} from 'mobx';

export interface MyProjectsWSPayload {
  event: string;
  project_id: number;
  project_status: string;
  client_id: string;
}

export function myProjectsStore() {
  return makeObservable(
    {
      isDataChanged: false as boolean,
      handleProjectsParamsChanged(userId: string, payload: MyProjectsWSPayload) {
        if (+userId === +payload.client_id) {
          this.isDataChanged = true;
        }
      },
      toggleDataChanged(value: boolean) {
        this.isDataChanged = value;
      },
    },
    {
      isDataChanged: observable,
      handleProjectsParamsChanged: action.bound,
      toggleDataChanged: action.bound,
    }
  );
}

import {action, makeObservable, observable} from 'mobx';
import {calculateInstantPriceQuote, getDefaultCurrency} from 'api/wizard';
import {
  IQuoteData,
  IValidateFormDataConfig,
  QouteTypeEnum,
  checkValueIsDigitsOnly,
  getDataForRechargeAmountQuote,
  getDataForWordCountQuote,
  validateFormData,
} from 'pages_refactor/PaymentQuote/PaymentQuoteUtils';
import {ApiResponse, IVoucherResultResponse, SourceLanguage, TargetLanguage} from 'pages/Wizard/types';
import {submitCoupon} from 'api/payment';

export function paymentQuoteStore() {
  return makeObservable(
    {
      isLoading: false as boolean,
      selectedType: QouteTypeEnum.WORD_COUNT as QouteTypeEnum,
      wordCount: '' as string,
      rechargeAmount: '' as string,
      quoteData: null as IQuoteData | null,
      sourceLanguage: null as SourceLanguage | null,
      targetLanguages: [] as TargetLanguage[],
      expertise: null as string | null,
      isEditingEnabled: false as boolean,
      errorFields: [] as string[],
      defaultCurrency: '' as string,
      onSelectedTypeChange(newType: QouteTypeEnum) {
        this.selectedType = newType;
      },
      onWordCountChange(newValue: string) {
        this.errorFields = this.errorFields.filter((key) => key !== 'word_count');
        if (checkValueIsDigitsOnly(newValue)) {
          this.wordCount = newValue;
        }
      },
      onRechargeAmountChange(newValue: string) {
        this.errorFields = this.errorFields.filter((key) => key !== 'recharge_amount');
        if (checkValueIsDigitsOnly(newValue)) {
          this.rechargeAmount = newValue;
        }
      },
      getPurchaseCreditsQuote(CSRFToken?: string) {
        const formValidationConfig: IValidateFormDataConfig = {
          recharge_amount: {
            value: this.rechargeAmount,
            type: 'number',
            required: true,
          },
        };
        const errorsList = validateFormData(formValidationConfig);

        if (!CSRFToken || errorsList.length) {
          this.errorFields = errorsList;
          return;
        }
        this.isLoading = true;
        const fd: FormData = getDataForRechargeAmountQuote(CSRFToken, this.rechargeAmount, 'calc');

        calculateInstantPriceQuote({
          fd,
        })
          .then(
            action((res) => {
              this.quoteData = res.results ? res.results : null;
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      getWordCountQuote(CSRFToken?: string) {
        const formValidationConfig: IValidateFormDataConfig = {
          source_language: {
            value: this.sourceLanguage?.slug || '',
            type: 'string',
            required: true,
          },
          target_languages: {
            value: this.targetLanguages,
            type: 'array',
            required: true,
          },
          word_count: {
            value: this.wordCount,
            type: 'string',
            required: true,
          },
        };
        const errorsList = validateFormData(formValidationConfig);

        if (!CSRFToken || errorsList.length || !this.sourceLanguage) {
          this.errorFields = errorsList;
          return;
        }
        this.isLoading = true;
        const fd: FormData = getDataForWordCountQuote(
          CSRFToken,
          'calc',
          this.sourceLanguage.slug,
          this.targetLanguages,
          +this.wordCount,
          this.isEditingEnabled,
          this.expertise || '',
          ''
        );

        calculateInstantPriceQuote({
          fd,
        })
          .then(
            action((res) => {
              this.quoteData = res.results;
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
      async getRechargeAmountCartKey(CSRFToken?: string) {
        if (!CSRFToken || !this.quoteData) {
          return;
        }
        this.isLoading = true;
        const sourceLangForWordCount: string =
          this.quoteData.payment_information.translationItems?.[0].srcLang?.slug || '';
        const targetLangsForWordCount: string[] = this.quoteData.payment_information.translationItems
          .map((item) => (item.tarLang?.slug ? item.tarLang?.slug : ''))
          .filter(Boolean);
        const fd: FormData =
          this.selectedType === QouteTypeEnum.WORD_COUNT
            ? getDataForWordCountQuote(
                CSRFToken,
                'cart',
                sourceLangForWordCount,
                targetLangsForWordCount,
                this.quoteData.payment_information.translationItems?.[0]?.wordCount,
                this.isEditingEnabled,
                this.expertise || '',
                ''
              )
            : getDataForRechargeAmountQuote(
                CSRFToken,
                String(this.quoteData.price_quote.SUMMARY_COST.customer),
                'cart'
              );

        const response = await calculateInstantPriceQuote({
          fd,
        })
          .then(action((res) => res.results))
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );

        return response;
      },
      async onCouponApply(couponID: string, CSRFToken?: string): Promise<ApiResponse<IVoucherResultResponse> | null> {
        if (!couponID || !CSRFToken || !this.quoteData?.price_quote.SUMMARY_TOTAL_VAT.customer) {
          return null;
        }
        this.isLoading = true;
        const response: ApiResponse<IVoucherResultResponse> = await submitCoupon({
          couponID,
          amount: this.quoteData?.price_quote.SUMMARY_TOTAL_VAT.customer,
          CSRFToken,
        })
          .then((res) => res.data)
          .finally(action(() => (this.isLoading = false)));

        return response;
      },
      async getQuoteCurrency() {
        this.resetQuoteData();
        const response = await getDefaultCurrency();
        this.defaultCurrency = response.results?.Currency || '';
      },
      onSourceLanguageChange(newLang: SourceLanguage) {
        this.errorFields = this.errorFields.filter((key) => key !== 'source_language');
        this.sourceLanguage = newLang;
      },
      setListOfTargetLangs(newList: TargetLanguage[]) {
        this.errorFields = this.errorFields.filter((key) => key !== 'target_languages');
        this.targetLanguages = newList;
      },
      setExpertise(id: string | null) {
        this.expertise = id;
      },
      toggleEditing() {
        this.isEditingEnabled = !this.isEditingEnabled;
      },
      resetQuoteData() {
        this.quoteData = null;
      },
      resetErrorFields() {
        this.errorFields = [];
      },
      getQuoteData(CSRFToken?: string) {
        if (this.selectedType === QouteTypeEnum.PURCHASE_CREDITS) {
          this.getPurchaseCreditsQuote(CSRFToken);
          return;
        }
        this.getWordCountQuote(CSRFToken);
      },
    },
    {
      isLoading: observable,
      selectedType: observable,
      wordCount: observable,
      rechargeAmount: observable,
      expertise: observable,
      isEditingEnabled: observable,
      defaultCurrency: observable,
      quoteData: observable.deep,
      sourceLanguage: observable.deep,
      targetLanguages: observable.deep,
      errorFields: observable.deep,
      onSelectedTypeChange: action.bound,
      onWordCountChange: action.bound,
      onRechargeAmountChange: action.bound,
      getPurchaseCreditsQuote: action.bound,
      getRechargeAmountCartKey: action.bound,
      resetQuoteData: action.bound,
      onSourceLanguageChange: action.bound,
      setListOfTargetLangs: action.bound,
      setExpertise: action.bound,
      toggleEditing: action.bound,
      getWordCountQuote: action.bound,
      resetErrorFields: action.bound,
      onCouponApply: action.bound,
      getQuoteData: action.bound,
      getQuoteCurrency: action.bound,
    }
  );
}

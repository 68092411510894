import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function referencesStore() {
  return makeObservable(
    {
      refereeName: ['', '', ''] as string[],
      refereeOrg: ['', '', ''] as string[],
      refereeEmail: ['', '', ''] as string[],
      isLoading: false as boolean,
      setRefereeName(names: string[]) {
        this.refereeName = names;
      },
      setRefereeOrg(organizations: string[]) {
        this.refereeOrg = organizations;
      },
      setRefereeEmail(emails: string[]) {
        this.refereeEmail = emails;
      },
      postReferences(formData: any) {
        this.isLoading = true;
        api.profile
          .addRefereesInfo({fd: formData})
          .then()
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      refereeName: observable,
      refereeOrg: observable,
      refereeEmail: observable,
      isLoading: observable,
      setRefereeName: action.bound,
      setRefereeOrg: action.bound,
      setRefereeEmail: action.bound,
      postReferences: action.bound,
    }
  );
}

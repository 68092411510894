import {makeObservable, observable, action} from 'mobx';
import {
  addNewCreditAction,
  getCreditList,
  getReasonByType,
  saveInvoiceData,
  getCurrencyList,
  getUnparkCredits,
} from 'api/invoices';
import {Order} from 'store/pages/customerStatementStore';
import {sortCreditData, NewCreditActionDto} from 'pages/Backoffice/components/CreditActions/CreditActionsUtils';

export interface IFilterData {
  orderField: string;
  order: Order;
  convertToNumberOnSort?: boolean;
}

export interface ICreditAction {
  Amount: string;
  Date: string;
  Id: string;
  Reasons: string;
  Remarks: string | null;
  Type: string;
  post_id: string | number | null;
  'Affiliate Id': string;
  'Current Balance': string;
  'TXN ID': string | number | null;
  'Operator Id': string;
  'Origin/Destination': number;
  'Price Quote': string | null;
  parentId?: string;
  childActions?: ICreditAction[];
}

export interface INewCreditAction {
  postID?: string;
  type?: string;
  amount?: number;
  remarks?: string;
  reason?: number;
}

export interface ICreditActionEdit {
  [key: string]: INewCreditAction;
}

export interface ICreditActionReason {
  ActionReasonId: number;
  Description: string;
  Id: number;
  Reason: string;
}

interface ICreditActionReasonsDto {
  action: string;
  reasons: ICreditActionReason[];
}

export interface IColumnFilterData {
  field?: string;
  value?: string;
}

interface ICurrency {
  CurrencyCode: string;
  Id: number;
  Symbol: string;
  Value: number;
}

interface ICreditActionReasonEdit {
  [key: string]: ICreditActionReason[];
}

export function creditActionsStore() {
  return makeObservable(
    {
      isLoading: false as boolean,
      data: null as ICreditAction[] | null,
      userId: null as string | null,
      filterData: null as IFilterData | null,
      newCreditAction: {} as INewCreditAction,
      creditActionEdit: {} as ICreditActionEdit,
      newActionReasons: [] as ICreditActionReason[],
      newActionReasonsEdit: {} as ICreditActionReasonEdit,
      currenciesList: [] as ICurrency[],
      page: 1 as number,
      isEditAllowed: false as boolean,
      columnFilterData: {field: 'Id'} as IColumnFilterData,
      getDataByUserId(id: string) {
        this.isLoading = true;
        this.data = [];
        getCurrencyList().then(
          action(({data: {results}}) => {
            this.currenciesList = results;
          })
        );
        getCreditList(id)
          .then(
            action(({data}) => {
              if (Array.isArray(data)) {
                const orderedList = this.getActionsWithParents(data);
                const sortedList = this.sortData(orderedList);
                this.data = sortedList;
              }
              if (data.error) {
                console.error('ERROR: ', data);
              }
            })
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
      handleIdChange(id: string) {
        this.userId = id;
      },
      handlePageChange(newPageNumber: number) {
        this.page = newPageNumber;
      },
      handleFilterDataChange(data: IFilterData) {
        this.filterData = data;
        if (this.data) {
          this.data = this.sortData(this.data);
        }
      },
      eraseAllTheData() {
        this.page = 0;
        this.data = null;
        this.userId = null;
        this.filterData = null;
      },
      sortData(list: ICreditAction[]) {
        return this.filterData ? sortCreditData(list, this.filterData) : list;
      },
      getActionsWithParents(list: ICreditAction[]) {
        const mainActions = list.filter(({parentId}) => !parentId);
        mainActions.forEach((item) => {
          item.Id = String(item.Id);
        });
        const actionsWithReduce = mainActions.map((item) => {
          const childs = list.filter(({parentId}) => parentId === item.Id);
          if (childs.length) {
            item.childActions = childs;
          }
          return item;
        });

        return actionsWithReduce;
      },
      setEditRules(isAllowed: boolean) {
        this.isEditAllowed = isAllowed;
      },
      setNewCreditAction(field: string, value: string | number, CSRFToken?: string) {
        this.newCreditAction = {
          ...this.newCreditAction,
          reason: field === 'type' ? undefined : this.newCreditAction.reason,
          [field]: value,
        };
        if (field === 'type' && CSRFToken) {
          this.getReasonsList(String(value), CSRFToken);
        }
      },
      setNewCreditActionEdit(id: string, field: string, value: string | number, CSRFToken?: string) {
        this.creditActionEdit = {
          ...this.creditActionEdit,
          [id]: {
            ...this.creditActionEdit[id],
            reason: field === 'type' ? undefined : this.creditActionEdit?.[id]?.reason,
            [field]: value,
          },
        };
        if (field === 'type' && CSRFToken) {
          this.getReasonsList(String(value), CSRFToken, true, id);
        }
      },
      eraseNewCreditActionData() {
        this.newCreditAction = {};
      },
      eraseNewCreditActionEditData() {
        this.creditActionEdit = {};
      },
      async saveInvoice(id: string, value: string) {
        this.isLoading = true;
        return saveInvoiceData(id, value)
          .then(
            action(({data: {status, results}}) => {
              if (status?.code !== 0) {
                return false;
              }
              const record = this.data?.find(({Id}: ICreditAction) => Id === id);
              if (record) {
                record['Operator Id'] = results.operatorId;
                record.Remarks = results.invoiceId;
                this.data = this.data ? [...this.data] : null;
                return true;
              }
              return false;
            })
          )
          .catch(() => false)
          .finally(() => {
            this.isLoading = false;
          });
      },
      getReasonsList(type: string, CSRFToken: string, isForEditAction?: boolean, id?: string) {
        if (!isForEditAction) {
          this.newActionReasons = [];
        }

        getReasonByType(type, CSRFToken).then(
          action(({data}) => {
            const newReasons =
              (data.results as ICreditActionReasonsDto[])?.find(({action: reasonAction}) => reasonAction === type)
                ?.reasons || [];
            if (!isForEditAction || !id) {
              this.newActionReasons = newReasons;
              return;
            }

            this.newActionReasonsEdit = {
              ...this.newActionReasonsEdit,
              [id]: newReasons,
            };
          })
        );
      },
      async postNewCreditAction(data: NewCreditActionDto) {
        return addNewCreditAction(data).then((res) => res);
      },
      addColumnFilterData(data: IColumnFilterData) {
        this.page = 1;
        this.columnFilterData = {
          ...this.columnFilterData,
          ...data,
        };
      },
      eraseColumnFilterData() {
        this.columnFilterData = {};
      },
      getUnparkCreditsValue(userId: string, CSRFToken: string) {
        getUnparkCredits(userId, CSRFToken).then(
          action(({data: {results}}) => {
            if (typeof results === 'number') {
              this.newCreditAction = {
                ...this.newCreditAction,
                amount: results,
              };
            }
          })
        );
      },
    },
    {
      isLoading: observable,
      page: observable,
      isEditAllowed: observable,
      filterData: observable.deep,
      newCreditAction: observable.deep,
      data: observable.deep,
      currenciesList: observable.deep,
      columnFilterData: observable.deep,
      newActionReasons: observable.deep,
      newActionReasonsEdit: observable.deep,
      creditActionEdit: observable.deep,
      userId: observable.deep,
      getDataByUserId: action.bound,
      handleIdChange: action.bound,
      handlePageChange: action.bound,
      handleFilterDataChange: action.bound,
      eraseAllTheData: action.bound,
      sortData: action.bound,
      setEditRules: action.bound,
      saveInvoice: action.bound,
      setNewCreditAction: action.bound,
      eraseNewCreditActionData: action.bound,
      postNewCreditAction: action.bound,
      addColumnFilterData: action.bound,
      eraseColumnFilterData: action.bound,
      setNewCreditActionEdit: action.bound,
      eraseNewCreditActionEditData: action.bound,
      getUnparkCreditsValue: action.bound,
    }
  );
}

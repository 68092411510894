import {makeObservable, observable, action} from 'mobx';

export function themeOptionsStore() {
  return makeObservable(
    {
      sidebarToggle: false as boolean,
      setSidebarToggle(sidebarToggle: boolean) {
        this.sidebarToggle = sidebarToggle;
      },
    },

    {
      sidebarToggle: observable,
      setSidebarToggle: action,
    }
  );
}

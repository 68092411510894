import {action, makeObservable, observable} from 'mobx';
import api from 'api';

export interface ProbationManagementField {
  min_rating: number;
  min_wordcount: number;
  max_wordcount: number | null;
  max_years: number;
  cat_vendors: string[];
  excluded_clients: string[];
  excluded_translators: string[];
  project_types: string[];
}

export interface GetProbationResponse {
  params: ProbationManagementField;
  project_types_list: KeyStringItem;
  cat_vendors_list: KeyStringItem;
}

export interface KeyStringItem {
  [key: string]: string;
}

export function probationManagementStore() {
  return makeObservable(
    {
      loading: false as boolean,
      field: {
        min_rating: 1,
        min_wordcount: 10,
        max_wordcount: null,
        max_years: 1,
        cat_vendors: [],
        excluded_clients: [],
        excluded_translators: [],
        project_types: [],
      } as ProbationManagementField,
      project_types_list: {} as KeyStringItem,
      cat_vendors_list: {} as KeyStringItem,
      fetchProbationParams(token: string) {
        this.loading = true;

        api.probationManagement
          .getProbation(token)
          .then(
            action((response) => {
              if (!response) return;

              const {params, cat_vendors_list, project_types_list} = response;

              this.field = params;
              this.cat_vendors_list = cat_vendors_list;
              this.project_types_list = project_types_list;
            })
          )
          .finally(
            action(() => {
              this.loading = false;
            })
          );
      },
      updateProbationParams(token: string) {
        this.loading = true;

        api.probationManagement.editProbation(this.field, token).finally(
          action(() => {
            this.loading = false;
          })
        );
      },
      updateFields(newField: Partial<ProbationManagementField>) {
        this.field = {...this.field, ...newField};
      },
    },
    {
      field: observable,
      project_types_list: observable,
      cat_vendors_list: observable,
      fetchProbationParams: action.bound,
      updateProbationParams: action.bound,
      updateFields: action.bound,
      loading: observable,
    }
  );
}

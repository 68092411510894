import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function telegramNotificationsStore() {
  return makeObservable(
    {
      isVerify: false as boolean,
      isSubscribed: false as boolean,
      showAlert: false as boolean,
      showSuccess: false as boolean,
      telegramNotifications: undefined as any,
      telegramLink: '' as string,
      isLoading: false as boolean,
      checkVerify() {
        api.profile.getCheckVerify().then((response: any) => {
          this.isVerify = !!response;
        });
      },
      checkSubscription() {
        api.profile.getCheckSubscription().then((response: any) => {
          this.isSubscribed = !!response;
        });
      },
      setTelegramNotifications(notifications: any) {
        this.telegramNotifications = notifications;

        this.isSubscribed = !!notifications?.has_subscribed;

        if (notifications?.has_subscribed) {
          this.telegramLink = notifications?.url;
          api.profile.postTelegramSMS();
          this.showSuccess = true;
        }
      },
      changeNotifications() {
        if (this.isSubscribed) {
          api.profile.postSubscription(0).then((response: any) => {
            if (response.status !== 200) {
              return;
            }

            if (response?.data?.results?.has_subscribed !== undefined) {
              this.setTelegramNotifications(response?.data?.results);
            }
          });
          this.showSuccess = false;
        } else {
          if (!this.isVerify) {
            this.showAlert = true;
          } else {
            api.profile.postSubscription(1).then((response: any) => {
              if (response.status !== 200) {
                return;
              }

              if (response?.data?.results?.has_subscribed !== undefined) {
                this.setTelegramNotifications(response?.data?.results);
              }
            });
          }
        }
      },
    },
    {
      isVerify: observable,
      isSubscribed: observable,
      showAlert: observable,
      showSuccess: observable,
      telegramLink: observable,
      isLoading: observable,
      checkVerify: action.bound,
      checkSubscription: action.bound,
      changeNotifications: action.bound,
    }
  );
}

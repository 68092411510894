import {makeObservable, observable, action} from 'mobx';

export function resourceViewStore() {
  return makeObservable(
    {
      resourcesType: '' as string,
      isLoaded: false as boolean,
      getResource(uuid: string) {
        fetch('/api/2/resources/' + uuid)
          .then((res) => res.json())
          .then((result) => {
            if (result.results.download_url) {
              let type = '';
              switch (result.results.file_mime) {
                case 'application/pdf':
                  type = 'pdf';
                  break;
                case 'image/jpeg':
                case 'image/png':
                case 'image/svg+xml':
                case 'image/bmp':
                case 'image/gif':
                case 'image/tiff':
                case 'image/webp':
                  type = 'image';
                  break;
                default:
                  type = '';
                  break;
              }
              this.resourcesType = type;
              this.isLoaded = true;
            }
          });
      },
    },
    {
      resourcesType: observable,
      isLoaded: observable,
      getResource: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

type BillingInformation = {
  firstName: string;
  lastName: string;
  vat_id: string;
  zipCode: string;
  phone: string;
  Country: string;
  Company: string;
  address: string;
  city: string;
};

export function billingAddressStore() {
  return makeObservable(
    {
      billingInformation: {
        firstName: '' as string,
        lastName: '' as string,
        vat_id: '' as string,
        zipCode: '' as string,
        phone: '' as string,
        Country: '' as string,
        Company: '' as string,
        address: '' as string,
        city: '' as string,
      } as BillingInformation,
      countriesList: [] as [],
      errorMessages: {} as any,
      isLoading: false as boolean,
      updateBillingInformation(newBillingInformation: Partial<BillingInformation>) {
        this.billingInformation = {...this.billingInformation, ...newBillingInformation};
      },
      getCountriesList() {
        api.wizard.getCountriesInfo().then(
          action(({data}) => {
            if (Array.isArray(data.list)) {
              this.countriesList = data.list;
            }
          })
        );
      },
      postBillingInformation(CSRFToken: string) {
        const params = {
          first_name: this.billingInformation.firstName,
          last_name: this.billingInformation.lastName,
          vat_id: this.billingInformation.vat_id,
          zip_code: this.billingInformation.zipCode,
          phone: this.billingInformation.phone,
          country: this.billingInformation.Country,
          company: this.billingInformation.Company,
          address: this.billingInformation.address,
          city: this.billingInformation.city,
          CSRFToken: CSRFToken,
        };
        this.isLoading = true;
        api.profile
          .postBillingInfo(params)
          .then((responseData: any) => {
            if (responseData.status !== 200) {
              return;
            }
            if (responseData?.data?.user_id) {
              this.errorMessages = {};
            } else {
              this.errorMessages = responseData?.data;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      billingInformation: observable,
      countriesList: observable,
      isLoading: observable,
      updateBillingInformation: action.bound,
    }
  );
}

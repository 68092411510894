import {getTranslatorsMyTeam, getTranslatorsMyTeamAvailability} from 'api/translatorsMyTeam';
import {action, makeObservable, observable} from 'mobx';
import {
  IMyTeamClient,
  IMyTeamFilters,
  IMyTeamSorting,
  IMyTeamSortingFieldEnum,
} from 'pages_refactor/MyTeam/MyTeamUtils';
import {SortDirectionEnum} from 'pages_refactor/VoicesPage/VoicesUtils';
import {FormValueProps} from 'pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';

export function translatorMyTeamStore() {
  return makeObservable(
    {
      isLoading: false as boolean,
      filters: {} as IMyTeamFilters,
      sorting: null as IMyTeamSorting | null,
      clientsData: [] as IMyTeamClient[],
      rowsPerPage: 10 as number,
      page: 0 as number,
      recordsCount: 0 as number,
      isMyTeamAvailable: false as boolean,
      setFilter(field: keyof IMyTeamFilters, value: string) {
        this.filters = {
          ...this.filters,
          [field]: value,
        };
      },
      getClientsData(page: number, params: FormValueProps) {
        this.isLoading = true;
        getTranslatorsMyTeam(page, params)
          .then(
            action((res) => {
              this.clientsData = res.data;
              this.recordsCount = res.totalCount;
            })
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
      getMyTeamAvailability() {
        getTranslatorsMyTeamAvailability()
          .then(
            action((res) => {
              this.isMyTeamAvailable = res;
            })
          )
          .catch(() => console.error('Error is-my-team-member'));
      },
      setRowsPerPage(value: number) {
        this.rowsPerPage = value;
      },
      setPage(value: number) {
        this.page = value;
      },
      changeSorting(field: IMyTeamSortingFieldEnum, order: SortDirectionEnum) {
        this.sorting = {
          field,
          order,
        };
      },
    },
    {
      isLoading: observable,
      rowsPerPage: observable,
      page: observable,
      recordsCount: observable,
      isMyTeamAvailable: observable,
      filters: observable.deep,
      clientsData: observable.deep,
      sorting: observable.deep,
      setFilter: action.bound,
      getClientsData: action.bound,
      setRowsPerPage: action.bound,
      setPage: action.bound,
      changeSorting: action.bound,
      getMyTeamAvailability: action.bound,
    }
  );
}

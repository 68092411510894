import {makeObservable, observable, action} from 'mobx';
import api from 'api';
import {StatsData, ChartData} from 'pages/AffiliateRevenues/AffiliateRevenuesUtils';
import {DatePickerValue} from '../../components/BaseComponents/BaseDatepicker';

export function affiliateRevenuesStore() {
  return makeObservable(
    {
      statsData: {} as any,
      selectedFromDate: null as DatePickerValue,
      selectedToDate: null as DatePickerValue,
      fromDateOpen: false as boolean,
      toDateOpen: false as boolean,
      isValidFromDate: true as boolean,
      isValidToDate: true as boolean,
      isLoading: false as boolean,
      setSelectedFromDate(date: DatePickerValue) {
        if (date) {
          this.selectedFromDate = date;
          if (this.fromDateOpen) {
            this.fromDateOpen = false;
            this.toDateOpen = true;
          }
        }
      },
      setSelectedToDate(date: DatePickerValue) {
        if (date) {
          this.selectedToDate = date;
          this.toDateOpen = false;
        }
      },
      setFromDateOpen(isOpen: boolean) {
        this.fromDateOpen = isOpen;
      },
      setToDateOpen(isOpen: boolean) {
        this.toDateOpen = isOpen;
      },
      setIsValidFromDate(isValid: boolean) {
        this.isValidFromDate = isValid;
      },
      setIsValidToDate(isValid: boolean) {
        this.isValidToDate = isValid;
      },
      getPartnerStats() {
        if (this.selectedFromDate && this.selectedToDate) {
          this.isLoading = true;

          if (this.selectedFromDate)
            api.partner
              .getPartnerStats(
                (this.selectedFromDate as Date).toISOString().split('T')[0].replaceAll('-', '/'),
                (this.selectedToDate as Date).toISOString().split('T')[0].replaceAll('-', '/')
              )
              .then((response) => {
                this.statsData = response?.results;
              })
              .finally(() => {
                this.isLoading = false;
              });
        }
      },
      getChartData() {
        const chartData: ChartData = {
          hits: this.statsData.hits || [],
          impressions: this.statsData.impressions || [],
          registrations: this.statsData.registrations || [],
          sales: this.statsData.sales || [],
          start: this.statsData.start || undefined,
          end: this.statsData.end || undefined,
          period: this.statsData.period || 0,
        };

        return chartData;
      },
      getStatisticsData() {
        const statisticsData: StatsData = {
          impressions:
            this.statsData.impressions?.reduce((summ: number, item: Array<number>) => summ + item[1], 0) || 0,
          uniqueVisitors: this.statsData.hits?.reduce((summ: number, item: Array<number>) => summ + item[1], 0) || 0,
          registeredUsers:
            this.statsData.registrations?.reduce((summ: number, item: Array<number>) => summ + item[1], 0) || 0,
          transactions: this.statsData.transactionCount || 0,
        };

        if (this.statsData.isTranslator) {
          statisticsData.referredTranslators = this.statsData.numTransReferrals || 0;
          statisticsData.receivedBonuses = this.statsData.transRefBonuses || 0;
        }

        if (Array.isArray(this.statsData.actions) && this.statsData.actions.length) {
          statisticsData.actions = this.statsData.actions;
          statisticsData.customersDetails = this.statsData.customersDetails;
          statisticsData.revenues = this.statsData.revenues?.toString();
        }

        return statisticsData;
      },
    },
    {
      statsData: observable,
      selectedFromDate: observable,
      selectedToDate: observable,
      fromDateOpen: observable,
      toDateOpen: observable,
      isValidFromDate: observable,
      isValidToDate: observable,
      isLoading: observable,
      setSelectedFromDate: action.bound,
      setSelectedToDate: action.bound,
      setFromDateOpen: action.bound,
      setToDateOpen: action.bound,
      setIsValidFromDate: action.bound,
      setIsValidToDate: action.bound,
      getPartnerStats: action.bound,
      getChartData: action.bound,
      getStatisticsData: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import api from 'api';

export function terminologyStore() {
  return makeObservable(
    {
      engine: '' as string,
      terminologyLanguages: [] as [],
      terminologyFiles: {} as any,
      sourceLanguage: '' as string,
      targetLanguage: '' as string,
      replaceIfExist: false as boolean,
      confirmReplaceOpen: false as boolean,
      file: undefined as any,
      error: '' as string,
      isLoading: false as boolean,
      setEngine(engine: string) {
        this.engine = engine;
      },
      setSourceLanguage(language: string) {
        this.sourceLanguage = language;
      },
      setTargetLanguage(language: string) {
        this.targetLanguage = language;
      },
      setReplaceIfExist(isReplace: boolean) {
        this.replaceIfExist = isReplace;
        if (isReplace === true) {
          this.postFile();
        }
      },
      setConfirmReplaceOpen(isOpen: boolean) {
        this.confirmReplaceOpen = isOpen;
      },
      setFile(file: any) {
        this.error = '';
        this.file = file;
        if (file) {
          api.profile.checkTerminology(this.sourceLanguage, this.targetLanguage).then((response) => {
            if (response.results) {
              this.confirmReplaceOpen = true;
            } else {
              this.postFile();
            }
          });
        }
      },
      getTerminologyLanguages(CSRFToken: string) {
        api.profile.getTerminologyLanguages(CSRFToken).then((response) => {
          this.terminologyLanguages = response?.results || [];
        });
      },
      getTerminologyFiles() {
        api.profile.getTerminologyFiles().then((response) => {
          this.terminologyFiles = response?.results || [];
        });
      },
      postFile() {
        const data = new FormData();
        data.append('file', this.file);
        data.append('srcLang', this.sourceLanguage);
        data.append('tarLang', this.targetLanguage);
        data.append('engine', 'amazon');
        data.append('replaceIfExist', this.replaceIfExist.toString());

        this.file = undefined;
        this.isLoading = true;

        api.profile
          .uploadTerminologyFiles({fd: data})
          .then((response) => {
            this.replaceIfExist = false;
            this.sourceLanguage = '';
            this.targetLanguage = '';

            if (response?.results?.status !== 'ok') {
              this.error = response?.results?.msg || '';
            }
            this.getTerminologyFiles();
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      deleteFile(id: string) {
        this.isLoading = true;
        api.profile
          .deleteTerminologyFile(id)
          .then((response) => {
            if (response?.results?.status === 'ok') {
              this.getTerminologyFiles();
            } else {
              this.error = response?.results?.msg || '';
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    {
      engine: observable,
      terminologyLanguages: observable,
      terminologyFiles: observable,
      sourceLanguage: observable,
      targetLanguage: observable,
      replaceIfExist: observable,
      confirmReplaceOpen: observable,
      file: observable,
      error: observable,
      isLoading: observable,
      setEngine: action.bound,
      setSourceLanguage: action.bound,
      setTargetLanguage: action.bound,
      setReplaceIfExist: action.bound,
      setConfirmReplaceOpen: action.bound,
      setFile: action.bound,
      getTerminologyLanguages: action.bound,
      getTerminologyFiles: action.bound,
      postFile: action.bound,
      deleteFile: action.bound,
    }
  );
}

import {makeObservable, observable, action} from 'mobx';
import {getUserList, getCustomersList} from 'api/blendTalkForAdmin';
import {
  FormValueProps,
  getObjectFromListById,
  initialFormValue,
  UserListItem,
} from 'pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';
import api from '../../api';
import {transformToChatNotifications} from '../../pages/BlendTalk/BlendTalkUtils';

export function blendTalkForAdminStore() {
  return makeObservable(
    {
      formValue: initialFormValue as FormValueProps,
      results: [] as UserListItem[] | null,
      loading: false as boolean,
      setFormValue(value: FormValueProps) {
        this.formValue = {...this.formValue, ...value};
      },
      getTableResults(params: FormValueProps, token?: string) {
        this.loading = true;
        getUserList(params)
          .then((response) => {
            let extId = '';
            response.forEach(({ID}, index) => (extId += `${index ? '&' : '?'}externalId[]=${ID}`));

            if (!response.length) this.results = null;
            if (extId && response.length) {
              getCustomersList(extId, token)
                .then((list) => {
                  const userByID = getObjectFromListById(response, 'ID');

                  this.results = list.map(({id, externalId}) => ({
                    ...userByID[+externalId],
                    externalId: id,
                  }));
                })
                .then(() => {
                  this.results &&
                    api.blendTalk
                      .getChatNotifications(token!)
                      .then((resp) => {
                        const result = transformToChatNotifications(resp, true);

                        this.results = this.results
                          ? this.results?.map((apiResult) => ({
                              ...apiResult,
                              unreadNotificationCount: result[apiResult.externalId!]?.length || 0,
                            }))
                          : null;
                      })
                      .catch(action(() => (this.results = null)));
                });
            }
          })
          .finally(action(() => (this.loading = false)));
      },
    },
    {
      formValue: observable,
      results: observable,
      loading: observable,
      setFormValue: action.bound,
      getTableResults: action.bound,
    }
  );
}

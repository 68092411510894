import {makeObservable, observable, action} from 'mobx';
import api from 'api';

type FilterData = {
  page: number;
  limit: number;
  filter_status: string;
  filter_type: string;
  CSRFToken: string;
};

type MessageData = {
  id: string;
  content: string;
  date: number;
  sender: string;
  status: 'read' | 'unread';
  title: string;
  type: string;
};

export function messageBoxStore() {
  return makeObservable(
    {
      rows: [] as Array<MessageData>,
      total: 0 as number,
      selectedRows: [] as Array<string>,
      filterData: {
        page: 0,
        limit: 25,
        filter_status: 'all',
        filter_type: 'all',
        CSRFToken: '',
      } as FilterData,
      isLoading: false as boolean,
      addSelectedRows(id?: string) {
        if (id) {
          this.selectedRows = [...this.selectedRows, id];
        } else {
          this.selectedRows = this.rows.map((message: MessageData) => message?.id);
        }
      },
      removeSelectedRows(id?: string) {
        if (id) {
          this.selectedRows = this.selectedRows.filter((item) => item !== id);
        } else {
          this.selectedRows = [];
        }
      },
      updateFilterData(newFilterData: Partial<FilterData>) {
        this.filterData = {...this.filterData, ...newFilterData};
        this.loadMessages();
      },
      openMessage(id: string) {
        this.rows.map((message: MessageData) => {
          if (message.id && message.id === id) {
            if (message.status !== 'read') {
              api.messageBox.readMessage(id, this.filterData.CSRFToken || '');
            }
            message.status = 'read';
          }

          return message;
        });
      },
      markAsRead() {
        this.selectedRows.forEach((id: string) => this.openMessage(id));
        this.removeSelectedRows();
      },
      loadMessages() {
        this.isLoading = true;
        api.messageBox
          .getMessages(
            this.filterData.page,
            this.filterData.limit,
            this.filterData.filter_status !== 'all' ? this.filterData.filter_status : '',
            this.filterData.filter_type !== 'all' ? this.filterData.filter_type : '',
            this.filterData.CSRFToken
          )
          .then(
            action(({data}) => {
              if (Array.isArray(data?.results?.messages)) {
                this.rows = data.results.messages;
                this.total = data.results?.count;
              }
            })
          )
          .finally(
            action(() => {
              this.isLoading = false;
            })
          );
      },
    },
    {
      rows: observable,
      total: observable,
      selectedRows: observable,
      filterData: observable.deep,
      isLoading: observable,
      addSelectedRows: action.bound,
      removeSelectedRows: action.bound,
      updateFilterData: action.bound,
      openMessage: action.bound,
      markAsRead: action.bound,
      loadMessages: action.bound,
    }
  );
}
